import React, { useState } from "react";

import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from "@chakra-ui/react";

import { Mapbox } from "common/components/AssetModal/Mapbox";

interface MapModalProps {
	isOpen: boolean;
	onClose: () => void;
	setCoordinates?: (coordinates: [number, number] | undefined) => void;
	initialValue?: [number, number];
}
export const EditLocationModal: React.FC<MapModalProps> = (props) => {
	const { isOpen, onClose, setCoordinates, initialValue } = props;

	const [temporalCoordinates, setTemporalCoordinates] = useState<[number, number] | undefined>(initialValue);

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent marginTop="6rem">
				<ModalHeader>Selecciona dirección</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Mapbox
						setCoordinates={(coordinates) => setTemporalCoordinates(coordinates)}
						value={temporalCoordinates}
					/>
				</ModalBody>

				<ModalFooter>
					<Button height="2rem" mr={3} onClick={onClose} variant="ghost">
						Cancelar
					</Button>
					<Button
						height="2rem"
						mr={3}
						variant="ghost"
						onClick={() => {
							if (setCoordinates) {
								setTemporalCoordinates(undefined);
								setCoordinates(undefined);
							}
							// onClose();
						}}
					>
						Borrar
					</Button>
					<Button
						height="2rem"
						onClick={() => {
							if (setCoordinates && temporalCoordinates) {
								setCoordinates(temporalCoordinates);
							}
							onClose();
						}}
					>
						Guardar
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
