import { EnvConfigurationType } from ".";

export const devConfig: EnvConfigurationType = {
	ENV_NAME: "development",
	AWS_POOL_ID: "eu-west-1_6zrfPeXrO",
	AWS_REGION: "eu-west-1",
	AWS_WEB_CLIENT_ID: "6dgpaqnhv9n45dtcn9n17amohc",
	AWS_COGNITO_DOMAIN: "adifiteauth.auth.eu-west-1.amazoncognito.com",
	AWS_AZURE_PROVIDER_ID: "AzureAD",
	API_URL: "https://api.dev.aite.dhub.arup.com",
	PROJECT_REF: "adif-ite",
};
