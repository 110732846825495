import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import React from "react";

import { ChevronDownIcon } from "@chakra-ui/icons";
import {
	Menu,
	MenuButton,
	IconButton,
	MenuList,
	MenuItem,
	Flex,
	Text,
	MenuDivider,
	Icon,
	MenuOptionGroup,
	Checkbox,
	Stack,
	CheckboxGroup,
	Button,
} from "@chakra-ui/react";
import { DateRange, Range } from "react-date-range";
import { es } from "react-date-range/dist/locale";
import { BsSortDown, BsSortUpAlt } from "react-icons/bs";

import { ActivoCompletoOutput } from "components/Portfolio/types/activos";
import { PortfolioFilterSelection } from "components/Portfolio/types/filterData";
import { columnHeaderLabel } from "helpers/FiltersColumns";
import { capitalizeFirstLetter } from "helpers/text";
import { actions as portfolioActions } from "store/portfolio/reducer";
import { useAppDispatch, useAppSelector } from "store/store";

import { booleanValidar } from "./validado";

type IProps = {
	column: keyof ActivoCompletoOutput;
};

export const FilterDropDownCertificacion: React.FC<IProps> = (props) => {
	const { column } = props;
	const filterSelection = useAppSelector((state) => state.portfolio.filterSelection);
	const dispatch = useAppDispatch();

	const [order, setOrder] = React.useState<"asc" | "desc" | undefined>(undefined);
	const [innerCalendarState, setInnerCalendarState] = React.useState<Range[]>([
		{
			startDate: undefined,
			endDate: new Date(),
			key: "selection",
		},
	]);

	React.useEffect(() => {
		if (order !== undefined) {
			dispatch(portfolioActions.setOrderBy({ column: column, direction: order }));
		}
	}, [order]);

	const onCheckboxChange = (value: string[]) => {
		const { generic } = columnToFilterMap[column];
		const newFilterSelection = {
			...(filterSelection || {}),
			[generic]: value.length === 1 ? value[0] === "true" : undefined,
		};
		dispatch(portfolioActions.setFilterSelection(newFilterSelection));
	};

	const onCalendarSubmit = () => {
		const rangeValue = innerCalendarState[0];
		const { range: rangeKey } = columnToFilterMap[column];
		const newFilterSelection = {
			...(filterSelection || {}),
			[rangeKey]: [rangeValue.startDate?.toISOString() ?? "", rangeValue.endDate?.toISOString() ?? ""],
		};
		dispatch(portfolioActions.setFilterSelection(newFilterSelection));
	};

	const getCurrentBooleanFilterAsCheckboxOptions = () => {
		if (!filterSelection) return ["true", "false"];
		const applied = filterSelection[columnToFilterMap[column].generic];
		if (applied === undefined) return ["true", "false"];
		else if (applied) return ["true"];
		else return ["false"];
	};

	return (
		<Flex alignItems="center">
			<Menu closeOnSelect={false}>
				<MenuButton
					as={IconButton}
					aria-label="Filter"
					icon={<ChevronDownIcon color="gray.700" />}
					variant="solid"
					borderRadius="md"
					bgColor="white"
					size="1rem"
					p=".05rem"
					border="1px solid"
					borderColor="gray.200"
				/>
				<MenuList minWidth="240px">
					<Stack spacing={1} direction="column" pl=".75rem">
						<CheckboxGroup
							colorScheme="gray"
							defaultValue={getCurrentBooleanFilterAsCheckboxOptions()}
							onChange={onCheckboxChange}
						>
							<Checkbox value="true" size="sm">
								<Text textStyle="body3">{capitalizeFirstLetter(booleanValidar("Sí"))}</Text>
							</Checkbox>
							<Checkbox value="false" size="sm">
								<Text textStyle="body3">{capitalizeFirstLetter(booleanValidar("No"))}</Text>
							</Checkbox>
						</CheckboxGroup>
					</Stack>
				</MenuList>
			</Menu>
			<Menu closeOnSelect={false}>
				<MenuButton
					as={IconButton}
					aria-label="Filter"
					icon={<ChevronDownIcon color="gray.700" />}
					variant="solid"
					borderRadius="md"
					bgColor="white"
					size="1rem"
					p=".05rem"
					border="1px solid"
					borderColor="gray.200"
					ml=".5rem"
				/>
				<MenuList minWidth="240px">
					<MenuOptionGroup type="radio">
						<MenuItem _hover={{ bg: "gray.100" }} onClick={() => setOrder("desc")}>
							<Icon as={BsSortDown} fontSize="1rem" color="gray.500" mr=".75rem" />
							<Text textStyle="body3">Descendente</Text>
						</MenuItem>
						<MenuItem _hover={{ bg: "gray.100" }} onClick={() => setOrder("asc")}>
							<Icon as={BsSortUpAlt} fontSize="1rem" color="gray.500" mr=".75rem" />
							<Text textStyle="body3">Ascendente</Text>
						</MenuItem>
					</MenuOptionGroup>
					<MenuDivider w="90%" mx="auto" />
					<Flex flexDir="column">
						<DateRange
							rangeColors={["var(--chakra-colors-gray-400)"]}
							editableDateInputs={true}
							onChange={(it) => setInnerCalendarState([it.selection])}
							moveRangeOnFirstSelection={false}
							retainEndDateOnFirstSelection={true}
							ranges={innerCalendarState}
							locale={es}
						/>
						<Button
							m="0 1rem 0.5rem auto"
							size="lg"
							onClick={onCalendarSubmit}
							bg="gray.400"
							_hover={{ bgColor: "gray.500" }}
						>
							<Text textStyle="body3" color="white">
								Aplicar
							</Text>
						</Button>
					</Flex>
				</MenuList>
			</Menu>
			<Text title={columnHeaderLabel(column)} color="gray.500" textStyle="body3" pl=".5rem">
				{columnHeaderLabel(column)}
			</Text>
		</Flex>
	);
};

const columnToFilterMap: {
	[col: string]: {
		range: keyof PortfolioFilterSelection;
		generic: keyof PortfolioFilterSelection;
	};
} = {
	fecha_entrega: { range: "fechaEntregaIte", generic: "iteEntregada" },
	fecha_entrega_bim: { range: "fechaEntregaBim", generic: "bimEntregado" },
	fecha_certificacion_ite: {
		range: "fechaCertificacionIte",
		generic: "iteCertificada",
	},
	fecha_certificacion_bim: {
		range: "fechaCertificacionBim",
		generic: "bimCertificado",
	},
} as const;
