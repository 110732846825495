import React from "react";

import { Accordion, Box, Flex, Select } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { NoElementsText } from "common/components/NoElementsText";
import { SectionHeader } from "common/components/SectionHeader";
import { displayWhenPrint } from "helpers/displayWhenPrint";
import { expandAllItems } from "helpers/expandedItems";
import { formsUrl } from "helpers/formsUrl";
import { capitalizeFirstLetter } from "helpers/text";
import { getNeeListThunk } from "store/ITE/thunks";
import { useAppDispatch, useAppSelector } from "store/store";

import { fechaPrimerEvento } from "../utils/fechasIte";
import { titloIte } from "../utils/tituloIte";
import { TableHeader } from "./Header";
import { MiddleSectionInfo } from "./MiddleSectionInfo";
import { TableRow } from "./Row";
import { NEE } from "./types";

type IProps = {
	print?: boolean;
};

export const TablaEficiencia: React.FC<IProps> = (props) => {
	const { print } = props;

	const { iteId } = useParams();
	const [loading, setLoading] = React.useState(true);
	const dispatch = useAppDispatch();
	const { data: nee } = useAppSelector((state) => ({
		data: state.ites.nee.data.data,
		thunkState: state.ites.nee.thunkState,
	}));
	const iteSelected = useAppSelector((state) => state.ites.iteSelected.data);
	const iteListItemSelected = useAppSelector((state) => state.ites.iteList.data.find((it) => it.id === iteId));

	const [filteredNee, setFilteredNee] = React.useState<NEE[]>(nee);

	React.useEffect(() => {
		if (!iteId) return;
		dispatch(getNeeListThunk({ iteId })).finally(() => {
			setLoading(false);
		});
	}, [iteId, dispatch]);

	React.useEffect(() => {
		setFilteredNee(nee);
	}, [nee]);

	const categorias = [...new Set(nee.map((nec) => nec.categoria))];
	const indices = [...new Set(nee.map((nec) => nec.indice))];

	const handleFilter = (data: NEE[], filter: string, field: string) => {
		const filtered = data.filter((nec) => nec[field] === filter);
		filter ? setFilteredNee(filtered) : setFilteredNee(nee);
	};

	if (loading) return <React.Fragment />;
	return (
		<Box p="1rem" rounded="md" boxShadow="sm" bgColor="white" pb="2rem" overflow="hidden" overflowY="auto">
			<SectionHeader
				formLink={formsUrl(iteSelected?.ite.record_ee_id)}
				ite={
					iteListItemSelected
						? titloIte(fechaPrimerEvento(iteListItemSelected).toJSDate(), iteListItemSelected.tipo, "short")
						: "ITE"
				}
				tableTitle="Eficiencia Energética"
				print={print}
			/>
			<MiddleSectionInfo />
			{filteredNee.length > 0 && (
				<Flex align="center" mb="1rem" flexDirection="row" m="1rem" sx={{ display: displayWhenPrint(print) }}>
					<Box w="50%" mr="2rem">
						<Select
							onChange={(e) => handleFilter(nee, e.target.value, "categoria")}
							placeholder="Todas las categorías"
							size="md"
							fontSize=".75rem"
							borderRadius="md"
						>
							{categorias.map((categoria, i) => (
								<option key={i} value={categoria}>
									{capitalizeFirstLetter(categoria)}
								</option>
							))}
						</Select>
					</Box>
					<Box w="50%">
						<Select
							onChange={(e) => handleFilter(nee, e.target.value, "indice")}
							placeholder="Todos los índice"
							size="md"
							fontSize=".75rem"
							borderRadius="md"
						>
							{indices.sort().map((indice, i) => (
								<option key={i} value={indice}>
									{`Índice ${indice}`}
								</option>
							))}
						</Select>
					</Box>
				</Flex>
			)}
			<TableHeader />
			<Box mr="1rem">
				<Accordion allowToggle h="100%" index={print ? expandAllItems(filteredNee) : undefined}>
					{filteredNee.length > 0 ? (
						filteredNee.map((data, i) => <TableRow key={i} nee={data} print={print} />)
					) : (
						<NoElementsText section={"nee"} />
					)}
				</Accordion>
			</Box>
		</Box>
	);
};
