import React from "react";

import {
	Box,
	Select,
	Grid,
	SlideFade,
	SlideFadeProps,
	useDisclosure,
	IconButton,
	Icon,
	GridItem,
	Button,
	Text,
	Flex,
	useToast,
	UseToastOptions,
} from "@chakra-ui/react";
import { FiPrinter, FiAlertTriangle, FiMail } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";

import { getReportSignedUrl } from "api/printer";
import FullsizeSpinner from "common/components/FullsizeSpinner";
import StatusIndicator from "common/components/StatusIndicator";
import {
	getDatosInventarioThunk,
	getIteByIdThunk,
	getNaeListThunk,
	getNcnListThunk,
	getNecListThunk,
	getNeeListThunk,
	getReportsThunk,
} from "store/ITE/thunks";
import { useAppDispatch, useAppSelector } from "store/store";
import { getUserFeaturesThunk } from "store/user/thunks";

import { EmailModal } from "./components/EmailModal";
import { TableHeader } from "./components/Header";
import { IteInfo } from "./components/IteInfo";
import { IteNuevaModal } from "./components/ITEScheduling/IteNuevaModal";
import { IteReprogramarModal } from "./components/ITEScheduling/IteReprogramarModal";
import { IteValidation } from "./components/IteValidation";
import { TableSelector } from "./components/TableSelector";
import { useAltaITESegunEstado } from "./hooks/useAltaITESegunEstado";
import { useReprogramacionITEs } from "./hooks/useReprogramacionITEs";
import { ITEListOutput } from "./types/ite";
import { fechaPrimerEvento } from "./utils/fechasIte";
import { titloIte } from "./utils/tituloIte";

type IProps = {
	print?: boolean;
};

export const IteSection: React.FC<IProps> = (props) => {
	const { print } = props;
	const { assetId, iteId, tableId } = useParams();
	const iteList: ITEListOutput[] = useAppSelector((state) => state.ites.iteList.data);
	const assetIteList = iteList.filter((ite) => ite.asset_id === assetId);
	const iteSelected = useAppSelector((state) => state.ites.iteSelected);
	const datosInventario = useAppSelector((state) => state.ites.inv.data);
	const iteReports = useAppSelector((state) => state.ites.reports);

	const [loading, setLoading] = React.useState(false);
	const [disableButton, setDisableButton] = React.useState(true);
	const [openReprogramar, setOpenReprogramar] = React.useState<boolean>();
	const [openNuevaIte, setOpenNuevaIte] = React.useState<boolean>();
	//const [openNuevaIteBasica, setOpenNuevaIteBasica] = React.useState<boolean>();

	const configReprogramacion = useReprogramacionITEs();
	const configAltaSegunEstado = useAltaITESegunEstado();
	//const configAltaBasica = useAltaITEBasica();

	const { isOpen, onToggle } = useDisclosure();
	const { isOpen: isOpenEmailModal, onToggle: onToggleEmailModal } = useDisclosure();
	const toast = useToast()

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const changeIteSelected = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLSelectElement>) => {
		const { value } = e.target;
		if (tableId) navigate(`/activo/${assetId}/ite/${value}/${tableId}`);
		else navigate(`/activo/${assetId}/ite/${value}`);
	};

	const itesOfAssetByDate = React.useMemo(() => {
		const itesByDate = assetIteList
			.map((ite) => {
				const date = fechaPrimerEvento(ite).toJSDate();
				return { date, tipo: ite.tipo, value: ite.id };
			})
			.sort((prev, next) => prev.date.getTime() - next.date.getTime());
		return itesByDate;
	}, [assetIteList]);

	const SlideInProps: SlideFadeProps = {
		offsetX: "300px",
		offsetY: "0px",
	};

	const SlideOutProps: SlideFadeProps = {
		offsetX: "0px",
		offsetY: "0px",
	};

	const slideProps = tableId ? SlideInProps : SlideOutProps;

	React.useEffect(() => {
		onToggle();
		dispatch(getUserFeaturesThunk());
	}, []);

	React.useEffect(() => {
		if (!iteId) return;
		setLoading(true);

		if (iteId !== iteSelected.data?.ite.id || !datosInventario) {
			dispatch(getIteByIdThunk({ id: iteId }));
			dispatch(getDatosInventarioThunk({ iteId }));
			dispatch(getNecListThunk({ iteId, order: "indice.desc" }));
			dispatch(getNaeListThunk({ iteId }));
			dispatch(getNcnListThunk({ iteId, order: "indice.desc" }));
			dispatch(getNeeListThunk({ iteId, order: "indice.desc" }));
		}
		setLoading(false);
		setDisableButton(false);
	}, [iteId, iteSelected.data?.ite.id]);

	React.useEffect(() => {
		if (iteReports.data.length || !iteId) return; // if we already have it, we are good
		const refreshReports = () => {
			console.log("Refreshing reports...");
			dispatch(getReportsThunk({ iteId }));
		};
		const delayed = setTimeout(refreshReports, 15e3);
		return () => clearTimeout(delayed);
	}, [iteId, iteReports.data.length]);

	const requiresValidation = () => iteSelected.data?.ite.estado === "Entregado";

	const currentIteReport = iteReports.data
		.filter((rep) => {
			const ite = iteSelected.data?.ite;
			if (!ite) return false;
			const fechaReport = new Date(rep.date_created);
			const fechaITE = new Date(ite.fecha_entrega || ite.fecha_visita_reprogramada || ite.fecha_visita_programada);
			return fechaReport > fechaITE;
		})
		.sort((a, b) => b.date_created.localeCompare(a.date_created))[0];

	const handlePrint = async () => {
		const ite = iteSelected.data;
		if (!currentIteReport?.key || !ite) {
			toast(reportNotReadyToast)
			return
		};
		const fechaITE = new Date(
			ite.ite.fecha_visita_reportada || ite.ite.fecha_visita_reprogramada || ite.ite.fecha_visita_programada,
		);
		const formatter = new Intl.DateTimeFormat("es-ES");
		const reportUrl = await getReportSignedUrl(
			currentIteReport.key,
			`${ite?.asset.nombre} - Resumen ITE ${formatter.format(fechaITE).replaceAll("/", "-")}.pdf`,
		).catch(err => {
			toast(reportNotReadyToast);
			throw err
		});

		window.open(reportUrl, "_blank");
	};

	return (
		<React.Fragment>
			<Box>
				<SlideFade {...slideProps} in={isOpen}>
					<Box padding="1rem" rounded="md" boxShadow="sm" bgColor="white" paddingBottom="2rem" paddingRight="1.5rem">
						<Flex ml="1rem">
							<Box mr="auto">
								<Text textStyle="h5" fontFamily="heading">
									Revisión y registro de ITE
								</Text>
							</Box>
							<IconButton
								className="printHidden"
								aria-label="share"
								variant="icon-only"
								height="1.5rem"
								width="1.5rem"
								onClick={onToggleEmailModal}
								isDisabled={disableButton || !currentIteReport}
								title="Compartir datos de inspección via email"
							>
								{!currentIteReport && iteSelected.data?.ite.fecha_entrega ? (
									<FullsizeSpinner color="gray.500" />
								) : (
									<Icon as={FiMail} fontSize="1.5rem" color="gray.500" strokeWidth={"1"} />
								)}
							</IconButton>
							<IconButton
								className="printHidden"
								aria-label="print"
								variant="icon-only"
								height="1.5rem"
								width="1.5rem"
								onClick={handlePrint}
								isDisabled={disableButton || !currentIteReport}
								title="Imprimir informe"
							>
								<Icon as={FiPrinter} fontSize="1.5rem" color="gray.500" strokeWidth={"1"} />
							</IconButton>
						</Flex>

						<Box mx="1rem" mt="0.5rem" className="printHidden">
							<Text textStyle="body3" color="#006935" data-testid="iteSectionLabel">
								Selección y Programación de ITEs
							</Text>
							<Grid templateColumns={{ xs: "1fr", sm: "repeat(2, 1fr)" }} gap="1rem">
								<GridItem display="flex" flexDirection="column" justifyContent="space-between" gap="1rem">
									<Select
										name="ite"
										onChange={changeIteSelected}
										fontSize="body2"
										fontWeight="bold"
										defaultValue={iteId}
									>
										{itesOfAssetByDate.map((ite, i) => (
											<option key={i} value={ite.value}>
												{titloIte(ite.date, ite.tipo)}
											</option>
										))}
									</Select>
								</GridItem>
								<GridItem display="flex" justifyContent="space-between" alignItems="flex-start">
									{!print && (
										<Flex w="100%" gap=".5rem" flexDirection="column">
											{requiresValidation() && <IteValidation ite={iteSelected.data} print={print} />}
											{iteSelected.data?.asset.por_validar && (
												<Flex alignItems="center" gridColumn="span 2" my=".25rem" gap="0.75rem" fontSize="1.25rem">
													<Icon
														as={FiAlertTriangle}
														fontSize="1.5rem"
														color="red.500"
														display={{ xs: "none", sm: "block" }}
													/>
													<Text textStyle="body2">Activo pendiente de aceptación</Text>
												</Flex>
											)}

											{configReprogramacion.allowsRescheduling && (
												<Button
													type="submit"
													size="md"
													bg="gray.400"
													borderRadius="5px"
													h="2rem"
													w="100%"
													onClick={() => setOpenReprogramar(true)}
												>
													<Text textStyle="body2" color="white">
														{`Reprogramar ITE`}
													</Text>
												</Button>
											)}
											{configAltaSegunEstado.allowsNewITE && !iteSelected.data?.asset.por_validar && (
												<Button
													type="submit"
													size="md"
													bg="gray.400"
													borderRadius="5px"
													h="2rem"
													w="100%"
													onClick={() => setOpenNuevaIte(true)}
												>
													<Text textStyle="body2" color="white">
														Añadir ITE Según Estado
													</Text>
												</Button>
											)}
											{/* La ITE básica se genera automaticamente*/}
											{/* {!iteSelected.data?.asset.por_validar && (
												<Button
													type="submit"
													size="md"
													bg="gray.400"
													borderRadius="5px"
													h="2rem"
													w="100%"
													onClick={() => setOpenNuevaIteBasica(true)}
												>
													<Text textStyle="body2" color="white">
														Añadir ITE Básica
													</Text>
												</Button>
											)} */}
										</Flex>
									)}
								</GridItem>
								<GridItem colSpan={2} display="flex" gap="2rem" alignItems="center">
									{iteSelected.data?.ite?.estado !== undefined && (
										<React.Fragment>
											<Box>
												<StatusIndicator estado={iteSelected.data.ite.estado} />
											</Box>
											<Text textStyle="body3" fontStyle="italic" borderLeft="2px solid lightgray" paddingLeft=".5rem">
												{iteSelected.data?.ite.comentario}
											</Text>
										</React.Fragment>
									)}
								</GridItem>
							</Grid>
						</Box>
						<Box w="100%" mx="1rem" mt="0.5rem" display="flex">
							<IteInfo ite={iteSelected.data?.ite} />
						</Box>
						<TableHeader />
						{loading ? (
							<Box textAlign="center">
								<FullsizeSpinner />
							</Box>
						) : (
							<React.Fragment>
								<TableSelector
									tableRef={"inv"}
									tableLabel={"Ficha de Inventario"}
									items={datosInventario?.items.length || 0}
									indice={"-"}
									print={print}
								/>
								<TableSelector
									tableRef={"nec"}
									tableLabel={"Estado de Conservación"}
									items={iteSelected.data?.ite.nec.numero}
									indice={iteSelected.data?.ite.nec.indice}
									print={print}
								/>
								{iteSelected.data?.ite.tipo !== "Básica" && (
									<TableSelector
										tableRef={"nee"}
										tableLabel={"Eficiencia Energética"}
										items={iteSelected.data?.ite.nee.numero}
										indice={iteSelected.data?.ite.nee.indice}
										print={print}
									/>
								)}
								{iteSelected.data?.ite.tipo !== "Básica" && (
									<TableSelector
										tableRef={"nae"}
										tableLabel={"Condiciones de Accesibilidad"}
										items={iteSelected.data?.ite.nae.numero}
										indice={iteSelected.data?.ite.nae.indice}
										print={print}
									/>
								)}
								<TableSelector
									tableRef={"ncn"}
									tableLabel={"Cumplimiento de Normativa"}
									items={iteSelected.data?.ite.ncn.numero}
									indice={iteSelected.data?.ite.ncn.indice}
									print={print}
								/>
							</React.Fragment>
						)}
					</Box>
				</SlideFade>
			</Box>
			{configReprogramacion.allowsRescheduling && iteId && assetId && (
				<IteReprogramarModal
					isOpen={openReprogramar ?? false}
					iteId={iteId}
					assetId={assetId}
					onClose={() => setOpenReprogramar(false)}
					defaultDate={configReprogramacion.defaultDate}
					minDate={configReprogramacion.minDate}
					maxDate={configReprogramacion.maxDate}
				/>
			)}
			{assetId && configAltaSegunEstado.allowsNewITE && (
				<IteNuevaModal
					isOpen={openNuevaIte ?? false}
					assetId={assetId}
					onClose={() => setOpenNuevaIte(false)}
					defaultDate={configAltaSegunEstado.defaultDate}
					minDate={configAltaSegunEstado.minDate}
					maxDate={configAltaSegunEstado.maxDate}
				/>
			)}
			{/*assetId && iteId && (
				<IteBasicaNuevaModal
					isOpen={openNuevaIteBasica ?? false}
					assetId={assetId}
					onClose={() => setOpenNuevaIteBasica(false)}
					defaultDate={configAltaBasica.defaultDate}
					minDate={configAltaBasica.minDate}
					maxDate={configAltaBasica.maxDate}
				/>
			)*/}
			<EmailModal isOpen={isOpenEmailModal} onClose={onToggleEmailModal} />
		</React.Fragment>
	);
};

const reportNotReadyToast: UseToastOptions = {
	title: "Informe no disponible",
	description: "Este informe aún no ha sido generado. La generación de informes se lleva a cabo tras realizar cambios al contenido o el estado de la ITE y puede tomar varios minutos, en función de la cantidad de información recabada. Por favor, espere unos minutos y refresque la página. En caso de no poder disponer de este informe en unos minutos, pongase en contacto con el equipo de suporte en aite@dhub.arup.com",
	status: "warning",
	duration: 5000,
	isClosable: true
};
