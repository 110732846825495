import React from "react";

import { Grid, GridItem, Text } from "@chakra-ui/react";

import { BarAndValue } from "common/components/BarAndValue";
import { useAppSelector } from "store/store";

export const PortfolioStats: React.FC = () => {
	const portfolioStats = useAppSelector((state) => state.portfolio.portfolioStats.data);
	const totalValue =
		(portfolioStats?.estado_ite && Object.values(portfolioStats.estado_ite).reduce((a, b) => a + b, 0)) || 0;

	return (
		<Grid templateColumns="1fr 4fr" alignItems="center" pr="3rem" flex="1" gap="0 0.5rem">
			{portfolioStats &&
				Object.entries(portfolioStats.estado_ite).map(([key, value]) => (
					<React.Fragment key={key}>
						<GridItem>
							<Text textStyle="body3" color="gray.800">
								{key}
							</Text>
						</GridItem>
						<GridItem>
							<BarAndValue
								value={value}
								totalValue={totalValue}
								label={key}
								colors={{
									Rechazado: "red.500",
									Registrado: "green.400",
									Entregado: "yellow.500",
									Programada: "gray.200",
								}}
							/>
						</GridItem>
					</React.Fragment>
				))}
		</Grid>
	);
};
