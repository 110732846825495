import React, { useEffect } from "react";

import { useAuthenticator } from "@aws-amplify/ui-react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Layout } from "common/core/Layout";
import { THUNK_STATE } from "config/types";
import { useAppDispatch, useAppSelector } from "store/store";
import { getUserFeaturesThunk } from "store/user/thunks";
import { Portfolio } from "views/portfolio";

import { assetsView } from "./asset";
import { portfolioRoute } from "./portfolio";
import { portfolioWithParams } from "./portfolioWithParams";

export const AppRoutes: React.FC = () => {
	const { user } = useAuthenticator((context) => [context.user]);
	const features = useAppSelector((state) => state.user.features);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!user) return;
		dispatch(getUserFeaturesThunk());
	}, [user]);

	return (
		<Routes>
			<Route>
				{features.data.includes("can-read") ? (
					<Route>
						<Route path="/portfolio">
							<Route
								index
								element={
									<Layout>
										<Portfolio />
									</Layout>
								}
							/>
							{portfolioWithParams}
						</Route>
						<Route path="/mapa"> {portfolioRoute}</Route>
						<Route path="/activo">
							<Route path=":assetId">
								{assetsView}
								<Route path=":sectionId">
									{assetsView}
									<Route path=":iteId">
										{assetsView}
										<Route path=":tableId">{assetsView}</Route>
									</Route>
								</Route>
							</Route>
						</Route>
					</Route>
				) : (
					<Route
						path="/"
						element={
							features.thunkState !== THUNK_STATE.PENDING ? (
								<div>Parece que no has podido acceder a la plataforma. Por favor, contacte con el administrador</div>
							) : (
								<></>
							)
						}
					/>
				)}
			</Route>
			<Route path="/" element={<Navigate to="/portfolio" replace />} />
		</Routes>
	);
};
