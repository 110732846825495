import React from "react";

import { Grid, GridItem } from "@chakra-ui/react";

import Carousel from "common/components/Carousel";
import { InfoLine } from "common/components/InfoLine";

import { NAE } from "../types";

interface IProps {
	nae: NAE;
}
export const RowBody: React.FC<IProps> = (props) => {
	const { nae } = props;

	return (
		<React.Fragment>
			<Grid templateColumns="repeat(10, 1fr)" w="100%" textAlign="left" pl="1rem" pr=".5rem">
				<GridItem colSpan={nae.fotos && nae.fotos.length > 0 ? 6 : 10}>
					<InfoLine label="Estandar a cumplir:" value={nae.estandar} />
					<InfoLine label="Detalles del Incumplimiento:" value={nae.observacion} />
				</GridItem>
				{nae.fotos && nae.fotos.length > 0 && (
					<GridItem colSpan={4} pr=".5rem">
						<Carousel images={nae.fotos} />
					</GridItem>
				)}
			</Grid>
		</React.Fragment>
	);
};
