import React from "react";

import { Box, Flex, Grid, GridItem, Icon, Link } from "@chakra-ui/react";
import { BsChevronRight, BsCheckCircle, BsExclamationTriangle } from "react-icons/bs";

import { PortfolioInfoLine } from "common/components/PortfolioInfoLine";
import { defaultValuesJefaturas } from "components/Portfolio/defaultValues";
import { ActivoCompletoOutput } from "components/Portfolio/types/activos";

interface IProps {
	asset: ActivoCompletoOutput;
}

export const LocalizacionRow: React.FC<IProps> = (props) => {
	const { asset } = props;

	return (
		<Box
			p=".5rem 0"
			rounded="md"
			bgColor="white"
			mt=".5rem"
			pl="1rem"
			h="2.5rem"
			_hover={{ cursor: "pointer", boxShadow: "sm" }}
		>
			<Link href={`/activo/${asset.id}/ite/${asset.ite_id}`} _hover={{ textDecoration: "none" }}>
				<Flex height="100%" alignItems="center">
					<Grid templateColumns="repeat(35, 1fr)" w="100%" color="gray.800" alignItems="center">
						<GridItem colSpan={2} pt=".5rem" pl="1.5rem">
							{asset.por_validar === undefined || asset.por_validar ? (
								<Icon as={BsExclamationTriangle} color="red.500" fontSize="1.25rem" />
							) : (
								<Icon as={BsCheckCircle} color="green.500" fontSize="1.25rem" />
							)}
						</GridItem>
						<GridItem colSpan={6}>
							<PortfolioInfoLine value={asset.nombre} href={`/activo/${asset.id}/ite/${asset.ite_id}`} />
						</GridItem>
						<GridItem colSpan={3}>
							<PortfolioInfoLine value={asset.ambito} />
						</GridItem>
						<GridItem colSpan={3}>
							<PortfolioInfoLine
								value={defaultValuesJefaturas.find(({ value }) => value === asset.jefatura)?.label ?? asset.jefatura}
							/>
						</GridItem>
						<GridItem colSpan={3}>
							<PortfolioInfoLine value={asset.agrupacion} />
						</GridItem>
						<GridItem colSpan={3}>
							<PortfolioInfoLine value={asset.provincia} />
						</GridItem>
						<GridItem colSpan={4}>
							<PortfolioInfoLine value={asset.municipio} />
						</GridItem>
						<GridItem colSpan={4}>
							<PortfolioInfoLine value={asset.direccion} />
						</GridItem>
						<GridItem colSpan={2}>
							<PortfolioInfoLine value={asset.pk} />
						</GridItem>
						<GridItem colSpan={2}>
							<PortfolioInfoLine value={asset.via} />
						</GridItem>
						<GridItem colSpan={3}>
							<PortfolioInfoLine value={asset.codigo_estacion} />
						</GridItem>
					</Grid>
					<Icon as={BsChevronRight} color="gray.500" width="1rem" height="1rem" mr="1rem" />
				</Flex>
			</Link>
		</Box >
	);
};
