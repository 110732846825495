import React from "react";

import { Button, Flex, Text, Link, useDisclosure, Box } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { DatosRefModal } from "components/ITE/TablaEficiencia/DatosRefModal";
import { useAppSelector } from "store/store";

import HeaderText from "../Header";
import TabCloser from "../TabCloser";

interface IProps {
	tableTitle: string;
	ite: string;
	formLink?: string;
	print?: boolean;
}

export const SectionHeader: React.FC<IProps> = (props) => {
	const { tableTitle, ite, formLink, print } = props;
	const neeContexto = useAppSelector((state) => state.ites.neeContexto.data);

	const { tableId } = useParams();
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<React.Fragment>
			<Flex gap="1rem" w="100%" alignItems="center" mb=".5rem">
				<Box pl="1rem">
					<Text textStyle="h5" fontFamily="heading">
						{tableTitle}
					</Text>
					<HeaderText label={ite} />
				</Box>
				{!print && (
					<Flex ml="auto" gap="1rem">
						<Link href={formLink} isExternal style={{ textDecoration: "none" }}>
							<Button type="submit" bg="gray.400" size="md" borderRadius="5px" h="2rem">
								<Text textStyle="body3" color="white">
									Ver Formulario
								</Text>
							</Button>
						</Link>
						{tableId === "nee" && neeContexto && (
							<React.Fragment>
								<Button onClick={onOpen} bg="gray.400" size="md" borderRadius="5px" h="2rem">
									<Text textStyle="body3" color="white">
										Datos referencia
									</Text>
								</Button>
								<DatosRefModal isOpen={isOpen} onClose={onClose} />
							</React.Fragment>
						)}
					</Flex>
				)}
				{!print && <TabCloser />}
			</Flex>
		</React.Fragment>
	);
};
