import React, { useState } from "react";

import Modal from "./Modal";
import "./styles.css";

export const thumbnailUrlFromFilepath = (filepath: string) => {
	if (window.location.hostname === "localhost") return "/placeholder.png";
	return `${window.location.origin.replace("https://", "https://forms.")}/thumbnails/${filepath}`;
};

export const urlFromFilepath = (filepath: string) => {
	if (window.location.hostname === "localhost") return "/placeholder.png";
	return `${window.location.origin.replace("https://", "https://forms.")}/images/${filepath}`;
};

interface IProps {
	images: { filepath: string }[];
	index?: number;
}

const Carousel: React.FC<IProps> = (props) => {
	const { images, index = 0 } = props;

	const [url, setUrl] = useState<string>(urlFromFilepath(images[0].filepath));
	const [display, setDisplay] = useState<"none" | "block">("none");
	const [indexes, setIndexes] = useState<[number, number]>([index, index + 1]);

	if (images.length === 1) {
		return (
			<React.Fragment>
				<div id="gallery">
					<img
						className="gallery-image"
						loading="lazy"
						src={thumbnailUrlFromFilepath(images[0].filepath)}
						onClick={() => {
							setUrl(urlFromFilepath(images[0].filepath));
							setDisplay("block");
						}}
						onError={({ currentTarget }) => {
							currentTarget.onerror = null; // prevents looping
							currentTarget.src = "/placeholder.png";
						}}
					/>
				</div>
				<Modal url={url} display={display} setDisplay={setDisplay} />
			</React.Fragment>
		);
	} else {
		const indexHandler = (move: 1 | -1) => {
			const assessIndex = indexes[0] + move;
			if (assessIndex < 0) {
				setIndexes([images.length - 1, 0]);
			} else if (assessIndex === images.length - 1) {
				setIndexes([images.length - 1, 0]);
			} else if (assessIndex === images.length) {
				setIndexes([0, 1]);
			} else {
				setIndexes([assessIndex, assessIndex + 1]);
			}
		};
		return (
			<React.Fragment>
				<div id="gallery">
					<svg viewBox="0 0 24 24" focusable="false" className="icon" onClick={() => indexHandler(-1)}>
						<path fill="currentColor" d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
					</svg>
					<div className="gallery-content">
						<div className="gallery-image-wrapper">
							<img
								className="gallery-image"
								src={thumbnailUrlFromFilepath(images[indexes[0]].filepath)}
								onClick={() => {
									setUrl(urlFromFilepath(images[indexes[0]].filepath));
									setDisplay("block");
								}}
								onError={({ currentTarget }) => {
									currentTarget.onerror = null; // prevents looping
									currentTarget.src = "/placeholder.png";
								}}
							/>
						</div>
					</div>
					<svg viewBox="0 0 24 24" focusable="false" className="icon" onClick={() => indexHandler(1)}>
						<path fill="currentColor" d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
					</svg>
				</div>
				<Modal url={url} display={display} setDisplay={setDisplay} />
			</React.Fragment>
		);
	}
};
export default Carousel;
