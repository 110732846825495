import { ComponentStyleConfig } from "@chakra-ui/react";

import Input, { baseStyle as inputBaseStyle } from "./Input";

const componentOverride: ComponentStyleConfig = {
	baseStyle: {
		field: {
			...inputBaseStyle.field,
		},
	},
	defaultProps: {
		...Input.defaultProps,
	},
};

export default componentOverride;
