import { useState } from "react";

import {
	Input,
	Tag,
	TagLabel,
	TagCloseButton,
	Wrap,
	InputProps,
	FormControl,
	FormErrorMessage,
} from "@chakra-ui/react";

const EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const isValidEmail = (email) => EMAIL_REGEXP.test(email);

/**
 * Represents an email added to the list. Highlighted with a close button for removal.
 */
interface ChipProps {
	email: string;
	onDelete: (email: string) => void;
}
export const Chip: React.FC<ChipProps> = (props: ChipProps) => (
	<Tag key={props.email} borderRadius="full" variant="solid" colorScheme="green">
		<TagLabel>{props.email}</TagLabel>
		<TagCloseButton
			onClick={() => {
				props.onDelete(props.email);
			}}
		/>
	</Tag>
);

/**
 * A horizontal stack of chips. Like a Pringles can on its side.
 */
interface ChipListProps {
	emails: string[];
	onDelete: (email: string) => void;
}
export const ChipList: React.FC<ChipListProps> = (props: ChipListProps) => (
	<Wrap spacing={1} mb={3}>
		{props.emails.map((email) => (
			<Chip email={email} key={email} onDelete={props.onDelete} />
		))}
	</Wrap>
);

/**
 * Form field wrapper.
 */
export const ChipEmailInput: React.FC<InputProps> = (props: InputProps) => (
	<FormControl>
		<Input type="email" {...props} />
		<FormErrorMessage> Dirección de correo electrónico no válida </FormErrorMessage>
	</FormControl>
);

/**
 * Contains presentation, logic and state for inputting emails and having them saved as chips.
 */
interface EmailChipInputProps {
	name: string;
	required?: boolean;
	placeholder?: string;
	initialEmails?: string[];
}
export const EmailChipInput: React.FC<EmailChipInputProps> = (props: EmailChipInputProps) => {
	const { name, required = false, placeholder, initialEmails = [] } = props;
	const [inputValue, setInputValue] = useState("");
	const [emails, setEmails] = useState(initialEmails);

	// Checks whether we've added this email already.
	const emailChipExists = (email: string) => emails.includes(email);

	// Add an email to the list, if it's valid and isn't already there.
	const addEmails = (emailsToAdd: string[]) => {
		const validatedEmails = emailsToAdd
			.map((e) => e.trim())
			.filter((email) => isValidEmail(email) && !emailChipExists(email));

		const newEmails = [...emails, ...validatedEmails];

		setEmails(newEmails);
		setInputValue("");
	};

	// Remove an email from the list.
	const removeEmail = (email: string) => {
		const index = emails.findIndex((e) => e === email);
		if (index !== -1) {
			const newEmails = [...emails];
			newEmails.splice(index, 1);
			setEmails(newEmails);
		}
	};

	// Save input field contents in state when changed.
	const handleChange = (e) => {
		setInputValue(e.target.value);
	};

	// Validate and add the email if we press tab, enter or comma.
	const handleKeyDown = (e) => {
		if (["Enter", "Tab", ","].includes(e.key) && inputValue) {
			e.preventDefault();
			addEmails([inputValue]);
		}
	};

	// Split and add emails when pasting.
	const handlePaste = (e) => {
		e.preventDefault();

		const pastedData = e.clipboardData.getData("text");
		const pastedEmails = pastedData.split(",");
		addEmails(pastedEmails);
	};

	// Validate contents and add to list or simply remove
	const handleBlur = () => {
		addEmails([inputValue]);
	};

	const handleCloseClick = (email: string) => {
		removeEmail(email);
	};

	return (
		<>
			<ChipEmailInput
				placeholder={placeholder}
				onPaste={handlePaste}
				onKeyDown={handleKeyDown}
				onChange={handleChange}
				onBlur={handleBlur}
				value={inputValue}
				isRequired={required && !emails.length}
			/>
			<ChipList emails={emails} onDelete={handleCloseClick} />
			<Input display="none" name={name} value={emails} readOnly />
		</>
	);
};
