import { ActionReducerMapBuilder } from "@reduxjs/toolkit";

import { THUNK_STATE } from "config/types";

import { PortfolioSlice } from "./reducer";
import {
	getInfoActivosThunk,
	getPortfolioStatsThunk,
	putEditarActivoThunk,
	postNuevoActivoValidadoThunk,
	putRechazarActivoThunk,
	putValidarActivoThunk,
	putFechaEntregaCertificacionThunk,
	postCommentThunk,
} from "./thunks";

// 1. GET Resumen Activos
export const getInfoActivosExtraReducer = (builder: ActionReducerMapBuilder<PortfolioSlice>) => [
	builder.addCase(getInfoActivosThunk.pending, (state) => {
		state.assetsInfo.thunkState = THUNK_STATE.PENDING;
	}),
	builder.addCase(getInfoActivosThunk.rejected, (state) => {
		state.assetsInfo.thunkState = THUNK_STATE.REJECTED;
	}),
	builder.addCase(getInfoActivosThunk.fulfilled, (state, action) => ({
		...state,
		assetsInfo: {
			data: {
				data: action.payload.data,
				pagination: action.payload.pagination,
			},
			thunkState: THUNK_STATE.FULFILLED,
		},
	})),
];

// 2. GET Portfolio Stats
export const getPortfolioStatssExtraReducer = (builder: ActionReducerMapBuilder<PortfolioSlice>) => [
	builder.addCase(getPortfolioStatsThunk.pending, (state) => {
		state.portfolioStats.thunkState = THUNK_STATE.PENDING;
	}),
	builder.addCase(getPortfolioStatsThunk.rejected, (state) => {
		state.portfolioStats.thunkState = THUNK_STATE.REJECTED;
	}),
	builder.addCase(getPortfolioStatsThunk.fulfilled, (state, action) => ({
		...state,
		portfolioStats: {
			data: action.payload,
			thunkState: THUNK_STATE.FULFILLED,
		},
	})),
];

// 3. PUT Editar Activo
export const putEditarActivoExtraReducer = (builder: ActionReducerMapBuilder<PortfolioSlice>) => [
	builder.addCase(putEditarActivoThunk.pending, (state) => {
		state.assetsInfo.thunkState = THUNK_STATE.PENDING;
	}),
	builder.addCase(putEditarActivoThunk.rejected, (state) => {
		state.assetsInfo.thunkState = THUNK_STATE.REJECTED;
	}),
	builder.addCase(putEditarActivoThunk.fulfilled, (state, action) => {
		const foundAsset = state.assetsInfo.data.data.find((asset) => asset.id === action.payload.id);
		if (foundAsset) {
			const updatedAsset = state.assetsInfo.data.data.map((asset) => {
				if (asset.id === action.payload.id) {
					return action.payload;
				}
				return asset;
			});
			return {
				...state,
				assetsInfo: {
					data: {
						data: updatedAsset,
						pagination: state.assetsInfo.data.pagination,
					},
					thunkState: THUNK_STATE.FULFILLED,
				},
			};
		}
		return state;
	}),
];

// 4. PUT Validar Activo
export const putValidarActivoExtraReducer = (builder: ActionReducerMapBuilder<PortfolioSlice>) => [
	builder.addCase(putValidarActivoThunk.pending, (state) => {
		state.assetsInfo.thunkState = THUNK_STATE.PENDING;
	}),
	builder.addCase(putValidarActivoThunk.rejected, (state) => {
		state.assetsInfo.thunkState = THUNK_STATE.REJECTED;
	}),
	builder.addCase(putValidarActivoThunk.fulfilled, (state, action) => {
		const foundAsset = state.assetsInfo.data.data.find((asset) => asset.id === action.payload.id);
		if (foundAsset) {
			const updatedAsset = state.assetsInfo.data.data.map((asset) => {
				if (asset.id === action.payload.id) {
					return action.payload;
				}
				return asset;
			});
			return {
				...state,
				assetsInfo: {
					data: {
						data: updatedAsset,
						pagination: state.assetsInfo.data.pagination,
					},
					thunkState: THUNK_STATE.FULFILLED,
				},
			};
		}
		return state;
	}),
];

// 5. PUT Rechazar Activo
export const putRechazarActivoExtraReducer = (builder: ActionReducerMapBuilder<PortfolioSlice>) => [
	builder.addCase(putRechazarActivoThunk.pending, (state) => {
		state.assetsInfo.thunkState = THUNK_STATE.PENDING;
	}),
	builder.addCase(putRechazarActivoThunk.rejected, (state) => {
		state.assetsInfo.thunkState = THUNK_STATE.REJECTED;
	}),
	builder.addCase(putRechazarActivoThunk.fulfilled, (state, action) => {
		const foundAsset = state.assetsInfo.data.data.find((asset) => asset.id === action.payload.id);
		if (foundAsset) {
			const updatedAsset = state.assetsInfo.data.data.map((asset) => {
				if (asset.id === action.payload.id) {
					return action.payload;
				}
				return asset;
			});
			return {
				...state,
				assetsInfo: {
					data: {
						data: updatedAsset,
						pagination: state.assetsInfo.data.pagination,
					},
					thunkState: THUNK_STATE.FULFILLED,
				},
			};
		}
		return state;
	}),
];

// 6. POST Nuevo Activo Validado
export const postNuevoActivoValidadoExtraReducer = (builder: ActionReducerMapBuilder<PortfolioSlice>) => [
	builder.addCase(postNuevoActivoValidadoThunk.pending, (state) => {
		state.assetsInfo.thunkState = THUNK_STATE.PENDING;
	}),
	builder.addCase(postNuevoActivoValidadoThunk.rejected, (state) => {
		state.assetsInfo.thunkState = THUNK_STATE.REJECTED;
	}),
	builder.addCase(postNuevoActivoValidadoThunk.fulfilled, (state) => {
		state.assetsInfo.thunkState = THUNK_STATE.FULFILLED;
	}),
];

// 7. PUT certificacion
export const putFechaEntregaCertificacionExtraReducer = (builder: ActionReducerMapBuilder<PortfolioSlice>) => [
	builder.addCase(putFechaEntregaCertificacionThunk.pending, (state) => {
		state.certificationActionThunkState = THUNK_STATE.PENDING;
	}),
	builder.addCase(putFechaEntregaCertificacionThunk.rejected, (state) => {
		state.certificationActionThunkState = THUNK_STATE.REJECTED;
	}),
	builder.addCase(putFechaEntregaCertificacionThunk.fulfilled, (state, action) => {
		for (const { iteId, tipo, fecha } of action.payload) {
			const assetIdx = state.assetsInfo.data.data.findIndex((it) => it.ite_id === iteId);
			if (assetIdx >= 0) {
				if (tipo === "ite") state.assetsInfo.data.data[assetIdx].fecha_certificacion_ite = fecha;
				else if (tipo === "bim") state.assetsInfo.data.data[assetIdx].fecha_certificacion_bim = fecha;
			}
			const assetIdxAll = state.allAssetsInfo.findIndex((it) => it.ite_id === iteId);
			if (assetIdxAll >= 0) {
				if (tipo === "ite") state.allAssetsInfo[assetIdxAll].fecha_certificacion_ite = fecha;
				else if (tipo === "bim") state.allAssetsInfo[assetIdxAll].fecha_certificacion_bim = fecha;
			}
		}
		if (state.portfolioStats.data) {
			const iteDiff = action.payload
				.filter((it) => it.tipo === "ite")
				.reduce((prev, curr) => prev + (curr.fecha === undefined ? -1 : 1), 0);
			const nCertificadosIte = state.portfolioStats.data.estado_certificacion_ite["Certificadas"];
			const nNoCertificadosIte = state.portfolioStats.data.estado_certificacion_ite["No Certificadas"];
			state.portfolioStats.data.estado_certificacion_ite = {
				Certificadas: nCertificadosIte + iteDiff,
				"No Certificadas": nNoCertificadosIte - iteDiff,
			};
			const bimDiff = action.payload
				.filter((it) => it.tipo === "bim")
				.reduce((prev, curr) => prev + (curr.fecha === undefined ? -1 : 1), 0);
			const nCertificadosBim = state.portfolioStats.data.estado_certificacion_bim["Certificadas"];
			const nNoCertificadosBim = state.portfolioStats.data.estado_certificacion_bim["No Certificadas"];
			state.portfolioStats.data.estado_certificacion_bim = {
				Certificadas: nCertificadosBim + bimDiff,
				"No Certificadas": nNoCertificadosBim - bimDiff,
			};
		}
		state.certificationActionThunkState = THUNK_STATE.FULFILLED;
	}),
];

// 7. POST comentario
export const postComentarioExtraReducer = (builder: ActionReducerMapBuilder<PortfolioSlice>) => [
	builder.addCase(postCommentThunk.pending, (state) => {
		state.commentActionThunkState = THUNK_STATE.PENDING;
	}),
	builder.addCase(postCommentThunk.rejected, (state) => {
		state.commentActionThunkState = THUNK_STATE.REJECTED;
	}),
	builder.addCase(postCommentThunk.fulfilled, (state, action) => {
		const { iteId } = action.payload;
		const assetIdx = state.assetsInfo.data.data.findIndex((it) => it.ite_id === iteId);
		if (assetIdx >= 0) {
			const nComentarios = state.assetsInfo.data.data[assetIdx].numero_comentarios;
			state.assetsInfo.data.data[assetIdx].numero_comentarios = (nComentarios || 0) + 1;
		}

		const assetAllIdx = state.allAssetsInfo.findIndex((it) => it.ite_id === iteId);
		if (assetAllIdx >= 0) {
			const nComentarios = state.assetsInfo.data.data[assetAllIdx].numero_comentarios;
			state.allAssetsInfo[assetAllIdx].numero_comentarios = (nComentarios || 0) + 1;
		}
		state.commentActionThunkState = THUNK_STATE.FULFILLED;
	}),
];
