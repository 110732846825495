import React from "react";

import { Flex, Text } from "@chakra-ui/react";

import { useAppSelector } from "store/store";

import { ClearFiltersButton } from "./clearFilters";
import { FilterTags } from "./FiltersTags";

export const FiltersApplied: React.FC = () => {
	const filterSelection = useAppSelector((state) => state.portfolio.filterSelection);
	const orderBy = useAppSelector((state) => state.portfolio.orderBy);

	const hasFilters = React.useMemo(() => {
		if ((JSON.stringify(filterSelection) === "{}" || filterSelection === null) && orderBy === null) return false;
		return true;
	}, [filterSelection, orderBy]);

	return (
		<Flex alignItems="center" p="1rem 0" mr="auto">
			{hasFilters ? (
				<>
					<Text textStyle="body3" color="gray.800">
						Filtros Aplicados
					</Text>
					<FilterTags />
					<ClearFiltersButton />
				</>
			) : null}
		</Flex>
	);
};
