import React from "react";

import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";
import { Tag, TagCloseButton, TagLabel } from "@chakra-ui/react";

import { defaultValuesJefaturas, defaultValuesTipologias, defaultValuesUsos } from "components/Portfolio/defaultValues";
import { PortfolioFilterSelection } from "components/Portfolio/types/filterData";
import { columnHeaderLabel } from "helpers/FiltersColumns";
import { capitalizeFirstLetter } from "helpers/text";
import { actions as portfolioActions } from "store/portfolio/reducer";
import { useAppDispatch, useAppSelector } from "store/store";

import { BooleanFilterTag } from "./BooleanTag";
import { RangeFilterTag } from "./RangeFilterTag";

export const FilterTags: React.FC = () => {
	const filterSelection = useAppSelector((state) => state.portfolio.filterSelection);
	const orderBy = useAppSelector((state) => state.portfolio.orderBy);

	const dispatch = useAppDispatch();

	const handleClose = (value: string | string[], item?: string) => {
		if (filterSelection && !item) {
			const filterKeys = Object.keys(filterSelection) as (keyof PortfolioFilterSelection)[];
			const keyWithValue = filterKeys?.find((key) => filterSelection?.[key] === value);
			if (keyWithValue) {
				const newFilterSelection = { ...filterSelection };
				delete newFilterSelection[keyWithValue];
				dispatch(portfolioActions.setFilterSelection(newFilterSelection));
			}
		}
		if (filterSelection && item) {
			const filterKeys = Object.keys(filterSelection) as (keyof PortfolioFilterSelection)[];
			const keyWithValue = filterKeys?.find((key) => filterSelection?.[key] === value);
			if (keyWithValue) {
				const newFilterSelection = { ...filterSelection };
				const newKeyWithValue = newFilterSelection[keyWithValue];
				if (newKeyWithValue && Array.isArray(newKeyWithValue)) {
					const newKeyWithValueFiltered = newKeyWithValue.filter(
						(itemFiltered: string | undefined) => itemFiltered !== item,
					);
					if (newKeyWithValueFiltered.length === 0) {
						delete newFilterSelection[keyWithValue];
						dispatch(portfolioActions.setFilterSelection(newFilterSelection));
					}
					if (newKeyWithValueFiltered.length > 0) {
						dispatch(
							portfolioActions.setFilterSelection({ ...newFilterSelection, [keyWithValue]: newKeyWithValueFiltered }),
						);
					}
				}
			}
		}
	};

	return (
		<React.Fragment>
			{filterSelection !== null &&
				Object.entries(filterSelection).map(([column, value]) => {
					if (value !== undefined) {
						if (Object.keys(DATE_RANGE_COLUMNS_LABEL_MAP).includes(column)) {
							return (
								<RangeFilterTag
									key={column}
									column={column}
									label={DATE_RANGE_COLUMNS_LABEL_MAP[column]}
									range={value as string[]}
								/>
							);
						} else if (Object.keys(CHECK_BOX_COLUMNS_LABEL_MAP).includes(column)) {
							return (
								<BooleanFilterTag
									key={column}
									column={column}
									labelTrue={CHECK_BOX_COLUMNS_LABEL_MAP[column]["true"]}
									labelFalse={CHECK_BOX_COLUMNS_LABEL_MAP[column]["false"]}
									value={value === undefined ? undefined : !!value}
								/>
							);
						} else if (typeof value === "string" && value.length) {
							return (
								<Tag size="sm" borderRadius="md" variant="solid" bgColor="gray.200" ml=".5rem" key={value}>
									<TagLabel fontSize="body3" color="gray.600" m=".25rem">
										{value}
									</TagLabel>
									<TagCloseButton color="gray.600" onClick={() => handleClose(value)} />
								</Tag>
							);
						} else if (typeof value === "object" && value.length) {
							return value.map((item: string) => (
								<Tag size="sm" borderRadius="md" variant="solid" bgColor="gray.200" ml=".5rem" key={item}>
									<TagLabel fontSize="body3" color="gray.600" m=".25rem">
										{capitalizeFirstLetter(MAPPER[column]?.find(({ value }) => value === item)?.label ?? item)}
									</TagLabel>
									<TagCloseButton color="gray.600" onClick={() => handleClose(value, item)} />
								</Tag>
							));
						}
					}
				})}
			{orderBy !== null && (
				<Tag size="sm" borderRadius="md" variant="solid" bgColor="gray.200" ml=".5rem">
					<TagLabel fontSize="body3" color="gray.600" m=".25rem">
						{columnHeaderLabel(orderBy.column)} {orderBy.direction === "asc" ? <ArrowUpIcon /> : <ArrowDownIcon />}
					</TagLabel>
					<TagCloseButton color="gray.600" onClick={() => dispatch(portfolioActions.resetOrderBy())} />
				</Tag>
			)}
		</React.Fragment>
	);
};

const DATE_RANGE_COLUMNS_LABEL_MAP = {
	fechaEntregaIte: "Fecha Entrega ITE",
	fechaEntregaBim: "Fecha Entrega Modelo",
	fechaCertificacionIte: "Fecha Certificación ITE",
	fechaCertificacionBim: "Fecha Certificación Modelo",
};

const CHECK_BOX_COLUMNS_LABEL_MAP = {
	iteEntregada: {
		true: "ITE Entregada",
		false: "ITE No Entregada",
	},
	bimEntregado: {
		true: "Modelo BIM Entregado",
		false: "Modelo BIM No Entregado",
	},
	iteCertificada: {
		true: "ITE Certificada",
		false: "ITE No Certificada",
	},
	bimCertificado: {
		true: "Modelo BIM Certificado",
		false: "Modelo BIM No Certificado",
	},
};

const MAPPER = {
	jefatura: defaultValuesJefaturas,
	uso: defaultValuesUsos,
	tipologia: defaultValuesTipologias,
	porValidar: [
		{ value: "true", label: "Provisional" },
		{ value: "false", label: "Definitivo" },
	],
};
