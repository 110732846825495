// MODULE & PROJECT
export type Module = {
	ref: string;
	name: string;
	description: string;
	module_order: number;
	is_enabled: boolean;
	created_at: string;
};

export type Project = {
	ref: string;
	name: string;
	image: string | null;
	location: string | null;
	language: string;
	description: string;
	created_at: string;
	num_assets: number;
	external_url: string | null;
};

export type ActiveModules = "ite";

// MODULE CONFIGURATION
export type OrderByFilter<T> = { column: keyof T; direction: "asc" | "desc" };

export type EnabledFormFieldType =
	| "text"
	| "longtext"
	| "select"
	| "select_multiple"
	| "checkbox"
	| "integer"
	| "float"
	| "timestamp"
	| "date"
	| "attachments";

export type FormValidations = "required" | "email";

export type ModuleInfoType = Record<string, unknown>;

export type ModuleConfigFormat = {
	type: "currency";
	symbol: string;
};

export type ModuleInnerFieldInfo = {
	title: string;
	type: EnabledFormFieldType;
	validations: FormValidations[];
	order: number;
	column: number;
	format: ModuleConfigFormat;
	options?: string[];
};

export type ModuleInfoField = Record<string, ModuleInnerFieldInfo>;

export type ModuleConfiguration = {
	id: string;
	project_ref: string;
	info_config: ModuleInfoField;
	column_label_mapper: Record<string, string>;
	features: string[];
};

// PAGINATION TYPES //
export type Pagination = {
	total: number;
	lastPage: number;
	perPage: number;
	currentPage: number;
	from: number;
	to: number;
};

export type ApiPaginatedResponse<T> = {
	data: T;
	pagination: Pagination;
};

// THUNK TYPES //
export enum THUNK_STATE {
	IDLE = "IDLE",
	FULFILLED = "FULFILLED",
	PENDING = "PENDING",
	REJECTED = "REJECTED",
}

export type StatePropWithThunkState<T> = {
	thunkState: THUNK_STATE;
	data: T;
};

export type SelectOption = {
	label: string;
	value: string | boolean;
};

export type CheckboxOption = {
	label: string;
	value: string;
	checked: boolean;
};

export interface IAttachedFile {
	id?: string;
	name: string;
	file?: File;
	type?: string;
}
