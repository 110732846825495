import React from "react";

import { Alert, AlertDescription, AlertIcon, Box, SimpleGrid } from "@chakra-ui/react";
import { DateTime } from "luxon";
import { useNavigate, useParams } from "react-router-dom";

import CookieProvider from "common/components/CookieProvider";
import FullsizeSpinner from "common/components/FullsizeSpinner";
import { EstadoActivo } from "components/EstadoActivo";
import { IteSection } from "components/ITE";
import { TablaAccesibilidad } from "components/ITE/TablaAccesibilidad";
import { TablaConservacion } from "components/ITE/TablaConservacion";
import { TablaEficiencia } from "components/ITE/TablaEficiencia";
import { TablaInventario } from "components/ITE/TablaInventario";
import { TablaNormativa } from "components/ITE/TablaNormativa";
import { ITEListOutput } from "components/ITE/types/ite";
import { LevantamientoDigital } from "components/LevantamientoDigital";
import { Portada } from "components/Portada";
import { THUNK_STATE } from "config/types";
import { getIteByIdThunk, getIteListThunk, getReportsThunk } from "store/ITE/thunks";
import { useAppDispatch, useAppSelector } from "store/store";

export const AssetView: React.FC = () => {
	const { assetId, sectionId, iteId, tableId } = useParams();
	const iteList = useAppSelector((state) => state.ites.iteList);
	const iteSelected = useAppSelector((state) => state.ites.iteSelected);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	React.useEffect(() => {
		dispatch(getIteListThunk({ assetId: assetId }));
	}, [assetId]);

	React.useEffect(() => {
		if (!["ite", "ld", "portada"].includes(sectionId ?? "")) return;
		if (iteSelected?.data?.ite.id === iteId) return;
		// if (sectionId !== "ite") return;
		const getFechaVisita = (ite: ITEListOutput) =>
			DateTime.fromISO(ite.fecha_visita_reprogramada || ite.fecha_visita_programada);
		if (!iteId) {
			const itesSortedAcendingDate = [...iteList.data].sort(
				(a, b) => getFechaVisita(a).toSeconds() - getFechaVisita(b).toSeconds(),
			);
			const activeITEs = itesSortedAcendingDate.filter(
				(it) => !it.fecha_registro && getFechaVisita(it).minus({ years: 1 }) < DateTime.now(),
			);
			const registeredITEs = itesSortedAcendingDate.filter((it) => !!it.fecha_registro);
			if (activeITEs.length) {
				// Si hay ITEs pendientes, vamos a ver la menos reciente (que es la que estará por hacer)
				navigate(activeITEs[0].id);
			} else if (registeredITEs.length) {
				// Si no, vamos a ver simplemente la última registrada
				navigate(registeredITEs.slice(-1)[0].id);
			}
		} else {
			dispatch(getIteByIdThunk({ id: iteId }));
			dispatch(getReportsThunk({ iteId }));
		}
	}, [sectionId, iteId, iteList]);

	const status = React.useMemo<"loading" | "error" | "ready">(() => {
		if (!assetId) {
			return "error";
		} else if (iteSelected.thunkState === THUNK_STATE.PENDING) {
			return "loading";
		} else if (iteSelected.thunkState === THUNK_STATE.FULFILLED) {
			return "ready";
		} else if (iteSelected.thunkState === THUNK_STATE.REJECTED) {
			return "error";
		}
		return "loading";
	}, [assetId, iteSelected]);

	if (!assetId || status === "error") {
		return (
			<Box p="1rem" rounded="md" boxShadow="sm" bgColor="white" pb="2rem">
				<Alert status="error">
					<AlertIcon />
					<AlertDescription> No se encontró el activo </AlertDescription>
				</Alert>
			</Box>
		);
	} else if (status === "loading") {
		return (
			<Box textAlign={"center"}>
				<FullsizeSpinner />
			</Box>
		);
	} else {
		return (
			<Box>
				<CookieProvider assetId={assetId} />
				<SimpleGrid columns={{ sm: 1, md: 2 }} spacingX="1.5rem" spacingY="1.5rem">
					<Box minW="300px">
						<EstadoActivo />
					</Box>
					{sectionId && !tableId && (
						<Box minW="300px">
							<Section sectionId={sectionId} />
						</Box>
					)}
					{sectionId && tableId && (
						<Box minW="300px">
							<Table tableId={tableId} />
						</Box>
					)}
				</SimpleGrid>
			</Box>
		);
	}
};

interface AssetSectionprops {
	sectionId: string;
}
const Section: React.FC<AssetSectionprops> = (props) => {
	switch (props.sectionId) {
		case "portada":
			return <Portada />;
		case "ite":
			return <IteSection />;
		case "ld":
			return <LevantamientoDigital />;
		default:
			return <React.Fragment />;
	}
};

interface ITESectionProps {
	tableId: string;
}
const Table: React.FC<ITESectionProps> = (props) => {
	switch (props.tableId) {
		case "nec":
			return <TablaConservacion />;
		case "nee":
			return <TablaEficiencia />;
		case "nae":
			return <TablaAccesibilidad />;
		case "ncn":
			return <TablaNormativa />;
		case "inv":
			return <TablaInventario />;
		default:
			return <React.Fragment />;
	}
};
