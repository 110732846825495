import { apiClient } from "api";

export const getTecnicos = async (projectRef: string) =>
	await apiClient
		.get<string[]>(`ite/tecnicos/${projectRef}/`)
		.then((res) => res.data)
		.catch((err) => {
			console.log(err);
			return [];
		});
