import React from "react";

import { Flex, Link, Text, TextProps } from "@chakra-ui/react";

import { capitalizeFirstLetter } from "helpers/text";
interface IProps {
	value?: string | string[] | number | null;
	date?: boolean;
	styleProps?: TextProps;
	currency?: boolean;
	percentage?: boolean;
	href?: string;
}

export const PortfolioInfoLine: React.FC<IProps> = (props) => {
	const { value, date, currency, percentage, styleProps, href } = props;

	const valueFormater = (value: string | string[] | number | null | undefined) => {
		if (value === null || value === undefined || value === "") {
			return "-";
		} else if (Array.isArray(value)) {
			return value.length === 0 ? "-" : capitalizeFirstLetter(value.join(", "));
		} else if (value && date) {
			return capitalizeFirstLetter(
				Intl.DateTimeFormat("es-ES", { month: "long", year: "numeric" }).format(new Date(value)),
			);
		} else if (value && currency) {
			return `${value.toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`;
		} else if (value && percentage) {
			return `${value}%`;
		} else if (typeof value === "number") {
			return value;
		} else {
			return capitalizeFirstLetter(value);
		}
	};

	const colorFormater = (value: string | string[] | number | null | undefined) => {
		if (value === null || value === undefined || value === "" || (Array.isArray(value) && value.length === 0)) {
			return "gray.400";
		} else {
			return "gray.800";
		}
	};

	return (
		<React.Fragment>
			<Flex mt=".25rem" noOfLines={1}>
				{href ? (
					<Link
						href={href}
						title={valueFormater(value).toString()}
						textStyle="body3"
						color={colorFormater(value)}
						pl=".25rem"
						// {...styleProps}
					>
						{valueFormater(value)}
					</Link>
				) : (
					<Text
						title={valueFormater(value).toString()}
						textStyle="body3"
						color={colorFormater(value)}
						pl=".25rem"
						{...styleProps}
					>
						{valueFormater(value)}
					</Text>
				)}
			</Flex>
		</React.Fragment>
	);
};
