import React, { useEffect, useState } from "react";

import { Box, Flex, FormControl, FormLabel, Grid, GridItem, Switch, Text } from "@chakra-ui/react";

import { BarAndValue } from "common/components/BarAndValue";
import FullsizeSpinner from "common/components/FullsizeSpinner";
import { ActivoCompletoOutput } from "components/Portfolio/types/activos";
import { THUNK_STATE } from "config/types";
import { getAssetsWithFilterSelection, putFechaEntregaCertificacionThunk } from "store/portfolio/thunks";
import { useAppDispatch, useAppSelector } from "store/store";

export const PortfolioStatsCertificacion: React.FC = () => {
	const dispatch = useAppDispatch();
	const filters = useAppSelector((state) => state.portfolio.filterSelection);
	const { pagination, data: assetsInView } = useAppSelector((state) => state.portfolio.assetsInfo.data);
	const thunkState = useAppSelector((state) => state.portfolio.certificationActionThunkState);
	const portfolioStats = useAppSelector((state) => state.portfolio.portfolioStats.data);
	const [activosSeleccionados, setActivosSeleccionados] = useState<ActivoCompletoOutput[]>([]);
	const features = useAppSelector((state) => state.user.features.data);
	const numeroActivosSeleccionados = activosSeleccionados.length;
	const hasCertifyFeature = features.indexOf("certify") >= 0;

	useEffect(() => {
		if (pagination.lastPage < 1) return;
		const pages = new Array(pagination.lastPage).fill(0).map((_, idx) => idx + 1);
		Promise.all(
			pages.map((page) =>
				getAssetsWithFilterSelection({ filterSelection: { ...filters }, page }).then((res) => res.data.data),
			),
		).then((res) => setActivosSeleccionados(res.flat()));
	}, [pagination.lastPage, filters, assetsInView]);

	const graphData = {
		"ITEs entregadas": portfolioStats?.estado_entrega_ite["Entregadas"] || 0,
		"ITEs certificadas": portfolioStats?.estado_certificacion_ite["Certificadas"] || 0,
		"BIM entregados": portfolioStats?.estado_entrega_bim["Entregadas"] || 0,
		"BIM certificados": portfolioStats?.estado_certificacion_bim["Certificadas"] || 0,
	};

	const handleBulkCertificate = async (toggleValue: boolean, type: "ite" | "bim") => {
		const now = new Date().toISOString();
		const action = toggleValue ? "set" : "unset";
		const changes = activosSeleccionados
			.map((it) => ({
				iteId: it.ite_id,
				certificado: type === "ite" ? it.fecha_certificacion_ite : it.fecha_certificacion_bim,
			}))
			.filter((it) => (action === "set" ? !it.certificado : it.certificado))
			.map((it) => ({ iteId: it.iteId, tipo: type, fecha: action === "set" ? now : undefined }));

		// Apply changes remotely
		console.log(`Updated certification status for ${changes.length} assets`);
		await dispatch(putFechaEntregaCertificacionThunk(changes));

		// Update local state to reflext changes
		for (const changed of changes) {
			const activo = activosSeleccionados.find((it) => it.ite_id === changed.iteId);
			if (!activo) continue;
			if (changed.tipo === "ite") activo.fecha_certificacion_ite = changed.fecha;
			if (changed.tipo === "bim") activo.fecha_certificacion_bim = changed.fecha;
		}
		setActivosSeleccionados(activosSeleccionados);
	};

	if (!portfolioStats) return <React.Fragment />;
	return (
		<Flex flex="1">
			<Grid templateColumns="1fr 3fr" alignItems="center" pr="3rem" gap="0 1rem">
				{Object.entries(graphData).map(([key, value]) => (
					<React.Fragment key={key}>
						<GridItem>
							<Text textStyle="body3" color="gray.800">
								{key}
							</Text>
						</GridItem>
						<GridItem>
							<BarAndValue
								value={value}
								totalValue={numeroActivosSeleccionados}
								label={key}
								colors={{
									"ITEs entregadas": "green.300",
									"ITEs certificadas": "green.500",
									"BIM entregados": "teal.300",
									"BIM certificados": "teal.500",
								}}
							/>
						</GridItem>
					</React.Fragment>
				))}
			</Grid>
			{hasCertifyFeature && (
				<Grid templateColumns="1fr" maxW="15rem" gap=".5rem">
					<GridItem display="flex" alignItems="center">
						<FormControl display="flex" alignItems="center">
							<Box w="5.5rem">
								{thunkState === THUNK_STATE.PENDING ? <FullsizeSpinner color="green" /> : <React.Fragment />}
								<Switch
									id="certificar-todas-ite"
									display={thunkState === THUNK_STATE.PENDING ? "none" : undefined}
									colorScheme="green"
									onChange={(e) => handleBulkCertificate(e.target.checked, "ite")}
								/>
							</Box>
							<FormLabel htmlFor="certificar-todas-ite" mb="0" ml="1rem" lineHeight=".8rem">
								{`Marcar las ${numeroActivosSeleccionados} ITEs seleccionados como certificadas`}
							</FormLabel>
						</FormControl>
					</GridItem>
					<GridItem display="flex" alignItems="center">
						<FormControl display="flex" alignItems="center">
							<Box w="5.5rem">
								{thunkState === THUNK_STATE.PENDING ? <FullsizeSpinner color="teal" /> : <React.Fragment />}
								<Switch
									id="certificar-todas-bim"
									display={thunkState === THUNK_STATE.PENDING ? "none" : undefined}
									colorScheme="teal"
									onChange={(e) => handleBulkCertificate(e.target.checked, "bim")}
								/>
							</Box>
							<FormLabel htmlFor="certificar-todas-bim" mb="0" ml="1rem" lineHeight=".8rem">
								{`Marcar los ${numeroActivosSeleccionados} modelos BIM seleccionados como certificados`}
							</FormLabel>
						</FormControl>
					</GridItem>
				</Grid>
			)}
		</Flex>
	);
};
