import React from "react";

import { Grid, GridItem } from "@chakra-ui/react";

import Carousel from "common/components/Carousel";
import { InfoLine } from "common/components/InfoLine";

import { NCN } from "../types";

interface IProps {
	ncn: NCN;
}
export const RowBody: React.FC<IProps> = (props) => {
	const { ncn } = props;

	return (
		<React.Fragment>
			<Grid templateColumns="repeat(10, 1fr)" gap={2} w="100%" textAlign="left" pl="1rem">
				<GridItem colSpan={ncn.fotos && ncn.fotos.length === 0 ? 10 : 6}>
					<InfoLine label="Resumen:" value={ncn.resumen} />
					{ncn.observacion && <InfoLine label="Detalles del Incumplimiento:" value={ncn.observacion} />}
				</GridItem>
				{ncn.fotos && ncn.fotos.length > 0 && (
					<GridItem colSpan={4} pr=".5rem">
						<Carousel images={ncn.fotos} />
					</GridItem>
				)}
			</Grid>
		</React.Fragment>
	);
};
