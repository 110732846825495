import { AxiosRequestConfig } from "axios";

import { apiClient, CustomAxiosRequest } from ".";

type putCertificacionPropsType = {
	iteId: string;
	tipo: "ite" | "bim";
	fecha: string | undefined;
};

export const putCertificacion: CustomAxiosRequest<putCertificacionPropsType[], undefined> = (props) => {
	const url = `ite/ite/certificar`;
	const config: AxiosRequestConfig = {
		method: "PUT",
		url,
		headers: {
			"Content-Type": "application/json",
		},
		data: props,
	};
	return apiClient(config);
};
