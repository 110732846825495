import { ComponentStyleConfig } from "@chakra-ui/react";

const componentOverride: ComponentStyleConfig = {
	baseStyle: {
		borderRadius: "0.375rem",
		_hover: {
			filter: "brightness(95%)",
			textDecoration: "none",
		},
		_focus: {
			boxShadow: "none",
		},
	},
	sizes: {
		md: {
			w: "fit-content",
			h: "fit-content",

			padding: "0.125rem 1rem",
			fontFamily: "Arial",
			fontSize: "body3",
			fontWeight: "body3",
			lineHeight: "body3",
			letterSpacing: "body3",
		},
		sm: {
			w: "fit-content",
			h: "fit-content",

			padding: "0.125rem 1rem",
			fontFamily: "Arial",
			fontSize: "caption",
			fontWeight: "caption",
			lineHeight: "caption",
			letterSpacing: "caption",
		},
		lg: {
			w: "fit-content",
			h: "fit-content",

			padding: "0.375rem 1rem",
			fontFamily: "Arial",
			fontSize: "body",
			fontWeight: "body2",
			lineHeight: "body2",
			letterSpacing: "body2",
		},
		fit: {
			w: "fit-content",
			h: "fit-content",
			minWidth: "7rem",
		},
	},
	variants: {
		solidPrimary: {
			backgroundColor: "red.500",
			color: "pureWhite",
			_disabled: {
				backgroundColor: "gray.200",
			},
			_hover: { _disabled: { backgroundColor: "gray.600" } },
		},
		solidPrimaryWithHover: {
			backgroundColor: "red.500",
			color: "pureWhite",
			border: "1px solid",
			borderColor: "red.500",
			_disabled: {
				backgroundColor: "gray.200",
			},
			_hover: { backgroundColor: "pureWhite", color: "red.500", _disabled: { backgroundColor: "gray.600" } },
		},
		solidSecondary: {
			backgroundColor: "pureWhite",
			color: "gray.700",
			_disabled: {
				backgroundColor: "gray.100",
			},
			_hover: {
				color: "red.500",
				_disabled: { backgroundColor: "gray.400" },
			},
		},
		solidTertiary: {
			backgroundColor: "gray.600",
			color: "pureWhite",
			_disabled: {
				backgroundColor: "gray.100",
			},
			_hover: { _disabled: { backgroundColor: "gray.400" } },
		},
		solidPrimaryIcon: {
			backgroundColor: "red.500",
			color: "pureWhite",
			borderRadius: "50%",
			minWidth: "fit-content",
			minHeight: "fit-content",
			boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)",
			"& > svg": {
				stroke: "pureWhite",
			},
			_disabled: {
				backgroundColor: "gray.200",
			},
			_hover: { _disabled: { backgroundColor: "gray.600" } },
		},
		outlinePrimary: {
			backgroundColor: "pureWhite",
			color: "red.500",
			border: "1px solid",
			borderColor: "red.500",
			_disabled: {
				backgroundColor: "gray.200",
			},
			_hover: { backgroundColor: "red.500", color: "pureWhite", _disabled: { backgroundColor: "gray.600" } },
		},
		outlineSecondary: {
			backgroundColor: "transparent",
			color: "gray.500",
			border: "1px solid",
			borderColor: "gray.500",
			_disabled: {
				backgroundColor: "gray.100",
			},
			_hover: {
				color: "gray.700",
				borderColor: "gray.700",
				_disabled: { backgroundColor: "gray.200" },
			},
		},
		outlineTransparent: {
			backgroundColor: "transparent",
			color: "gray.400",
			border: "1px solid",
			borderColor: "gray.400",
			_disabled: {
				backgroundColor: "gray.400",
			},
			_hover: { backgroundColor: "transparent", color: "gray.400" },
		},
		noHoverOutlinePrimary: {
			backgroundColor: "pureWhite",
			color: "red.500",
			border: "1px solid",
			borderColor: "red.500",
		},
		noHoverOutlineSecondary: {
			backgroundColor: "pureWhite",
			color: "gray.400",
			border: "1px solid",
			borderColor: "gray.400",
		},
	},
	defaultProps: {
		size: "md",
	},
};

export default componentOverride;
