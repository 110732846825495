import React, { useState } from "react";

import { Select } from "@chakra-ui/react";
import MapComponent, { Marker } from "react-map-gl";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";

import { useAppDispatch, useAppSelector } from "store/store";
import { actions } from "store/user/reducer";

interface IProps {
	value?: [number, number];
}

const accessToken = "pk.eyJ1IjoiZGh1YiIsImEiOiJjbGUyczIyODQwMXdrM3dseG53MnMxaXI4In0.FYGuulRoBvKZ56vsbIXpzA";

const getStyleUrl = (style: string) => {
	switch (style) {
		case "satellite":
			return "mapbox://styles/mapbox/satellite-v9";
		default:
			return "mapbox://styles/dhub/cl2ypoavr000z14n5af53rj77";
	}
};

export const Mapbox: React.FC<IProps> = (props) => {
	const { value } = props;
	const mapStyle = useAppSelector((state) => state.user.mapStyle);
	const dispatch = useAppDispatch();

	const viewState = useState({
		longitude: (value && value[0]) || -3,
		latitude: (value && value[1]) || 40,
		zoom: value ? 7 : 3,
	})[0];

	return (
		<div>
			<Select defaultValue="default" my=".5rem" onChange={(e) => dispatch(actions.setStyle(e.target.value))}>
				<option value="default">Vista mapa</option>
				<option value="satellite">Vista satélite</option>
			</Select>
			<div style={{ width: "100%", height: "23rem", borderRadius: "0.375rem" }}>
				<MapComponent mapStyle={getStyleUrl(mapStyle)} mapboxAccessToken={accessToken} initialViewState={viewState}>
					{value && (
						<Marker
							key={"marker"}
							longitude={value[0]}
							latitude={value[1]}
							onClick={(e) => {
								e.originalEvent.stopPropagation();
							}}
							color={"var(--chakra-colors-gray-500)"}
							scale={0.6}
							style={{
								cursor: "pointer",
							}}
						/>
					)}
				</MapComponent>
			</div>
		</div>
	);
};
