import React from "react";

import { Breadcrumb as ChakraBreadCrumb, BreadcrumbItem, BreadcrumbLink, Text } from "@chakra-ui/react";
import { Link, useParams } from "react-router-dom";

import { capitalizeFirstLetter } from "helpers/text";
import { useAppSelector } from "store/store";

const Breadcrumb: React.FC = () => {
	const { assetId, sectionId, tableId } = useParams();
	const iteSelected = useAppSelector((state) => state.ites.iteSelected.data);

	const sectionName =
		sectionId === "ite"
			? "ITE"
			: sectionId === "ld"
			? "Activo Digital"
			: sectionId === "portada"
			? "Portada"
			: "Actuaciones";

	const tableName = (tableId: string) => {
		switch (tableId) {
			case "nee":
				return "Eficiencia Energética";
			case "nec":
				return "Estado de Conservación";
			case "nae":
				return "Condiciones de Accesibilidad";
			case "ncn":
				return "Cumplimiento de Normativa";
		}
	};

	return (
		<ChakraBreadCrumb color="gray.500" fontSize="body3">
			{assetId && (
				<BreadcrumbItem>
					<BreadcrumbLink as={Link} to={"/"}>
						<Text fontSize="body3">Portfolio</Text>
					</BreadcrumbLink>
				</BreadcrumbItem>
			)}
			{assetId && (
				<BreadcrumbItem>
					<BreadcrumbLink as={Link} to={`/activo/${assetId}`}>
						<Text fontWeight={!sectionId ? "bold" : undefined} fontSize="body3">
							{iteSelected && capitalizeFirstLetter(iteSelected.asset.nombre)}
						</Text>
					</BreadcrumbLink>
				</BreadcrumbItem>
			)}
			{sectionId && (
				<BreadcrumbItem>
					<BreadcrumbLink as={Link} to={`/activo/${assetId}/${sectionId}`}>
						<Text fontWeight={!tableId ? "bold" : undefined} fontSize="body3">
							{sectionName}
						</Text>
					</BreadcrumbLink>
				</BreadcrumbItem>
			)}
			{tableId && (
				<BreadcrumbItem>
					<Text fontSize="body3" fontWeight="bold">
						{tableName(tableId)}
					</Text>
				</BreadcrumbItem>
			)}
		</ChakraBreadCrumb>
	);
};

export default Breadcrumb;
