import { Text } from "@chakra-ui/react";

export const IsRequiredSymbol: React.FC = () => {
	return (
		<Text color="red.500" fontSize="xl" ml="1">
			*
		</Text>
	);
};
export default IsRequiredSymbol;
