import React from "react";

import {
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Grid,
	GridItem,
	Text,
} from "@chakra-ui/react";

import IndiceIndicator from "common/components/IndiceIndicator";
import { noOfLines } from "helpers/displayWhenPrint";
import { capitalizeFirstLetter } from "helpers/text";

import { RowBody } from "../RowBody";
import { NEE } from "../types";

interface IProps {
	nee: NEE;
	print?: boolean;
}

export const TableRow: React.FC<IProps> = (props) => {
	const { nee, print } = props;

	return (
		<AccordionItem>
			{({ isExpanded }) => (
				<Box style={{ breakInside: "avoid" }}>
					<AccordionButton minHeight="2.75rem" p="0" pl="1rem">
						<Grid
							templateColumns="repeat(7, 1fr)"
							gap={5}
							w="100%"
							textAlign="left"
							alignItems="center"
							overflow="hidden"
						>
							<GridItem colSpan={2}>
								<Text
									title={capitalizeFirstLetter(nee.categoria)}
									textStyle="body3"
									color="gray.800"
									noOfLines={noOfLines(print)}
								>
									{capitalizeFirstLetter(nee.categoria)}
								</Text>
							</GridItem>
							<GridItem colSpan={2}>
								<Text
									title={capitalizeFirstLetter(nee.tipo)}
									textStyle="body3"
									color="gray.800"
									noOfLines={noOfLines(print)}
									pl=".25rem"
								>
									{capitalizeFirstLetter(nee.tipo)}
								</Text>
							</GridItem>
							<GridItem colSpan={2} pl=".5rem">
								{nee.retorno_anos && (
									<Text textStyle="body3" color="gray.800">
										{nee.retorno_anos} años
									</Text>
								)}
							</GridItem>
							<GridItem colSpan={1}>{nee.indice && <IndiceIndicator indice={nee.indice} />}</GridItem>
						</Grid>
						{!print && <AccordionIcon color="gray.500" />}
					</AccordionButton>
					<AccordionPanel borderTop="0.5px solid" borderColor="gray.100" padding="1rem 0" backgroundColor="gray.table">
						{isExpanded && <RowBody nee={nee} />}
					</AccordionPanel>
				</Box>
			)}
		</AccordionItem>
	);
};
