import React from "react";

import { Divider, Grid, GridItem } from "@chakra-ui/react";

import HeaderText from "common/components/Header";

export const TableHeader: React.FC = () => {
	return (
		<React.Fragment>
			<Grid templateColumns="repeat(8, 1fr)" gap={1} w="95%">
				<GridItem colStart={6}>
					<HeaderText label="Ítems" />
				</GridItem>
				<GridItem colStart={7}>
					<HeaderText label="Índice" />
				</GridItem>
			</Grid>
			<Divider mt=".5rem" />
		</React.Fragment>
	);
};
