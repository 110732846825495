import React from "react";

import { Box, BoxProps, Flex, Text } from "@chakra-ui/react";

import { capitalizeFirstLetter } from "helpers/text";

interface IProps {
	estado?: string;
	resultado?: string;
}

const StatusIndicator: React.FC<IProps> = (props) => {
	const { estado, resultado } = props;

	const estadoLC = estado?.toLowerCase();
	const resultadoLC = resultado?.toLowerCase();

	const statusColorSelector = (estado: string) => {
		switch (estado) {
			case "programada":
				return "gray.100";
			case "en proceso":
				return "gray.200";
			case "no definidas":
				return "gray.200";
			case "validado":
				return "green.100";
			case "registrado":
				return "green.100";
			case "completada":
				return "green.100";
			case "completadas":
				return "green.100";
			case "completado":
				return "green.100";
			case "sin Acciones":
				return "green.100";
			case "por validar":
				return "yellow.100";
			case "entregado":
				return "yellow.100";
			case "pendientes":
				return "yellow.100";
			case "por aceptar":
				return "yellow.100";
			case "con acciones":
				return "yellow.100";
			case "rechazado":
				return "red.lightest";
			case "en proyecto":
				return "red.lightest";
			case "por adjudicar":
				return "purple.lightest";
			case "pendiente":
				return "gray.200";
			case "sin activo digitalizado":
				return "yellow.100";
			default:
				return "transparent";
		}
	};

	const fontColorSelector = (estado: string) => {
		switch (estado) {
			case "programada":
				return "gray.500";
			case "en proceso":
				return "gray.600";
			case "no definidas":
				return "gray.600";
			case "registrado":
				return "green.600";
			case "validado":
				return "green.600";
			case "completada":
				return "green.600";
			case "completadas":
				return "green.600";
			case "completado":
				return "green.600";
			case "sin Acciones":
				return "green.600";
			case "por validar":
				return "yellow.700";
			case "entregado":
				return "yellow.700";
			case "pendientes":
				return "yellow.700";
			case "por aceptar":
				return "yellow.700";
			case "con acciones":
				return "yellow.700";
			case "rechazado":
				return "red.600";
			case "en proyecto":
				return "red.600";
			case "por adjudicar":
				return "purple.600";
			case "pendiente":
				return "gray.600";
			case "sin activo digitalizado":
				return "yellow.700";
			default:
				return "transparent";
		}
	};

	const statusBoxProps: BoxProps = {
		borderRadius: "5px",
		justifyContent: "center",
		alignContent: "center",
		height: "100%",
		bgColor: (estadoLC && statusColorSelector(estadoLC)) || "transparent",
	};

	return (
		<Flex>
			{estadoLC ? (
				<Box {...statusBoxProps}>
					<Text textStyle="body3" m=".35rem .75rem" color={fontColorSelector(estadoLC)}>
						{capitalizeFirstLetter(estadoLC)}
					</Text>
				</Box>
			) : (
				<Box>
					<Text textStyle="body3" m=".35rem .75rem" color="gray.800">
						{resultadoLC && capitalizeFirstLetter(resultadoLC)}
					</Text>
				</Box>
			)}
		</Flex>
	);
};

export default StatusIndicator;
