import { ActivoCompletoOutput } from "components/Portfolio/types/activos";

export const columnHeaderLabel = (column: keyof ActivoCompletoOutput) => {
	switch (column) {
		case "estado_ite":
			return "Estado ITE";
		case "nombre":
			return "Activos";
		case "ambito":
			return "Subdirección / Gerencia";
		case "jefatura":
			return "Jefatura";
		case "resultado_ite":
			return "Resultado ITE";
		case "empresa":
			return "Empresa";
		case "tecnico":
			return "Técnico Responsable";
		case "autor":
			return "Autor de la Inspección";
		case "direccion":
			return "Dirección";
		case "codigo_estacion":
			return "Código de Estación";
		case "pk":
			return "PK de Inicio";
		case "via":
			return "Línea asignada";
		case "municipio":
			return "Municipio";
		case "provincia":
			return "Provincia";
		case "tipologia":
			return "Principal Tipología Edificatoria";
		case "usos":
			return "Usos";
		case "especialidades":
			return "Especialidades";
		case "relevancia":
			return "Relevancia";
		case "por_validar":
			return "Prov./def.";
		case "agrupacion":
			return "Agrupación de Edificios";
		case "nee_categoria_mejoras":
			return "Categoría de mejora";
		case "nee_tipo_edificio":
			return "Tipo de edificio (uso)";
		case "nec_indice":
			return "Indice NCE";
		case "prioridad_reparacion":
			return "Prioridad reparación";
		case "nec_total_deficiencias_4":
			return "Nº deficiencias NCSY4";
		case "nec_total_deficiencias_3":
			return "Nº deficiencias NCSY3";
		case "nec_total_deficiencias_2":
			return "Nº deficiencias NCSY2";
		case "nec_total_deficiencias_1":
			return "Nº deficiencias NCSY1";
		case "nee_indice":
			return "Indice NEE";
		case "nee_total_mejoras_3":
			return "Nº mejoras NMEE3";
		case "nee_total_mejoras_2":
			return "Nº mejoras NMEE2";
		case "nee_total_mejoras_1":
			return "Nº mejoras NMEE1";
		case "nee_consumo_energetico_kwh":
			return "Consumo total (kWh/año)";
		case "nee_consumo_por_m2":
			return "Consumo por superficie (kWh/año m2)";
		case "nae_indice":
			return "Indice NAE";
		case "nae_total_incumplimientos":
			return "Nº incumplimientos";
		case "ncn_indice":
			return "Indice NCNE";
		case "ncn_total_incumplimientos_3":
			return "Nº incumplimientos NCNSY3";
		case "ncn_total_incumplimientos_2":
			return "Nº incumplimientos NCNSY2";
		case "ncn_total_incumplimientos_1":
			return "Nº incumplimientos NCNSY1";
		case "fecha_visita_reportada":
			return "Fecha Visita";
		case "fecha_visita_programada":
			return "Fecha Visita";
		case "fecha_visita_reprogramada":
			return "Fecha Visita";
		case "fecha_visita_programada_prox_ite":
			return "Próxima Visita";
		case "fecha_visita_reprogramada_prox_ite":
			return "Próxima Visita";
		case "fecha_entrega_bim":
			return "Entrega Modelo";
		case "fecha_certificacion_bim":
			return "Cert. Modelo";
		case "fecha_entrega":
			return "Entrega ITE";
		case "fecha_certificacion_ite":
			return "Cert. ITE";
		case "numero_comentarios":
			return "Comentarios";
		default:
			return column;
	}
};
