import React from "react";

import { Flex, ChakraProps } from "@chakra-ui/react";

interface IProps extends React.PropsWithChildren, ChakraProps {}

const Page: React.FC<IProps> = (props) => {
	const { children, ...chakraProps } = props;
	return (
		<Flex
			h="99vh"
			w="100%"
			p="2rem"
			flexDirection="column"
			style={{ pageBreakAfter: "always", pageBreakInside: "avoid" }}
			{...chakraProps}
		>
			{children}
		</Flex>
	);
};

export default Page;
