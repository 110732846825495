import React from "react";

import { Button, Link, Text, WrapItem } from "@chakra-ui/react";
import { Link as ReactRouterLink } from 'react-router-dom'

type IProps = {
	name: string;
	path: boolean;
};

export const TableButton: React.FC<IProps> = (props) => {
	const { name, path } = props;

	const getHRef = () => {
		switch (name) {
			case "Inspección":
				return ("/portfolio/inspeccion");
			case "Localización":
				return ("/portfolio/localizacion");
			case "Tipología":
				return ("/portfolio/tipologia");
			case "E. Conservación":
				return ("/portfolio/nec");
			case "E. Energética":
				return ("/portfolio/nee");
			case "Accesibilidad":
				return ("/portfolio/nae");
			case "C. Normativa":
				return ("/portfolio/ncn");
			case "Certificación":
				return ("/portfolio/certificacion");
			default:
				return ("/portfolio/inspeccion");
		}
	};

	return (
		<React.Fragment>
			<WrapItem>
				<Link as={ReactRouterLink} to={getHRef()} _hover={{ textDecoration: "none" }}>
					<Button
						size="md"
						borderRadius="5px"
						h="auto"
						minH="2rem"
						variant="outline"
						borderWidth="1.5px"
						borderColor={path ? "gray.500" : "gray.300"}
						_hover={path ? {} : { bgColor: "gray.100" }}
					>
						<Text textStyle="body3" color={path ? "gray.700" : "gray.400"}>
							{name}
						</Text>
					</Button>
				</Link>
			</WrapItem>
		</React.Fragment>
	);
};
