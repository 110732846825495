import React from "react";

import { SearchIcon } from "@chakra-ui/icons";
import { Button, Input, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react";

import { actions as portfolioActions } from "store/portfolio/reducer";
import { useAppDispatch, useAppSelector } from "store/store";

export function FreeTextInput() {
	const [search, setSearch] = React.useState<string>("");
	const filterSelection = useAppSelector((state) => state.portfolio.filterSelection);

	const dispatch = useAppDispatch();

	const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			dispatch(portfolioActions.setFilterSelection({ ...filterSelection, searchText: search }));
		}
	};

	const handleSearchButton = () => {
		if (search) {
			dispatch(portfolioActions.setFilterSelection({ ...filterSelection, searchText: search }));
		}
	};
	React.useEffect(() => {
		if (filterSelection === null || filterSelection.searchText === undefined) {
			setSearch("");
		} else {
			setSearch(filterSelection.searchText);
		}
	}, []);

	React.useEffect(() => {
		if (filterSelection === null || filterSelection.searchText === undefined) {
			setSearch("");
		}
	}, [filterSelection]);

	return (
		<InputGroup>
			<InputLeftElement pointerEvents="none" color="gray.300" p=".6rem">
				<SearchIcon />
			</InputLeftElement>
			<Input pl="2rem" value={search} onChange={(e) => setSearch(e.target.value)} onKeyPress={(e) => handleSearch(e)} />
			<InputRightElement width="4.5rem" p=".3rem">
				<Button h="1.75rem" size="md" onClick={handleSearchButton}>
					Buscar
				</Button>
			</InputRightElement>
		</InputGroup>
	);
}
