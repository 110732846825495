import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import React from "react";

import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import {
	Checkbox,
	Flex,
	Input,
	Popover,
	PopoverCloseButton,
	PopoverContent,
	PopoverTrigger,
	Text,
	useDisclosure,
} from "@chakra-ui/react";
import { Calendar } from "react-date-range";
import { es } from "react-date-range/dist/locale";

import { ActivoCompletoOutput } from "components/Portfolio/types/activos";
import { capitalizeFirstLetter } from "helpers/text";

interface IProps {
	column: keyof ActivoCompletoOutput;
	value: string | undefined | null;
	editable: boolean;
	onChange?: (d: Date | undefined) => void;
}

const formatter = Intl.DateTimeFormat("es-ES", { day: "numeric", month: "numeric", year: "numeric" });

export const PortfolioDateInput: React.FC<IProps> = (props) => {
	const { column, value, editable, onChange } = props;
	const { onToggle, isOpen, onClose } = useDisclosure();

	const date = value ? new Date(value) : undefined;

	const valueFormatter = (value: Date | undefined) => {
		if (!value) {
			return editable ? "-" : "No entregado";
		} else {
			return capitalizeFirstLetter(formatter.format(value));
		}
	};

	const handleCheckbox = () => {
		if (!onChange) return;
		if (value) onChange(undefined);
		else onChange(new Date());
	};

	return (
		<React.Fragment>
			<Flex h="100%" gap="0 0.5rem" alignItems="center">
				{editable ? (
					<Checkbox
						colorScheme={column.includes("bim") ? "teal" : "green"}
						readOnly={!editable}
						isChecked={!!value}
						value="test"
						onChange={handleCheckbox}
					/>
				) : !value ? (
					<CloseIcon color="gray.300" />
				) : (
					<CheckIcon color={column.includes("bim") ? "teal.500" : "green.500"} />
				)}
				{editable ? (
					<Popover returnFocusOnClose={false} isOpen={isOpen} onClose={onClose} placement="auto" closeOnBlur={true}>
						<PopoverTrigger>
							<Input onClick={onToggle} value={valueFormatter(date).toString()} w="80%" onChange={console.log} />
						</PopoverTrigger>
						<PopoverContent w="100%" h="100%">
							<PopoverCloseButton />
							<Calendar
								onChange={(v) => {
									onChange && onChange(v);
									onToggle();
								}}
								locale={es}
							/>
						</PopoverContent>
					</Popover>
				) : (
					<Text
						title={valueFormatter(date).toString()}
						textStyle="body3"
						color={value ? "gray.800" : "gray.400"}
						pl=".25rem"
					>
						{valueFormatter(date)}
					</Text>
				)}
			</Flex>
		</React.Fragment>
	);
};
