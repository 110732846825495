import React from "react";

import { Image, Flex, Text, Box } from "@chakra-ui/react";

import adifLogo from "assets/images/adif_wordmark.svg";
import { urlFromFilepath } from "common/components/Carousel";
import { useAppSelector } from "store/store";

import Page from "./Page";

export const Cover: React.FC = () => {
	const fichaInventario = useAppSelector((state) => state.ites.inv.data?.contexto);
	const asset = useAppSelector((state) => state.ites.iteSelected.data?.asset);

	const getImageSignedUrl = (imageKey?: string) => {
		if (!imageKey) return "/placeholder.png";
		try {
			const fp = JSON.parse(imageKey).filter((it) => it._is_deleted !== true)[0].filepath;
			if (!fp) return "/placeholder.png";
			return urlFromFilepath(fp);
		} catch (err) {
			return "/placeholder.png";
		}
	};

	return (
		<React.Fragment>
			<Page>
				<Flex flexDirection="row" justifyContent="space-between" alignItems="center" mb="2rem">
					<Flex flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
						<Text textStyle="h5" color="#00703c" mb=".25rem">
							Resultado de la Inspección Técnica
						</Text>
						<Text textStyle="h4" color="dark">
							{fichaInventario?.nombre}
						</Text>
						<Text color="#444" textStyle="h5" marginTop="-0.50rem">
							{asset?.fecha_visita_reportada &&
								new Date(asset.fecha_visita_reportada).toLocaleDateString("es-ES", {
									month: "long",
									year: "numeric",
									day: "numeric",
								})}
						</Text>
					</Flex>
					<Image src={adifLogo} alt="Adif Logo" height="4rem" />
				</Flex>
				{fichaInventario?.foto_portada !== undefined && (
					<Flex gap="1rem" flexDirection="column" flex="1">
						<Image
							my="auto"
							src={getImageSignedUrl(fichaInventario.foto_portada)}
							alt="Foto de portada"
							onError={({ currentTarget }) => {
								currentTarget.onerror = null; // prevents looping
								currentTarget.src = "/placeholder.png";
							}}
						/>
					</Flex>
				)}
			</Page>
			<Page gap="2rem">
				<Box mr="auto">
					<Text textStyle="h5" fontFamily="heading">
						Descripción General del Activo
					</Text>
				</Box>
				{fichaInventario?.descripcion_general !== undefined && (
					<Flex flexDirection="column" gap="1rem">
						<Text textStyle="body3" color="#006935">
							Descripción General
						</Text>
						<Text textStyle="body" fontFamily="heading">
							{fichaInventario.descripcion_general}
						</Text>
					</Flex>
				)}
			</Page>
		</React.Fragment>
	);
};
