import { DateTime } from "luxon";

import { useAppSelector } from "store/store";

import { ITEListOutput } from "../types/ite";
import { fechaPrimerEvento, fechaUltimoEvento } from "../utils/fechasIte";

export const useAltaITESegunEstado = () => {
	const iteList: ITEListOutput[] = useAppSelector((state) => state.ites.iteList.data);
	const features = useAppSelector((state) => state.user.features);

	const now = DateTime.now();
	const lastApproved: ITEListOutput | undefined = [...iteList]
		.filter((it) => !!it.fecha_registro)
		.sort((prev, next) => fechaUltimoEvento(next).toSeconds() - fechaUltimoEvento(prev).toSeconds())[0];
	const nextITE: ITEListOutput | undefined = [...iteList]
		// La proxima ITE tiene que empezar en el futuro y no estar registrada
		.filter((it) => !it.fecha_registro && fechaPrimerEvento(it) > now)
		// Y de entre esas ITEs que estén en el futuro, quiero encontrar la primera (aunque deberia haber solo una)
		.sort((prev, next) => fechaPrimerEvento(prev).toSeconds() - fechaPrimerEvento(next).toSeconds())[0];

	const activeITEs = iteList.filter((it) => !it.fecha_registro && fechaPrimerEvento(it).minus({ years: 1 }) < now);

	const minDate = lastApproved ? fechaUltimoEvento(lastApproved).plus({ days: 1 }) : now;
	const maxDate = nextITE ? fechaPrimerEvento(nextITE).minus({ years: 1 }) : undefined;

	const allowsNewITE =
		features.data.includes("add-ite") &&
		!activeITEs.length &&
		(!maxDate || maxDate > minDate) &&
		!iteList.find((it) => it.tipo === "Según estado" && !it.fecha_registro);

	return { allowsNewITE, minDate, maxDate, defaultDate: minDate };
};
