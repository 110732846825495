import React from "react";

import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";

import { defaultValues } from "../../../defaultValues";
import { FilterDropDown } from "../../components/FilterDropDown";
import { FilterDropDownCertificacion } from "../../components/FilterDropDown/FilterDropDownCertificacion";

export const CertificacionHeader: React.FC = () => {
	return (
		<Box h=".5rem" background="transparent" p="0 1rem" mb=".5rem">
			<Flex width="100%" height="100%" alignItems="center">
				<Grid templateColumns="repeat(32, 1fr)" w="100%" pl=".3rem">
					<GridItem colSpan={2}>
						<FilterDropDown column="por_validar" values={defaultValues.por_validar} />
					</GridItem>
					<GridItem colSpan={6}>
						<FilterDropDown column="nombre" />
					</GridItem>
					<GridItem colSpan={4}>
						<FilterDropDown column="empresa" values={defaultValues.empresa} />
					</GridItem>
					<GridItem colSpan={4}>
						<FilterDropDownCertificacion column="fecha_entrega_bim" />
					</GridItem>
					<GridItem colSpan={4}>
						<FilterDropDownCertificacion column="fecha_certificacion_bim" />
					</GridItem>
					<GridItem colSpan={4}>
						<FilterDropDownCertificacion column="fecha_entrega" />
					</GridItem>
					<GridItem colSpan={4}>
						<FilterDropDownCertificacion column="fecha_certificacion_ite" />
					</GridItem>
					<GridItem colSpan={4}>
						<FilterDropDown column="numero_comentarios" />
					</GridItem>
				</Grid>
			</Flex>
		</Box>
	);
};
