import { AxiosRequestConfig } from "axios";

import { ITECommentInput, ITECommentOutput } from "components/ITE/types/comentarios";

import { apiClient, CustomAxiosRequest } from ".";

type getComentariosPropsType = {
	iteId: string;
};

export const getComentarios: CustomAxiosRequest<getComentariosPropsType, ITECommentOutput[]> = (props) => {
	const url = `ite/ite/comentarios`;
	const config: AxiosRequestConfig = {
		method: "GET",
		url,
		headers: {
			"Content-Type": "application/json",
		},
		params: props,
	};
	return apiClient(config);
};

export const postComentario: CustomAxiosRequest<ITECommentInput, void> = (data) => {
	const url = `ite/ite/comentarios`;
	const config: AxiosRequestConfig = {
		method: "POST",
		url,
		headers: {
			"Content-Type": "application/json",
		},
		data: data,
	};
	return apiClient(config);
};
