import React from "react";

import { Accordion, Box, Flex, Select } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { NoElementsText } from "common/components/NoElementsText";
import { SectionHeader } from "common/components/SectionHeader";
import { displayWhenPrint } from "helpers/displayWhenPrint";
import { expandAllItems } from "helpers/expandedItems";
import { formsUrl } from "helpers/formsUrl";
import { capitalizeFirstLetter } from "helpers/text";
import { getNaeListThunk } from "store/ITE/thunks";
import { useAppDispatch, useAppSelector } from "store/store";

import { fechaPrimerEvento } from "../utils/fechasIte";
import { titloIte } from "../utils/tituloIte";
import { TableHeader } from "./Header";
import { TableRow } from "./Row";
import { NAE } from "./types";

type IProps = {
	print?: boolean;
};

export const TablaAccesibilidad: React.FC<IProps> = (props) => {
	const { print } = props;

	const { iteId } = useParams();
	const [loading, setLoading] = React.useState(true);
	const dispatch = useAppDispatch();
	const { data: nae } = useAppSelector((state) => state.ites.nae);
	const iteSelected = useAppSelector((state) => state.ites.iteSelected.data);
	const iteListItemSelected = useAppSelector((state) => state.ites.iteList.data.find((it) => it.id === iteId));

	const [filteredNae, setFilteredNae] = React.useState<NAE[]>(nae);

	React.useEffect(() => {
		if (!iteId) return;
		dispatch(getNaeListThunk({ iteId })).finally(() => {
			setLoading(false);
		});
	}, [iteId, dispatch]);

	React.useEffect(() => {
		setFilteredNae(nae);
	}, [nae]);

	const categorias = [...new Set(nae.map((nae: NAE) => nae.categoria))];

	const handleFilter = (data: NAE[], filter: string, field: string) => {
		const filtered = data.filter((nae) => nae[field] === filter);
		filter ? setFilteredNae(filtered) : setFilteredNae(nae);
	};

	if (loading) return <React.Fragment />;
	return (
		<Box p="1rem" rounded="md" boxShadow="sm" bgColor="white" pb="2rem" overflow="hidden" overflowY="auto">
			<SectionHeader
				formLink={formsUrl(iteSelected?.ite.record_ae_id)}
				ite={
					iteListItemSelected
						? titloIte(fechaPrimerEvento(iteListItemSelected).toJSDate(), iteListItemSelected.tipo, "short")
						: "ITE"
				}
				tableTitle="Condiciones de Accesibilidad"
				print={print}
			/>
			{filteredNae.length > 0 && (
				<Flex align="center" mb="1rem" flexDirection="row" m="1rem" sx={{ display: displayWhenPrint(print) }}>
					<Box w="50%" mr="2rem">
						<Select
							onChange={(e) => handleFilter(nae, e.target.value, "categoria")}
							placeholder="Todas las categorías"
							size="md"
							fontSize=".75rem"
							borderRadius="md"
						>
							{categorias.sort().map((categoria) => (
								<option key={categoria} value={categoria}>
									{capitalizeFirstLetter(categoria)}
								</option>
							))}
						</Select>
					</Box>
					<Box w="50%"></Box>
				</Flex>
			)}
			<TableHeader />
			<Box mr="1rem" overflow="hidden" overflowY="auto">
				<Accordion allowToggle h="100%" index={print ? expandAllItems(filteredNae) : undefined}>
					{filteredNae.length > 0 ? (
						filteredNae.map((data, i) => <TableRow key={i} nae={data} print={print} />)
					) : (
						<NoElementsText section={"nae"} />
					)}
				</Accordion>
			</Box>
		</Box>
	);
};
