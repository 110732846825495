import { ActionReducerMapBuilder } from "@reduxjs/toolkit";

import { THUNK_STATE } from "config/types";

import { ISlice } from "./reducer";
import {
	getAssetsThunk,
	getProjectsForCurrentUserThunk,
	getTecnicosThunk,
	getUserEmailThunk,
	getUserFeaturesThunk,
} from "./thunks";

export const activeProjectsExtraReducers = (builder: ActionReducerMapBuilder<ISlice>) => [
	builder.addCase(getProjectsForCurrentUserThunk.pending, (state) => ({
		...state,
		activeProjects: {
			thunkState: THUNK_STATE.PENDING,
			data: state.activeProjects.data,
		},
	})),
	builder.addCase(getProjectsForCurrentUserThunk.rejected, (state) => ({
		...state,
		activeProjects: {
			thunkState: THUNK_STATE.REJECTED,
			data: state.activeProjects.data,
		},
	})),
	builder.addCase(getProjectsForCurrentUserThunk.fulfilled, (state, action) => ({
		...state,
		activeProjects: {
			thunkState: THUNK_STATE.FULFILLED,
			data: action.payload,
		},
	})),
];

export const activeAssetsExtraReducers = (builder: ActionReducerMapBuilder<ISlice>) => [
	builder.addCase(getAssetsThunk.pending, (state, action) => {
		state.activeAssets[action.meta.arg.projectRef] = {
			thunkState: THUNK_STATE.PENDING,
			data: state.activeAssets[action.meta.arg.projectRef]?.data || [],
		};
	}),
	builder.addCase(getAssetsThunk.rejected, (state, action) => {
		state.activeAssets[action.meta.arg.projectRef] = {
			thunkState: THUNK_STATE.REJECTED,
			data: state.activeAssets[action.meta.arg.projectRef]?.data || [],
		};
	}),
	builder.addCase(getAssetsThunk.fulfilled, (state, action) => {
		state.activeAssets[action.meta.arg.projectRef] = {
			thunkState: THUNK_STATE.FULFILLED,
			data: action.payload.map((rawAsset) => ({
				...rawAsset,
				geometry: rawAsset.geometria || undefined,
			})),
		};
	}),
];

export const userFeaturesExtraReducer = (builder: ActionReducerMapBuilder<ISlice>) => [
	builder.addCase(getUserFeaturesThunk.pending, (state) => ({
		...state,
		features: {
			thunkState: THUNK_STATE.PENDING,
			data: state.features.data,
		},
	})),
	builder.addCase(getUserFeaturesThunk.rejected, (state) => ({
		...state,
		features: {
			thunkState: THUNK_STATE.REJECTED,
			data: state.features.data,
		},
	})),
	builder.addCase(getUserFeaturesThunk.fulfilled, (state, action) => ({
		...state,
		features: {
			thunkState: THUNK_STATE.FULFILLED,
			data: action.payload,
		},
	})),
];

export const userEmailExtraReducer = (builder: ActionReducerMapBuilder<ISlice>) => [
	builder.addCase(getUserEmailThunk.pending, (state) => ({
		...state,
		thunkState: THUNK_STATE.PENDING,
	})),
	builder.addCase(getUserEmailThunk.rejected, (state) => ({
		...state,
		thunkState: THUNK_STATE.REJECTED,
	})),
	builder.addCase(getUserEmailThunk.fulfilled, (state, action) => ({
		...state,
		userEmail: action.payload,
		thunkState: THUNK_STATE.FULFILLED,
	})),
];

export const tecnicosExtraReducer = (builder: ActionReducerMapBuilder<ISlice>) => [
	builder.addCase(getTecnicosThunk.pending, (state) => {
		state.tecnicos = {
			thunkState: THUNK_STATE.PENDING,
			data: state.tecnicos?.data || [],
		};
	}),
	builder.addCase(getTecnicosThunk.rejected, (state) => {
		state.tecnicos = {
			thunkState: THUNK_STATE.REJECTED,
			data: state.tecnicos?.data || [],
		};
	}),
	builder.addCase(getTecnicosThunk.fulfilled, (state, action) => {
		state.tecnicos = {
			thunkState: THUNK_STATE.FULFILLED,
			data: action.payload,
		};
	}),
];
