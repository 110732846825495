import React from "react";

import {
	Button,
	Grid,
	GridItem,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Divider,
	Text,
} from "@chakra-ui/react";

import { InfoLine } from "common/components/InfoLine";
import { useAppSelector } from "store/store";

type ModalProps = {
	isOpen: boolean;
	onClose: () => void;
};

export const DatosRefModal: React.FC<ModalProps> = (props) => {
	const { isOpen, onClose } = props;
	const neeContexto = useAppSelector((state) => state.ites.neeContexto.data);

	return (
		<React.Fragment>
			{neeContexto && (
				<Modal isOpen={isOpen} onClose={onClose} size="2xl">
					<ModalOverlay />
					<ModalContent>
						<ModalHeader fontSize="sm">Datos de referencia</ModalHeader>
						<Divider w="95%" alignSelf="center" mb="1rem" />
						<ModalCloseButton />
						<ModalBody>
							<Grid templateColumns="repeat(8, 1fr)" gap={5}>
								<GridItem colSpan={3}>
									<Text textStyle="body3" color="green.600" mb=".5rem">
										Coste de energías existentes
									</Text>
									<InfoLine
										label="Coste Electricidad:"
										value={neeContexto.coste_electricidad}
										currency={true}
										suffix={"/kWh"}
									/>
									<InfoLine
										label="Coste Gas Natural:"
										value={neeContexto.coste_gas_natural}
										currency={true}
										suffix={"/kWh"}
									/>
									<InfoLine
										label="Coste Gasoléo:"
										value={neeContexto.coste_gasoleo_c}
										currency={true}
										suffix={"/kWh"}
									/>
									<InfoLine
										label="Otro coste de energía:"
										value={neeContexto.coste_otro}
										currency={true}
										suffix={"/kWh"}
									/>
								</GridItem>
								<GridItem colSpan={5}>
									<Text textStyle="body3" color="green.600" mb=".5rem">
										Factores de emisiones de CO<Text as="sub">2</Text>
									</Text>
									<InfoLine
										label="Factor de emisiones de Electricidad:"
										value={neeContexto.factor_emision_electricidad}
										suffix={"kgCO2/kWh"}
									/>
									<InfoLine
										label="Factor de emisiones de Gas Natural:"
										value={neeContexto.factor_emision_gas_natural}
										suffix={"kgCO2/kWh"}
									/>
									<InfoLine
										label="Factor de emisiones de Gasoléo:"
										value={neeContexto.factor_emision_gasoleo_c}
										suffix={"kgCO2/kWh"}
									/>
									<InfoLine
										label="Otro factor de emisiones:"
										value={neeContexto.factor_emision_otro}
										suffix={"kgCO2"}
									/>
								</GridItem>
							</Grid>
						</ModalBody>
						<Divider w="95%" alignSelf="center" mt="1rem" />
						<ModalFooter>
							<Button mr={3} onClick={onClose} backgroundColor="gray.400" color="white" size="md" w="6rem" h="2rem">
								Cerrar
							</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
			)}
		</React.Fragment>
	);
};
