import React from "react";

import { Box, Grid, GridItem, Image } from "@chakra-ui/react";

import Carousel, { thumbnailUrlFromFilepath } from "common/components/Carousel";
import { InfoLine } from "common/components/InfoLine";

import { Sistema } from "../types";

interface IProps {
	sistema: Sistema;
	print?: boolean;
}
export const RowBody: React.FC<IProps> = (props) => {
	const { sistema, print } = props;

	return (
		<React.Fragment>
			<Grid gap={2} gridTemplateColumns="2fr 1fr" w="100%" textAlign="left" pl="1rem">
				<GridItem colSpan={print ? 2 : 1}>
					<InfoLine label="Descripción:" value={sistema.description} />
				</GridItem>
				<GridItem colSpan={print ? 2 : 1}>
					{sistema.images && sistema.images.length > 0 && !print && <Carousel images={sistema.images} />}
					{print && sistema.images && sistema.images.length > 0 && (
						<Box p=".5rem" w="100%" h="250px">
							<Image src={thumbnailUrlFromFilepath(sistema?.images[0].filepath)} objectFit="scale-down" h="250px" />
						</Box>
					)}
				</GridItem>
			</Grid>
		</React.Fragment>
	);
};
