import React from "react";

import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Tooltip, Flex, Icon } from "@chakra-ui/react";

interface IProps {
	info: string;
}

const InfoHover: React.FC<IProps> = (props) => {
	const { info } = props;

	return (
		<React.Fragment>
			{info && (
				<Tooltip label={info} placement="top" hasArrow>
					<Flex
						p="0.15rem"
						borderRadius="50%"
						alignItems="center"
						justifyContent="center"
						w="1.25rem"
						h="1.25rem"
						_hover={{ bg: "gray.300", borderRadius: "50%" }}
					>
						<Icon
							as={InfoOutlineIcon}
							fontSize="1.15rem"
							color="gray.400"
							_hover={{ color: "gray.600", cursor: "pointer" }}
						/>
					</Flex>
				</Tooltip>
			)}
		</React.Fragment>
	);
};

export default InfoHover;
