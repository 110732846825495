import React from "react";

import { Box, BoxProps, Grid, GridItem, GridItemProps, GridProps } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

import { PortfolioBar } from "components/Portfolio/Lista/components/Bar";
import { AssetsMap } from "components/Portfolio/Mapa/AssetsMap";
import { MapaSideBar } from "components/Portfolio/Mapa/MapaSideBar";

import { PortfolioList } from "../../components/Portfolio/Lista";

export const Portfolio: React.FC = () => {
	const location = useLocation();
	const isMap = location.pathname.includes("mapa");
	const isList = location.pathname.includes("portfolio");

	const gridProps: GridProps = {
		templateColumns: "repeat(12, 1fr)",
		templateRows: "repeat(3, 1fr)",
		maxH: { xs: "100%", sm: "88vh" },
		overflow: "hidden",
		height: "100%",
		columnGap: "1rem",
		padding: "0 1rem",
	};

	const boxProps: BoxProps = {
		overflow: "hidden",
		padding: "0 1rem",
	};

	const mapSideBarProps: GridItemProps = {
		colSpan: 3,
		rowSpan: 1,
		zIndex: "4",
	};

	const sideBarGridProps: GridItemProps = {
		colSpan: 12,
		rowSpan: 2,
		mb: "1.5rem",
		zIndex: "4",
	};

	const sideBarProps: GridItemProps = isMap ? mapSideBarProps : sideBarGridProps;

	const secondGridItemProps: GridItemProps = {
		colSpan: 12,
		rowSpan: 2,
		zIndex: "2",
	};

	return (
		<React.Fragment>
			{isMap && (
				<Grid {...gridProps}>
					<GridItem {...sideBarProps}>
						<MapaSideBar />
					</GridItem>
					<GridItem {...secondGridItemProps}>
						<AssetsMap />
					</GridItem>
				</Grid>
			)}
			{isList && (
				<Box {...boxProps}>
					<PortfolioBar />
					<PortfolioList />
				</Box>
			)}
		</React.Fragment>
	);
};
