import { Auth } from "@aws-amplify/auth";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { JwtPayload } from "jsonwebtoken";
import jwtDecode from "jwt-decode";

import { getAssets, getProjectsForCurrentUser } from "api/configuration";
import { getUserFeatures } from "api/features";
import { getTecnicos } from "api/tecnicos";
import { SLICE_NAMES } from "store/constant";

export const getUserFeaturesThunk = createAsyncThunk(`${SLICE_NAMES.USERS}/getFeaturesPermissions`, async () => {
	const features = await getUserFeatures();
	return features.data;
});

export const getUserEmailThunk = createAsyncThunk(`${SLICE_NAMES.USERS}/getUserEmail`, async () => {
	const session = await Auth.currentSession();
	const token = session.getIdToken().getJwtToken();
	const decoded = jwtDecode<JwtPayload>(token);
	return typeof decoded === "object" ? decoded?.email.toLowerCase() || "" : "";
});

export const getProjectsForCurrentUserThunk = createAsyncThunk(
	`${SLICE_NAMES.USERS}/getProjectsForCurrentUserThunk`,
	async () => {
		const projectModules = await getProjectsForCurrentUser(null);
		return projectModules.data.data;
	},
);

type getAssetsThunkPropsType = {
	projectRef: string;
};
export const getAssetsThunk = createAsyncThunk(
	`${SLICE_NAMES.USERS}/getAssetsThunk`,
	async (payload: getAssetsThunkPropsType) => {
		const { projectRef } = payload;
		const projectAssets = await getAssets(projectRef);
		return projectAssets.data.data;
	},
);

export const getTecnicosThunk = createAsyncThunk(`${SLICE_NAMES.USERS}/getTecnicosThunk`, async () => {
	return await getTecnicos("adif-ite");
});

export const userThunks = {
	getUserEmailThunk,
	getProjectsForCurrentUserThunk,
	getAssetsThunk,
	getUserFeaturesThunk,
	getTecnicosThunk,
};
