import React from "react";

import { Grid, GridItem } from "@chakra-ui/react";

import Carousel from "common/components/Carousel";
import { InfoLine } from "common/components/InfoLine";
import { defaultValuesSistemas } from "components/Portfolio/defaultValues";
import { sistemaLabel } from "helpers/tableLabels";
import { capitalizeFirstLetter } from "helpers/text";

import { NEC } from "../types";

export const textofValue = (value: number) => {
	switch (value) {
		case 1:
			return "1 - Sin defecto o daño, o con deterioros leves no reseñables";
		case 2:
			return "2 - Con defecto o daño puntual. Sin previsión de nuevos daños o defectos o evolucción de los existentes";
		case 3:
			return "3 - Con daños o defectos extendidos y/o prevision de avance de los mismos";
		case 4:
			return "4 - Daños que pongan en riesgo la seguridad de personas o la continuidad del servicio ferroviario";
		default:
			return value;
	}
};
interface IProps {
	nec: NEC;
}
export const RowBody: React.FC<IProps> = (props) => {
	const { nec } = props;

	return (
		<React.Fragment>
			<Grid templateColumns="repeat(10, 1fr)" w="100%" textAlign="left" pl="1rem">
				<GridItem colSpan={nec.fotos && nec.fotos.length > 0 ? 6 : 10}>
					<Grid templateColumns="repeat(10, 1fr)" w="100%" textAlign="left" pl="1rem">
						<GridItem colSpan={5} pr=".5rem">
							<InfoLine label="Descripción del defecto:" value={nec.defecto} />
							<InfoLine label="Ubicación del daño:" value={nec.ubicacion} />
							<InfoLine label="Causa probable:" value={nec.causa} />

							<InfoLine
								label="Otros sistemas afectados:"
								value={nec.otros_sistemas_afectados
									.map((sistema) =>
										capitalizeFirstLetter(
											sistemaLabel(defaultValuesSistemas.find((it) => it.value === sistema)?.label ?? sistema),
										),
									)
									.join(", ")}
							/>
						</GridItem>
						<GridItem colSpan={5} ml=".25rem">
							<InfoLine label="Intensidad del daño:" value={textofValue(nec.intensidad)} />
							<InfoLine label="Influencia:" value={nec.influencia} />
							<InfoLine label="Descripción de la propuesta:" value={nec.propuesta_reparacion} />
							<InfoLine label="Prioridad de la reparación propuesta:" value={nec.prioridad_reparacion} />
							<InfoLine label="Acciones de seguimiento asociadas:" value={nec.acciones_seguimiento} />
						</GridItem>
					</Grid>
				</GridItem>
				{nec.fotos && nec.fotos.length > 0 && (
					<GridItem colSpan={4} pr=".5rem">
						{nec.fotos && nec.fotos.length > 0 && <Carousel images={nec.fotos} />}
					</GridItem>
				)}
			</Grid>
		</React.Fragment>
	);
};
