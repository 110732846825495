import React from "react";

import { Accordion, Box } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { NoElementsText } from "common/components/NoElementsText";
import { SectionHeader } from "common/components/SectionHeader";
import { expandAllItems } from "helpers/expandedItems";
import { formsUrl } from "helpers/formsUrl";
import { getNcnListThunk } from "store/ITE/thunks";
import { useAppDispatch, useAppSelector } from "store/store";

import { fechaPrimerEvento } from "../utils/fechasIte";
import { titloIte } from "../utils/tituloIte";
import { TableHeader } from "./Header";
import { TableRow } from "./Row";

type IProps = {
	print?: boolean;
};

export const TablaNormativa: React.FC<IProps> = (props) => {
	const { print } = props;

	const { iteId } = useParams();
	const [loading, setLoading] = React.useState(true);

	const dispatch = useAppDispatch();
	React.useEffect(() => {
		if (!iteId) return;
		dispatch(getNcnListThunk({ iteId })).finally(() => {
			setLoading(false);
		});
	}, [iteId, dispatch]);

	const iteSelected = useAppSelector((state) => state.ites.iteSelected.data);
	const iteListItemSelected = useAppSelector((state) => state.ites.iteList.data.find((it) => it.id === iteId));
	const { data: ncn } = useAppSelector((state) => state.ites.ncn);

	if (loading) return <React.Fragment />;
	return (
		<Box p="1rem" rounded="md" boxShadow="sm" bgColor="white" pb="2rem" overflow="hidden" overflowY="auto">
			<SectionHeader
				formLink={formsUrl(iteSelected?.ite.record_cn_id)}
				ite={
					iteListItemSelected
						? titloIte(fechaPrimerEvento(iteListItemSelected).toJSDate(), iteListItemSelected.tipo, "short")
						: "ITE"
				}
				tableTitle="Cumplimiento de Normativa"
				print={print}
			/>
			<TableHeader />
			<Box mr="1rem">
				<Accordion allowToggle h="100%" index={print ? expandAllItems(ncn) : undefined}>
					{ncn.length > 0 ? ncn.map((data, i) => <TableRow key={i} ncn={data} print={print} />) : <NoElementsText />}
				</Accordion>
			</Box>
		</Box>
	);
};
