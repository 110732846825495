import React from "react";

import { Flex, Img, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import arupRedLogo from "assets/images/arup_red.svg";

export const Topbar: React.FC = () => {
	return (
		<Flex
			width="100%"
			height="3rem"
			backgroundColor="pureWhite"
			alignItems="center"
			justifyContent="space-between"
			padding="0 2rem"
			zIndex="3"
		>
			<Flex as={Link} to={"/"}>
				<Text ml="1rem" pt=".15rem" fontFamily="heading">
					Gestor de Activos
				</Text>
			</Flex>
			<Flex>
				<Img src={arupRedLogo} alt="Arup Red Logo" />
			</Flex>
		</Flex>
	);
};
