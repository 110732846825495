import React from "react";

import {
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Flex,
	Grid,
	GridItem,
	Text,
} from "@chakra-ui/react";

import IndiceIndicator from "common/components/IndiceIndicator";
import InfoHover from "common/components/InfoHover";
import { defaultValuesSistemas } from "components/Portfolio/defaultValues";
import { noOfLines } from "helpers/displayWhenPrint";
import { sistemaLabel } from "helpers/tableLabels";
import { capitalizeFirstLetter } from "helpers/text";

import { RowBody } from "../RowBody";
import { NCN } from "../types";
interface IProps {
	ncn: NCN;
	print?: boolean;
}

export const TableRow: React.FC<IProps> = (props) => {
	const { ncn, print } = props;

	return (
		<AccordionItem>
			{({ isExpanded }) => (
				<Box style={{ breakInside: "avoid" }}>
					<AccordionButton minHeight="2.75rem" p="0" pl="1rem">
						<Grid templateColumns="repeat(7, 1fr)" gap={5} w="100%" textAlign="left" alignItems="center">
							<GridItem colSpan={2}>
								<Text
									title={capitalizeFirstLetter(
										sistemaLabel(
											defaultValuesSistemas.find((it) => it.value.split(".")[0] === ncn.sistema.split(".")[0])?.label ??
												ncn.sistema,
										),
									)}
									textStyle="body3"
									color="gray.800"
									noOfLines={noOfLines(print)}
								>
									{capitalizeFirstLetter(
										sistemaLabel(
											defaultValuesSistemas.find((it) => it.value.split(".")[0] === ncn.sistema.split(".")[0])?.label ??
												ncn.sistema,
										),
									)}
								</Text>
							</GridItem>
							<GridItem colSpan={4}>
								<Flex>
									<InfoHover info={ncn.resumen} />
									<Text textStyle="body3" color="gray.800" pl=".5rem" noOfLines={noOfLines(print)}>
										{capitalizeFirstLetter(ncn.resumen_corto)}
									</Text>
								</Flex>
							</GridItem>
							<GridItem colSpan={1}>{ncn.indice && <IndiceIndicator indice={ncn.indice} />}</GridItem>
						</Grid>
						{!print && <AccordionIcon color="gray.500" />}
					</AccordionButton>
					<AccordionPanel borderTop="0.5px solid" borderColor="gray.100" padding="1rem 0" backgroundColor="gray.table">
						{isExpanded && <RowBody ncn={ncn} />}
					</AccordionPanel>
				</Box>
			)}
		</AccordionItem>
	);
};
