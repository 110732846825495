import { formErrorAnatomy as parts } from "@chakra-ui/anatomy";
import type { PartsStyleFunction, SystemStyleFunction } from "@chakra-ui/theme-tools";

const baseStyleText: SystemStyleFunction = () => {
	return {
		color: "red.500",
		fontFamily: "Arial",
		fontSize: "caption",
		fontWeight: "caption",
		lineHeight: "caption",
		letterSpacing: "caption",
	};
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
	text: baseStyleText(props),
});

export default {
	baseStyle,
};
