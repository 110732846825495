import React from "react";

import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";

import { defaultValues } from "../../../defaultValues";
import { FilterDropDown } from "../../components/FilterDropDown";

export const AccesibilidadHeader: React.FC = () => {
	return (
		<Box h=".5rem" background="transparent" p="0 1rem" mb=".5rem">
			<Flex width="100%" height="100%" alignItems="center">
				<Grid templateColumns="repeat(16, 1fr)" w="100%" pr="1.5rem" pl=".3rem">
					<GridItem colSpan={1}>
						<FilterDropDown column="por_validar" values={defaultValues.por_validar} />
					</GridItem>
					<GridItem colSpan={4}>
						<FilterDropDown column="nombre" />
					</GridItem>
					<GridItem colSpan={2}>
						<FilterDropDown column="nae_indice" values={defaultValues.nae_indice} />
					</GridItem>
					<GridItem colSpan={2}>
						<FilterDropDown column="nae_total_incumplimientos" />
					</GridItem>
				</Grid>
			</Flex>
		</Box>
	);
};
