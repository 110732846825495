import { useState } from "react";

import {
	Box,
	Button,
	Divider,
	Flex,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	SimpleGrid,
} from "@chakra-ui/react";

import { defaultValues } from "components/Portfolio/defaultValues";
import { PortfolioFilterSelection } from "components/Portfolio/types/filterData";
import { columnHeaderLabel } from "helpers/FiltersColumns";
import { actions } from "store/portfolio/reducer";
import { useAppDispatch, useAppSelector } from "store/store";

interface FilterModalProps {
	isOpen: boolean;
	onClose: () => void;
}

interface SelectInputProps {
	label: string;
	options: { label: string; value?: string }[];
	onChange: (value: string) => void;
	current?: string;
}

const SelectInput = (props: SelectInputProps) => {
	return (
		<Flex flexDirection="column" key={props.label}>
			<Box fontWeight={400} fontSize="0.75rem">
				{props.label}
			</Box>
			<Select onChange={(ev) => props.onChange(ev.target.value)}>
				{props.options.map((option) => (
					<option key={option.value} value={option.value || ""} selected={props.current === option.value}>
						{option.label}
					</option>
				))}
			</Select>
		</Flex>
	);
};

interface RegularInputProps {
	label: string;
	onChange: (value: string) => void;
	current?: string;
}

const RegularInput = (props: RegularInputProps) => {
	return (
		<Flex flexDirection="column">
			<Box fontWeight={400} fontSize="0.75rem">
				{props.label}
			</Box>
			<Input
				placeholder={`${props.label}...`}
				onChange={(ev) => {
					props.onChange(ev.target.value);
				}}
				value={props.current}
			/>
		</Flex>
	);
};

export function FilterModal(props: FilterModalProps) {
	const { isOpen, onClose } = props;
	const currentFilterSelection = useAppSelector((state) => state.portfolio.filterSelection);
	const dispatch = useAppDispatch();

	const [form, setForm] = useState<PortfolioFilterSelection>({});

	const isGenericChoice = (value: string) => value.includes("Todos") || value.includes("Todas");

	const applyFilters = () => {
		let ambito = form.ambito;
		if (form.ambito && isGenericChoice(form.ambito[0])) {
			ambito = form.ambito[0].includes("AV")
				? defaultValues.ambito.filter((it) => it.red === "AV").map((it) => it.value)
				: defaultValues.ambito.filter((it) => it.red === "RC").map((it) => it.value);
		}

		// Object.assign respect the undefined of the second argument:
		if (currentFilterSelection) {
			const formUpdated = { ...form, ambito };
			dispatch(actions.setFilterSelection(Object.assign({ ...currentFilterSelection }, formUpdated)));
		}
		onClose();
	};

	const resetFilters = () => {
		const estadoITE = currentFilterSelection?.estadoITE;
		dispatch(actions.resetFilterSelection());
		dispatch(actions.setFilterSelection({ estadoITE }));
		setForm({});
	};

	const getCurrent = (key: keyof PortfolioFilterSelection) => {
		const fromFilter = currentFilterSelection ? currentFilterSelection[key] : undefined;
		const fromDefault = form[key];
		return (fromFilter && fromFilter[0]) || (fromDefault && fromDefault[0]);
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="xl">
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Filtros avanzados</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Divider marginBottom="1rem" />
					<SimpleGrid columns={2} spacing="1.5rem">
						<SelectInput
							label={columnHeaderLabel("ambito")}
							options={[{ label: "Todos los ámbitos" }, ...defaultValues.ambito]}
							onChange={(value) => {
								setForm((state) => ({ ...state, ambito: value ? [value] : undefined }));
							}}
							current={getCurrent("ambito")}
						/>
						<SelectInput
							label={columnHeaderLabel("jefatura")}
							options={[{ label: "Todas las jefaturas" }, ...defaultValues.jefatura]}
							onChange={(value) => {
								setForm((state) => ({ ...state, ambito: [value] }));
							}}
							current={getCurrent("jefatura")}
						/>
						<SelectInput
							label={columnHeaderLabel("provincia")}
							options={[{ label: "Todas las provincias" }, ...defaultValues.provincia]}
							onChange={(value) => {
								setForm((state) => ({ ...state, provincia: [value] }));
							}}
							current={getCurrent("provincia")}
						/>
						<SelectInput
							label={columnHeaderLabel("tipologia")}
							options={[{ label: "Todas las tipologías" }, ...defaultValues.tipologia]}
							onChange={(value) => {
								setForm((state) => ({ ...state, tipologia: value === "" ? undefined : [value] }));
							}}
							current={getCurrent("tipologia")}
						/>
						<SelectInput
							label={columnHeaderLabel("especialidades")}
							options={[{ label: "Todas las especialidades" }, ...defaultValues.especialidades]}
							onChange={(value) => {
								setForm((state) => ({ ...state, especialidad: value === "" ? undefined : [value] }));
							}}
							current={getCurrent("especialidad")}
						/>
						<SelectInput
							label={columnHeaderLabel("usos")}
							options={[{ label: "Todos los usos" }, ...defaultValues.usos]}
							onChange={(value) => {
								setForm((state) => ({ ...state, uso: value === "" ? undefined : [value] }));
							}}
							current={getCurrent("uso")}
						/>
						<RegularInput
							label={columnHeaderLabel("via")}
							current={form.via}
							onChange={(value) => {
								setForm((state) => ({ ...state, via: value === "" ? undefined : value }));
							}}
						/>
						<SelectInput
							label={columnHeaderLabel("relevancia")}
							options={[{ label: "Todas las relevancias" }, ...defaultValues.relevancia]}
							onChange={(value) => {
								setForm((state) => ({ ...state, relevancia: value === "" ? undefined : [value] }));
							}}
							current={getCurrent("relevancia")}
						/>
						<RegularInput
							label={columnHeaderLabel("codigo_estacion")}
							current={form.estacion}
							onChange={(value) => {
								setForm((state) => ({ ...state, estacion: value === "" ? undefined : value }));
							}}
						/>
						<SelectInput
							label={columnHeaderLabel("empresa")}
							options={[{ label: "Todas las empresas" }, ...defaultValues.empresa]}
							onChange={(value) => {
								setForm((state) => ({ ...state, empresa: value === "" ? undefined : [value] }));
							}}
							current={getCurrent("empresa")}
						/>
					</SimpleGrid>
					<Divider marginTop="2rem" />
				</ModalBody>

				<ModalFooter height="2rem" padding="0rem" marginBottom="2rem" marginRight="1.5rem" marginTop="1rem">
					{currentFilterSelection !== null &&
						Object.entries(currentFilterSelection).reduce((acc, [, v]) => (v !== undefined ? acc + 1 : acc), 0) > 1 && (
							<Button marginRight="2rem" height="2rem" onClick={resetFilters}>
								Eliminar filtros
							</Button>
						)}
					<Button height="2rem" onClick={applyFilters}>
						Aplicar filtros
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
