import React from "react";

import { ArrowRightIcon, ArrowLeftIcon, ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import {
	Flex,
	IconButton,
	Text,
	Tooltip,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
} from "@chakra-ui/react";

import { Pagination, THUNK_STATE } from "config/types";
import { actions as portfolioActions } from "store/portfolio/reducer";
import { useAppDispatch } from "store/store";

import { NoElementsText } from "../NoElementsText";

interface IProps {
	pagination?: Pagination;
	thunkState: string;
}

export const PaginationComponent: React.FC<IProps> = (props) => {
	const { pagination, thunkState } = props;
	const dispatch = useAppDispatch();

	const paginationRef: Pagination = pagination || {
		total: 0,
		lastPage: 0,
		perPage: 0,
		currentPage: 0,
		from: 0,
		to: 0,
	};

	return (
		<React.Fragment>
			<Flex justifyContent="space-between" m={5} alignItems="center">
				{thunkState !== THUNK_STATE.PENDING && paginationRef.lastPage > 1 && (
					<>
						<Flex>
							<Tooltip borderRadius="50">
								<IconButton
									onClick={() => dispatch(portfolioActions.setPage(0))}
									isDisabled={paginationRef.currentPage === 1}
									icon={<ArrowLeftIcon h={6} w={6} boxSize="1.75rem" p="0.5rem" />}
									mr={4}
									aria-label={"First page"}
								/>
							</Tooltip>
							<Tooltip>
								<IconButton
									onClick={() => dispatch(portfolioActions.setPage(paginationRef.currentPage - 1))}
									isDisabled={paginationRef.currentPage === 1}
									icon={<ChevronLeftIcon h={6} w={6} boxSize="1.75rem" p="0.15rem" />}
									mr={4}
									aria-label={"Previous page"}
								/>
							</Tooltip>
						</Flex>
						<Flex alignItems="center">
							<Text mr={8}>
								<Text as="span" mr={2} fontSize={".8rem"}>
									Página
								</Text>
								<Text fontSize=".8rem" fontWeight="bold" as="span" mr={2}>
									{paginationRef.currentPage}
								</Text>
								<Text fontSize=".8rem" as="span" mr={2}>
									de
								</Text>
								<Text fontSize=".8rem" fontWeight="bold" as="span" mr={2}>
									{paginationRef.lastPage}
								</Text>
							</Text>
							<Text fontSize=".8rem">Ir a la página:</Text>
							<NumberInput
								size="sm"
								ml={2}
								mr={5}
								w={20}
								min={1}
								max={paginationRef.lastPage}
								onChange={(_, value: number) => {
									if (value > paginationRef.lastPage) {
										dispatch(portfolioActions.setPage(paginationRef.lastPage));
									} else if (value < 1) {
										dispatch(portfolioActions.setPage(1));
									} else {
										dispatch(portfolioActions.setPage(value));
									}
								}}
								value={paginationRef.currentPage}
							>
								<NumberInputField />
								<NumberInputStepper>
									<NumberIncrementStepper />
									<NumberDecrementStepper />
								</NumberInputStepper>
							</NumberInput>
						</Flex>
						<Flex>
							<Tooltip>
								<IconButton
									onClick={() => dispatch(portfolioActions.setPage(paginationRef.currentPage + 1))}
									isDisabled={paginationRef.lastPage === paginationRef.currentPage}
									icon={<ChevronRightIcon h={6} w={6} boxSize="1.75rem" p="0.15rem" />}
									aria-label={"Next page"}
								/>
							</Tooltip>
							<Tooltip>
								<IconButton
									onClick={() => dispatch(portfolioActions.setPage(paginationRef.lastPage))}
									isDisabled={paginationRef.lastPage === paginationRef.currentPage}
									icon={<ArrowRightIcon h={6} w={6} boxSize="1.75rem" p="0.5rem" />}
									ml={4}
									aria-label={"Last Page"}
								/>
							</Tooltip>
						</Flex>
					</>
				)}
			</Flex>
			{thunkState === THUNK_STATE.FULFILLED && paginationRef.total === 0 && (
				<React.Fragment>
					<NoElementsText />
				</React.Fragment>
			)}
		</React.Fragment>
	);
};
