import { ComponentStyleConfig } from "@chakra-ui/react";

const componentOverride: ComponentStyleConfig = {
	baseStyle: {
		color: "primary.main",
	},
	variants: {
		topbarLink: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			color: "black.200",
			borderLeft: "1px solid",
			borderColor: "black.900",
			height: "100%",
			padding: "0 1.375rem",
			fontSize: "small",
			_hover: {
				color: "primary.main",
				textDecoration: "none",
			},
			"&:last-child": {
				borderRight: "1px solid",
				borderColor: "black.900",
			},
		},
		reverse: {
			color: "black.100",
			"&:hover": {
				color: "primary.main",
			},
		},
	},
	defaultProps: {
		size: "md",
	},
};

export default componentOverride;
