import { inputAnatomy as parts } from "@chakra-ui/anatomy";
import { ComponentStyleConfig } from "@chakra-ui/react";
import { PartsStyleObject } from "@chakra-ui/theme-tools";

export const baseStyle: PartsStyleObject<typeof parts> = {
	field: {
		fontFamily: "Arial",
		fontSize: "body3",
		fontWeight: "body3",
		lineHeight: "body3",
		letterSpacing: "body3",
		color: "gray.600",
		border: "1px solid",
		borderColor: "gray.200",
		borderRadius: "0.375rem",
		padding: "0.5rem 1rem",
		"&:focus": {
			outline: "2px solid",
			outlineColor: "blue.500",
			outlineOffset: "0",
		},
	},
};

const componentOverride: ComponentStyleConfig = {
	parts: ["field"],
	baseStyle: baseStyle,
	defaultProps: {
		variant: "",
		size: "",
	},
};

export default componentOverride;
