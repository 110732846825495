import React from "react";

import { Grid, GridItem } from "@chakra-ui/react";

import HeaderText from "common/components/Header";

export const TableHeader: React.FC = () => {
	return (
		<React.Fragment>
			<Grid templateColumns="repeat(1, 1fr)" w="100%" mt="1rem" mb=".25rem" pl="1rem" textAlign="left">
				<GridItem>
					<HeaderText label="Sistemas Existentes" color="green.600" />
				</GridItem>
			</Grid>
		</React.Fragment>
	);
};
