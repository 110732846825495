import { Button, Divider, Flex, useDisclosure, Text, Box, Select } from "@chakra-ui/react";
import { BsFunnel } from "react-icons/bs";

import { FilterModal } from "components/Portfolio/Mapa/MapaSideBar/Components/FilterModal";
import { FreeTextInput } from "components/Portfolio/Mapa/MapaSideBar/Components/FreeTextInput";
import { Stats } from "components/Portfolio/Mapa/MapaSideBar/Components/Stats";
import { useAppDispatch } from "store/store";
import { actions } from "store/user/reducer";

export function MapSideBarBody() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const dispatch = useAppDispatch();

	return (
		<>
			<Box mt="1rem">
				<FreeTextInput />
			</Box>
			<Divider margin="1rem 0rem" />
			<Select defaultValue="default" my=".5rem" onChange={(e) => dispatch(actions.setStyle(e.target.value))}>
				<option value="default">Vista mapa</option>
				<option value="satellite">Vista satélite</option>
			</Select>
			<Divider margin="1rem 0rem" />
			<Flex flexDirection="row" justifyContent="space-between" marginTop="1rem" gap="1rem">
				<Text textStyle="body3" color="gray.600" alignSelf="center">
					Filtros Avanzados
				</Text>
				<Button variant="solid" height="1.75rem" onClick={onOpen}>
					<BsFunnel />
				</Button>
			</Flex>
			<FilterModal isOpen={isOpen} onClose={onClose} />
			<Divider margin="1rem 0rem" />
			<Stats />
		</>
	);
}
