import React, { useEffect } from "react";

import { Box, Divider } from "@chakra-ui/react";

import { MapSideBarBody } from "components/Portfolio/Mapa/MapaSideBar/Components/MapSideBarBody";
import { ActivoCompletoOutput } from "components/Portfolio/types/activos";
import { ApiPaginatedResponse } from "config/types";
import { actions } from "store/portfolio/reducer";
import { getInfoActivosThunk, getPortfolioStatsThunk } from "store/portfolio/thunks";
import { useAppDispatch, useAppSelector } from "store/store";

import { SideBarButtons } from "./Buttons";
import { SideBarHeader } from "./Header";

export const MapaSideBar: React.FC = () => {
	const dispatch = useAppDispatch();
	const filterSelection = useAppSelector((state) => state.portfolio.filterSelection);

	useEffect(() => {
		dispatch(getPortfolioStatsThunk({ filterSelection: filterSelection || undefined }));

		const requestAllAssets = async () => {
			let [currentPage, lastPage] = [0, 1];
			await dispatch(actions.clearAllAssetsInfo());
			const allAssets: ActivoCompletoOutput[] = [];
			while (currentPage < lastPage) {
				const res = await dispatch(
					await getInfoActivosThunk({ page: currentPage + 1, filterSelection: filterSelection || undefined }),
				);
				const payload = res.payload as ApiPaginatedResponse<ActivoCompletoOutput[]>;
				currentPage = payload.pagination.currentPage;
				lastPage = payload.pagination.lastPage;
				allAssets.push(...payload.data);
			}
			await dispatch(actions.setAllAssetsInfo(allAssets));
		};
		requestAllAssets();
	}, [filterSelection]);

	return (
		<Box p="1.5rem" rounded="md" width="100%" bgColor="white">
			<SideBarHeader />
			<Divider mt="1rem" />
			<SideBarButtons />
			<Divider mt="1rem" />
			<MapSideBarBody />
		</Box>
	);
};
