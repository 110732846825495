import React from "react";

import { Box, Button, Divider, Flex, Grid, GridItem, Icon, Link, Text } from "@chakra-ui/react";
import { HiArrowUpRight } from "react-icons/hi2";

import StatusIndicator from "common/components/StatusIndicator";
import { capitalizeFirstLetter } from "helpers/text";

interface IProps {
	type: "activoDigitalizado" | "fichasInventario";
	accLink?: string;
	formLink?: string;
	formName: string;
	formStatus?: string;
	print?: boolean;
}

const statusColorSelector = (estado?: string) => {
	switch (estado) {
		case "pendiente":
			return "yellow.100";
		case "entregado":
			return "green.100";
		default:
			return "transparent";
	}
};

const fontColorSelector = (estado?: string) => {
	switch (estado) {
		case "pendiente":
			return "yellow.700";
		case "entregado":
			return "green.600";
		default:
			return "transparent";
	}
};
export const RowLevantamientoDigital: React.FC<IProps> = (props) => {
	const { type, formName, formStatus, accLink, formLink, print } = props;

	return (
		<React.Fragment>
			<Grid templateColumns="repeat(8, 1fr) 1.5rem" gap={1} w="100%" m=".25rem 0" alignItems="center">
				<GridItem colSpan={4} m=".25rem 0">
					<Text fontSize="body2" pl="1rem">
						{formName}
					</Text>
				</GridItem>
				<GridItem colSpan={4}>
					{type === "activoDigitalizado" ? (
						<Flex>
							<Box
								{...{
									borderRadius: "5px",
									justifyContent: "center",
									alignContent: "center",
									height: "100%",
									bgColor: (formStatus && statusColorSelector(formStatus)) || "transparent",
								}}
							>
								<Text textStyle="body3" m=".35rem .75rem" color={fontColorSelector(formStatus)}>
									{formStatus ? capitalizeFirstLetter(formStatus) : ""}
								</Text>
							</Box>
						</Flex>
					) : (
						<StatusIndicator estado={formStatus} />
					)}
				</GridItem>
				<GridItem colSpan={1} justifySelf="flex-end" pr=".25rem">
					{!print && formLink && (
						<Flex gap="1rem">
							<Link href={formLink} isExternal style={{ textDecoration: "none" }}>
								<Button type="submit" bg="gray.400" size="md" borderRadius="5px" h="2rem" ml="3rem">
									<Text textStyle="body3" color="white">
										Ver Formulario
									</Text>
								</Button>
							</Link>
							<Link href={formLink} isExternal style={{ textDecoration: "none" }}>
								<Flex
									alignItems="center"
									justifyContent="center"
									w="2rem"
									h="2rem"
									_hover={{ bg: "gray.100", borderRadius: "5px", cursor: "pointer" }}
								>
									<Icon as={HiArrowUpRight} fontSize="1.25rem" color="gray.500" />
								</Flex>
							</Link>
						</Flex>
					)}
					{!print && accLink && (
						<Link isExternal style={{ textDecoration: "none" }} href={accLink}>
							<Flex
								alignItems="center"
								justifyContent="center"
								w="2rem"
								h="2rem"
								_hover={{ bg: "gray.100", borderRadius: "5px", cursor: "pointer" }}
							>
								<Icon as={HiArrowUpRight} fontSize="1.25rem" color="gray.500" />
							</Flex>
						</Link>
					)}
				</GridItem>
			</Grid>
			<Divider w="97%" ml="1rem" />
		</React.Fragment>
	);
};
