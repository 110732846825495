import React from "react";

import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";

import { defaultValues } from "../../../defaultValues";
import { FilterDropDown } from "../../components/FilterDropDown";

export const ConservacionHeader: React.FC = () => {
	return (
		<Box h=".5rem" background="transparent" p="0 1rem" mb=".5rem">
			<Flex width="100%" height="100%" alignItems="center">
				<Grid templateColumns="repeat(18, 1fr)" w="100%" pr="1.5rem" pl=".3rem">
					<GridItem colSpan={1} alignSelf="center">
						<FilterDropDown column="por_validar" values={defaultValues.por_validar} />
					</GridItem>
					<GridItem colSpan={4} alignSelf="center">
						<FilterDropDown column="nombre" />
					</GridItem>
					<GridItem colSpan={2} alignSelf="center">
						<FilterDropDown column="nec_indice" values={defaultValues.nec_indice} />
					</GridItem>
					<GridItem colSpan={2} alignSelf="center">
						<FilterDropDown column="prioridad_reparacion" values={defaultValues.prioridad_reparacion} />
					</GridItem>
					<GridItem colSpan={2} alignSelf="center">
						<FilterDropDown column="nec_total_deficiencias_4" />
					</GridItem>
					<GridItem colSpan={2} alignSelf="center">
						<FilterDropDown column="nec_total_deficiencias_3" />
					</GridItem>
					<GridItem colSpan={2} alignSelf="center">
						<FilterDropDown column="nec_total_deficiencias_2" />
					</GridItem>
					<GridItem colSpan={2} alignSelf="center">
						<FilterDropDown column="nec_total_deficiencias_1" />
					</GridItem>
				</Grid>
			</Flex>
		</Box>
	);
};
