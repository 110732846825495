import { AxiosRequestConfig } from "axios";

import { ActivoCompletoOutput } from "components/Portfolio/types/activos";
import { ApiPaginatedResponse, IAttachedFile, Project } from "config/types";

import { apiClient, CustomAxiosRequest } from "./index";

export const getProjectsForCurrentUser: CustomAxiosRequest<null, ApiPaginatedResponse<Project[]>> = () => {
	const url = `conf/projects`;
	const config: AxiosRequestConfig<ApiPaginatedResponse<Project[]>> = {
		method: "GET",
		url,
		headers: {
			"Content-Type": "application/json",
		},
	};
	return apiClient(config);
};

export const getAssets: CustomAxiosRequest<string, ApiPaginatedResponse<ActivoCompletoOutput[]>> = (
	projectRef: string,
) => {
	const url = `conf/assets?projectRef=${projectRef}`;
	const config: AxiosRequestConfig<ApiPaginatedResponse<ActivoCompletoOutput[]>> = {
		method: "GET",
		url,
		headers: {
			"Content-Type": "application/json",
		},
	};
	return apiClient(config);
};

const getUploadSignedUrls = async (files: IAttachedFile[]) => {
	return await apiClient
		.post<{ [key: string]: string }>(
			`conf/putSignedUrls`,
			files.map((it) => ({
				key: it.id,
				fileName: encodeURI(it.file !== undefined ? it.file.name : ""),
				contentType: encodeURI(it.file !== undefined ? it.file.type : ""),
			})),
		)
		.then((res) => res.data);
};

export const getSignedUrl = (key: string) => apiClient.get(`conf/getSignedUrl`, { params: { key } });

export const uploadFiles = async (
	files: IAttachedFile[],
	setProgress?: React.Dispatch<React.SetStateAction<number>>,
) => {
	// Update progress by 10 in total
	if (files.length === 0 && setProgress) setProgress((p) => p + 10);
	const signedUrls = await getUploadSignedUrls(files);
	return Promise.all(
		files.map((it) => {
			if (it.id !== undefined && it.file !== undefined) {
				return apiClient
					.put(`${signedUrls[it.id]}`, it.file, {
						headers: {
							"Content-Type": it.file.type,
						},
					})
					.then((res) => {
						setProgress && setProgress((p) => p + 10 / files.length);
						return res;
					});
			}
		}),
	);
};
