import React from "react";

import { Flex, Icon, IconButton } from "@chakra-ui/react";
import { FiArrowLeft, FiX } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";

type TabCloserProps = {
	ite?: boolean;
};
const TabCloser: React.FC<TabCloserProps> = (props) => {
	const { ite } = props;
	const { assetId, sectionId, iteId, tableId } = useParams();

	const tabCloserRoute = (assetId?: string, sectionId?: string, tableId?: string) => {
		if (sectionId && iteId && tableId) {
			return `/activo/${assetId}/${sectionId}/${iteId}`;
		}
		if (sectionId && !tableId) {
			return `/activo/${assetId}`;
		}
		return "/";
	};

	const navigate = useNavigate();
	return (
		<Flex justifyContent="flex-start">
			<Flex
				alignItems="center"
				justifyContent="center"
				w="2rem"
				h="2rem"
				_hover={{ bg: "gray.100", borderRadius: "5px" }}
			>
				<IconButton
					aria-label="back"
					variant="icon-only"
					height="1rem"
					width="1rem"
					onClick={() => navigate(tabCloserRoute(assetId, sectionId, tableId))}
				>
					<Icon as={tableId && ite ? FiArrowLeft : FiX} fontSize="1.5rem" color="gray.500" strokeWidth={"1"} />
				</IconButton>
			</Flex>
		</Flex>
	);
};

export default TabCloser;
