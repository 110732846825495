import React from "react";

import { Box, Divider, Flex, Grid, GridItem, Icon, Text } from "@chakra-ui/react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";

import IndiceIndicator from "common/components/IndiceIndicator";

interface IProps {
	tableLabel: string;
	tableRef: string;
	completado?: boolean;
	items?: number;
	indice?: string;
	print?: boolean;
}

export const TableSelector: React.FC<IProps> = (props) => {
	const { tableLabel, tableRef, completado, items, indice, print } = props;
	const { assetId, sectionId, iteId, tableId } = useParams();
	const navigate = useNavigate();

	return (
		<Box>
			<Flex
				width="100%"
				height="100%"
				alignItems="center"
				onClick={
					tableId === tableRef
						? () => navigate(`/activo/${assetId}/${sectionId}/`)
						: () => navigate(`/activo/${assetId}/${sectionId}/${iteId}/${tableRef}`)
				}
				_hover={{ cursor: "pointer", bgColor: "gray.100" }}
				bgColor={!print ? (tableId === tableRef ? "gray.100" : "transparent") : "transparent"}
			>
				<Flex width="100%" height="100%" alignItems="center">
					<Grid templateColumns="repeat(8, 1fr)" gap={1} w="100%" m=".25rem 0" alignItems="center">
						<GridItem colSpan={4} m=".25rem 0">
							<Text fontSize="body2" pl="1rem">
								{tableLabel}
							</Text>
						</GridItem>
						<GridItem colSpan={1}>
							{completado && (
								<Icon
									as={IoIosCheckmarkCircleOutline}
									color="gray.500"
									width="1.5rem"
									height="1.5rem"
									mt=".5rem"
									_hover={{ cursor: "pointer" }}
								/>
							)}
						</GridItem>
						<GridItem colSpan={1}>
							<Text fontSize="body3" pl="1rem">
								{items}
							</Text>
						</GridItem>
						<GridItem colSpan={2}>{indice && <IndiceIndicator indice={indice} />}</GridItem>
					</Grid>
				</Flex>
				{!print && (
					<Icon
						as={tableId === tableRef ? BsChevronLeft : BsChevronRight}
						color="gray.500"
						width="1.25rem"
						height="1.25rem"
						mr="1rem"
						mt="0.5rem"
						mb=".5rem"
						_hover={{ cursor: "pointer" }}
					/>
				)}
			</Flex>
			<Divider />
		</Box>
	);
};
