import React from "react";

import { Flex, Text, TextProps } from "@chakra-ui/react";

import { capitalizeFirstLetter } from "helpers/text";
interface IProps {
	label?: string;
	value?: string | string[] | number | null;
	date?: boolean;
	styleProps?: TextProps;
	currency?: boolean;
	suffix?: string;
	percentage?: boolean;
	highlight?: string;
}

export const InfoLine: React.FC<IProps> = (props) => {
	const { label, value, date, styleProps, currency, suffix, percentage, highlight } = props;

	const valueFormater = (value: string | string[] | number | null | undefined) => {
		if (value === null || value === undefined || value === "") {
			return null;
		} else if (Array.isArray(value)) {
			return capitalizeFirstLetter(value.join(", "));
		} else if (value && date) {
			return capitalizeFirstLetter(
				Intl.DateTimeFormat("es-ES", { day: "numeric", month: "long", year: "numeric" }).format(new Date(value)),
			);
		} else if (value && currency) {
			return `${value.toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`;
		} else if (value && percentage) {
			return `${value}%`;
		} else if (typeof value === "number") {
			return value;
		} else {
			return capitalizeFirstLetter(value);
		}
	};

	const colorFormater = (value: string | string[] | number | null | undefined) => {
		if (value === null || value === undefined || value === "") {
			return "gray.400";
		} else {
			return "gray.800";
		}
	};

	return (
		<Flex mt=".25rem" gap=".25rem" wrap="wrap">
			<Text textStyle="body3" color="gray.500" display="inline" {...styleProps}>
				{label}
			</Text>
			<Text textStyle="body3" color={colorFormater(value)} display="inline" bg={highlight}>
				{valueFormater(value)} {suffix}
			</Text>
		</Flex>
	);
};
