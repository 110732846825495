import React from "react";

import { Flex, Spinner } from "@chakra-ui/react";

interface IProps {
	color?: string;
}
const FullsizeSpinner: React.FC<IProps> = (props) => {
	return (
		<Flex h="100%" w="100%" alignItems="center" justifyContent="center">
			<Spinner color={props.color} />
		</Flex>
	);
};

export default FullsizeSpinner;
