import React, { useMemo } from "react";

import {
	Box,
	Button,
	Checkbox,
	Divider,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	GridItem,
	Image,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { DateTime } from "luxon";
import { useNavigate, useParams } from "react-router-dom";

import locationIcon from "assets/images/Location.svg";
import { EditLocationModal } from "common/components/AssetModal/EditLocationModal";
import { defaultValues } from "components/Portfolio/defaultValues";
import { ActivoCompletoOutput, ActivoCompletoInput } from "components/Portfolio/types/activos";
import { getReportsThunk } from "store/ITE/thunks";
import { putEditarActivoThunk, postNuevoActivoValidadoThunk, putValidarActivoThunk } from "store/portfolio/thunks";
import { useAppDispatch } from "store/store";

import { FormikSelect } from "./FormikSelect";
import { IsRequiredSymbol } from "./IsRequiredSymbol";
import { getRelevanciaByUsos } from "./relevanciaPorUso";

interface IProps {
	asset?: ActivoCompletoOutput;
	isOpen: boolean;
	onClose: () => void;
}
type PropsType = IProps;
type Variant = "new" | "approval" | "edit";

type FormInput = ActivoCompletoInput & { fecha_visita_programada: string };

const AssetModal: React.FC<PropsType> = (props) => {
	const { asset, isOpen, onClose } = props;
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { isOpen: isOpenMapModal, onClose: onCloseMapModal, onOpen: onOpenMapModal } = useDisclosure();
	const { iteId } = useParams();

	const variant: Variant = (() => {
		if (!asset) return "new";
		if (asset.por_validar === undefined || asset.por_validar === true) return "approval";
		return "edit";
	})();

	const variantDetails = useMemo(() => {
		switch (variant) {
			case "new":
				return {
					title: "Registro de nuevo activo",
					aceptar: {
						bgColor: "green.500",
						text: "Guardar",
					},
					cancelar: { color: "gray.500", text: "Cancelar" },
				};
			case "approval":
				return {
					title: "Activo pendiente de aceptación",
					aceptar: {
						bgColor: "green.500",
						text: "Aceptar activo",
					},
				};
			default:
				return {
					title: "Editar activo",
					aceptar: {
						bgColor: "green.500",
						text: "Guardar",
					},
					cancelar: { color: "gray.500", text: "Cancelar" },
				};
		}
	}, [variant]);

	const selectStyles = {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		control: (provided: any) => ({
			...provided,
			borderColor: "gray.200",
			borderRadius: "0.375rem",
			fontSize: ".80rem",
		}),
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		option: (provided: any, state: { isFocused: any; isSelected: any }) => ({
			...provided,
			backgroundColor: state.isFocused || state.isSelected ? "#ccc" : "white",
			fontSize: ".80rem",
		}),
	};

	const getEtiquetaRelevancia = (usos: string[], tipologia: string) => {
		const relevancia = getRelevanciaByUsos(usos, tipologia);
		const style = {
			display: "flex",
			alignItems: "center",
			height: "40px",
			borderRadius: "5px",
			padding: ".75rem",
			fontSize: ".80rem",
			marginTop: ".5rem",
		};
		switch (relevancia) {
			case "alta":
				return (
					<Box bg="red.100" color="red" sx={style}>
						Alta
					</Box>
				);
			case "media":
				return (
					<Box bg="orange.100" color="orange.600" sx={style}>
						Media
					</Box>
				);
			case "baja":
				return (
					<Box bg="green.100" color="green" sx={style}>
						Baja
					</Box>
				);
			default:
				return <Box bg="gray.100" sx={style} w="100%"></Box>;
		}
	};

	const fechaVisitaNuevoActivo = DateTime.now().toISODate();
	const initialValues = {
		nombre: asset?.nombre || "",
		direccion: asset?.direccion || "",
		municipio: asset?.municipio || "",
		provincia: asset?.provincia || "",
		geometria: asset?.geometria,
		ambito: asset?.ambito || "",
		jefatura: asset?.jefatura || "",
		agrupacion: asset?.agrupacion || "",
		tipologia: asset?.tipologia || "",
		via: asset?.via || "",
		pk: asset?.pk || "",
		codigo_estacion: asset?.codigo_estacion || "",
		especialidades: asset?.especialidades || [],
		uso_principal: asset?.uso_principal || "",
		usos: asset?.usos || [],
		empresa: asset?.empresa || "",
		ano_construccion: asset?.ano_construccion,
		superficie: asset?.superficie,
		personal: asset?.personal,
		numero_de_plantas_bajo_rasante: asset?.numero_de_plantas_bajo_rasante,
		numero_de_plantas_sobre_rasante: asset?.numero_de_plantas_sobre_rasante,
		sync_inventario: asset?.sync_inventario ?? true,
		fecha_visita_programada: fechaVisitaNuevoActivo,
	} as FormInput;

	const onSubmit = async (formData: FormInput) => {
		const { fecha_visita_programada, ...data } = formData;
		for (const [key, value] of Object.entries(data)) {
			if (value === null) data[key] = undefined;
		}
		switch (variant) {
			case "new":
				await dispatch(postNuevoActivoValidadoThunk({ data: { ...data, fechaVisitaIte: fecha_visita_programada } }));
				navigate("/portfolio");
				return onClose();
			case "approval":
				if (!asset?.id) return; // Shouldn't happen if variant=approval but just for type safety
				await dispatch(putValidarActivoThunk({ assetId: asset.id, data }));
				iteId && dispatch(getReportsThunk({ iteId }));
				return onClose();
			case "edit":
				if (!asset?.id) return; // Shouldn't happen if variant=edit but just for type safety
				await dispatch(putEditarActivoThunk({ assetId: asset.id, data }));
				iteId && dispatch(getReportsThunk({ iteId }));
				return onClose();
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="6xl" closeOnOverlayClick={false}>
			<ModalOverlay />
			<ModalContent p="1rem">
				<ModalHeader>{variantDetails.title}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Formik initialValues={initialValues} onSubmit={onSubmit} novalidate>
						{(form) => (
							<Form>
								<Grid gridTemplateColumns="2fr 2fr 2fr 2fr" gap="2rem">
									<GridItem>
										<Field
											name="nombre"
											validate={async (value: string) => (value && value !== "" ? undefined : "Campo requerido")}
										>
											{({ field }) => (
												<FormControl isInvalid={!!form.errors.nombre && form.touched.nombre}>
													<FormLabel display="flex" gap="2px">
														<Text textStyle="body3">Nombre del activo</Text>
														<IsRequiredSymbol />
													</FormLabel>
													<Input {...field} type="text" h="38px" fontSize="body3" autoComplete="nombreActivo" />
													<FormErrorMessage>{form.errors.nombre}</FormErrorMessage>
												</FormControl>
											)}
										</Field>
									</GridItem>
									<GridItem>
										<Field
											name="ambito"
											validate={(value: string) => (value && value !== "" ? undefined : "Selecciona una opción")}
										>
											{({ field, meta }) => (
												<FormControl isInvalid={!!form.errors.ambito && form.touched.ambito}>
													<FormLabel display="flex" gap="2px">
														<Text textStyle="body3">Subdirección / Gerencia</Text>
														<IsRequiredSymbol />
													</FormLabel>
													<FormikSelect
														options={defaultValues.ambito.filter((it) => !it.value.toLowerCase().includes("tod"))}
														field={field}
														form={form}
														meta={meta}
														styles={selectStyles}
													/>
													<FormErrorMessage>{form.errors.ambito}</FormErrorMessage>
												</FormControl>
											)}
										</Field>
									</GridItem>
									<GridItem>
										<Field
											name="jefatura"
											validate={(value: string) => (value && value !== "" ? undefined : "Campo requerido")}
										>
											{({ field, meta, form }) => {
												const validOptions = defaultValues.jefatura.filter(
													(it) => !form.values.ambito || it.parent === form.values.ambito,
												);
												return (
													<FormControl isInvalid={!!form.errors.jefatura && form.touched.jefatura}>
														<FormLabel display="flex" gap="2px">
															<Text textStyle="body3">Jefatura</Text>
															<IsRequiredSymbol />
														</FormLabel>
														<FormikSelect
															options={validOptions}
															field={field}
															form={form}
															meta={meta}
															styles={selectStyles}
														/>
														<FormErrorMessage>{form.errors.jefatura}</FormErrorMessage>
													</FormControl>
												);
											}}
										</Field>
									</GridItem>
									<GridItem>
										<Field name="agrupacion">
											{({ field }) => (
												<FormControl>
													<FormLabel display="flex" gap="2px">
														<Text textStyle="body3">Agrupación de Edificios</Text>
													</FormLabel>
													<Input {...field} type="text" h="38px" fontSize="body3" autofill="agrupacionActivo" />
												</FormControl>
											)}
										</Field>
									</GridItem>
									<GridItem>
										<FormLabel display="flex" gap="2px">
											<Text textStyle="body3">Coordenadas</Text>
											{/* <IsRequiredSymbol /> */}
										</FormLabel>
										<Flex>
											<Button w="100%" h="38px" onClick={onOpenMapModal}>
												<Image src={locationIcon} alt="Location icon" height="2rem" cursor="pointer" />
											</Button>
										</Flex>
									</GridItem>
									<GridItem>
										<Field name="direccion">
											{({ field }) => (
												<FormControl>
													<FormLabel display="flex" gap="2px">
														<Text textStyle="body3">Dirección</Text>
													</FormLabel>
													<Input {...field} type="text" h="38px" fontSize="body3" autoComplete="direccionActivo" />

													<FormErrorMessage>{form.errors.direccion}</FormErrorMessage>
												</FormControl>
											)}
										</Field>
									</GridItem>
									<GridItem>
										<Field
											name="municipio"
											validate={(value: string) => (value && value !== "" ? undefined : "Campo requerido")}
										>
											{({ field }) => (
												<FormControl isInvalid={!!form.errors.municipio && form.touched.municipio}>
													<FormLabel display="flex" gap="2px">
														<Text textStyle="body3">Municipio</Text>
														<IsRequiredSymbol />
													</FormLabel>
													<Input {...field} type="text" h="38px" fontSize="body3" autoComplete="municipioActivo" />
													<FormErrorMessage>{form.errors.municipio}</FormErrorMessage>
												</FormControl>
											)}
										</Field>
									</GridItem>
									<GridItem>
										<Field
											name="provincia"
											validate={(value: string) => (value && value !== "" ? undefined : "Selecciona una opción")}
										>
											{({ field, meta }) => (
												<FormControl isInvalid={!!form.errors.provincia && form.touched.provincia}>
													<FormLabel display="flex" gap="2px">
														<Text textStyle="body3">Provincia</Text>
														<IsRequiredSymbol />
													</FormLabel>
													<FormikSelect
														options={defaultValues.provincia.filter((it) => !it.value.toLowerCase().includes("tod"))}
														field={field}
														form={form}
														meta={meta}
														styles={selectStyles}
													/>
													<FormErrorMessage>{form.errors.provincia}</FormErrorMessage>
												</FormControl>
											)}
										</Field>
									</GridItem>
									<GridItem>
										<Field
											name="especialidades"
											validate={(value: string | unknown[]) =>
												value && value.length ? undefined : "Selecciona al menos una opción"
											}
										>
											{({ field, meta }) => (
												<FormControl
													isInvalid={form.errors.especialidades !== undefined && form.touched.especialidades}
												>
													<FormLabel display="flex" gap="2px">
														<Text textStyle="body3">Especialidades</Text>
														<IsRequiredSymbol />
													</FormLabel>
													<FormikSelect
														isMulti
														options={defaultValues.especialidades}
														field={field}
														form={form}
														meta={meta}
														styles={selectStyles}
													/>
													<FormErrorMessage>{form.errors.especialidades}</FormErrorMessage>
												</FormControl>
											)}
										</Field>
									</GridItem>
									<GridItem>
										<Field name="via">
											{({ field }) => (
												<FormControl>
													<FormLabel display="flex" gap="2px">
														<Text textStyle="body3">Línea asignada</Text>
													</FormLabel>
													<Input {...field} type="text" h="38px" fontSize="body3" autoComplete="viaActivo" />
													<FormErrorMessage>{form.errors.via}</FormErrorMessage>
												</FormControl>
											)}
										</Field>
									</GridItem>
									<GridItem>
										<Field name="pk">
											{({ field }) => (
												<FormControl>
													<FormLabel display="flex" gap="2px">
														<Text textStyle="body3">PK de Inicio</Text>
													</FormLabel>
													<Input {...field} type="text" h="38px" fontSize="body3" autoComplete="pkActivo" />
													<FormErrorMessage>{form.errors.pk}</FormErrorMessage>
												</FormControl>
											)}
										</Field>
									</GridItem>
									<GridItem>
										<Field name="codigo_estacion">
											{({ field }) => (
												<FormControl>
													<FormLabel display="flex" gap="2px">
														<Text textStyle="body3">Código Estación</Text>
													</FormLabel>
													<Input
														{...field}
														h="38px"
														fontSize="body3"
														placeholder="Completar si se desconoce Via / PK"
														autoComplete="codigoEstacionActivo"
													/>
													<FormErrorMessage>{form.errors.codigo_estacion}</FormErrorMessage>
												</FormControl>
											)}
										</Field>
									</GridItem>
									<GridItem>
										<Field
											name="tipologia"
											validate={(value: string) => (value && value !== "" ? undefined : "Selecciona una opción")}
										>
											{({ field, meta }) => (
												<FormControl isInvalid={!!form.errors.tipologia && form.touched.tipologia}>
													<FormLabel display="flex" gap="2px">
														<Text textStyle="body3">Principal Tipología Edificatoria</Text>
														<IsRequiredSymbol />
													</FormLabel>
													<FormikSelect
														options={defaultValues.tipologia}
														field={field}
														form={form}
														meta={meta}
														styles={selectStyles}
													/>
													<FormErrorMessage>{form.errors.tipologia}</FormErrorMessage>
												</FormControl>
											)}
										</Field>
									</GridItem>
									<GridItem>
										<Field
											name="uso_principal"
											validate={(value: string) => (value && value !== "" ? undefined : "Selecciona una opción")}
										>
											{({ field, meta }) => (
												<FormControl isInvalid={!!form.errors.uso_principal && form.touched.uso_principal}>
													<FormLabel display="flex" gap="2px">
														<Text textStyle="body3">Uso principal del edificio</Text>
														<IsRequiredSymbol />
													</FormLabel>
													<FormikSelect
														options={defaultValues.usos}
														field={field}
														form={form}
														meta={meta}
														styles={selectStyles}
													/>
													<FormErrorMessage>{form.errors.uso_principal}</FormErrorMessage>
												</FormControl>
											)}
										</Field>
									</GridItem>
									<GridItem>
										<Field name="usos">
											{({ field, form, meta }) => (
												<FormControl>
													<FormLabel display="flex" gap="2px">
														<Text textStyle="body3">Usos complementarios del edificio</Text>
													</FormLabel>
													<FormikSelect
														options={defaultValues.usos}
														isMulti={true}
														field={field}
														form={form}
														meta={meta}
														styles={selectStyles}
													/>
												</FormControl>
											)}
										</Field>
									</GridItem>
									<GridItem>
										<Text textStyle="body3">Relevancia</Text>
										{getEtiquetaRelevancia(
											form.values["uso_principal"]
												? [form.values["uso_principal"], ...(form.values["usos"] ?? [])]
												: form.values["usos"] || [],
											form.values["tipologia"] ?? "Otro",
										)}
									</GridItem>
									<GridItem>
										<Field name="personal">
											{({ field, form, meta }) => (
												<FormControl>
													<FormLabel display="flex" gap="2px">
														<Text textStyle="body3">Cuenta con personal permanente</Text>
													</FormLabel>
													<FormikSelect
														options={[
															{ label: "Si", value: "Si" },
															{ label: "No", value: "No" },
														]}
														field={field}
														form={form}
														meta={meta}
														styles={selectStyles}
													/>
												</FormControl>
											)}
										</Field>
									</GridItem>
									<GridItem>
										<Field name="ano_construccion">
											{({ field }) => (
												<FormControl>
													<FormLabel display="flex" gap="2px">
														<Text textStyle="body3">Año de Construcción</Text>
													</FormLabel>
													<Input
														{...field}
														h="38px"
														fontSize="body3"
														autoComplete="anoConstruccionActivo"
														type="number"
													/>
												</FormControl>
											)}
										</Field>
									</GridItem>
									<GridItem>
										<Field name="superficie">
											{({ field }) => (
												<FormControl>
													<FormLabel display="flex" gap="2px">
														<Text textStyle="body3">Superficie (m²)</Text>
													</FormLabel>
													<Input
														{...field}
														h="38px"
														fontSize="body3"
														autoComplete="superficieActivo"
														type="number"
														// min="0"
													/>
												</FormControl>
											)}
										</Field>
									</GridItem>
									<GridItem>
										<Field name="empresa">
											{({ field }) => (
												<FormControl>
													<FormLabel display="flex" gap="2px">
														<Text textStyle="body3">Empresa Inspectora</Text>
													</FormLabel>
													<Input {...field} h="38px" fontSize="body3" autoComplete="empresaIte" />
												</FormControl>
											)}
										</Field>
									</GridItem>
									<GridItem>
										<Field name="numero_de_plantas_bajo_rasante">
											{({ field }) => (
												<FormControl>
													<FormLabel display="flex" gap="2px">
														<Text textStyle="body3">Nº Plantas Bajo Rasante</Text>
													</FormLabel>
													<Input
														{...field}
														h="38px"
														fontSize="body3"
														autoComplete="nPlantasBajoRasanteActivo"
														type="number"
														min="0"
													/>
												</FormControl>
											)}
										</Field>
									</GridItem>
									<GridItem>
										<Field name="numero_de_plantas_sobre_rasante">
											{({ field }) => (
												<FormControl>
													<FormLabel display="flex" gap="2px">
														<Text textStyle="body3">Nº Plantas Sobre Rasante</Text>
													</FormLabel>
													<Input
														{...field}
														h="38px"
														fontSize="body3"
														autoComplete="nPlantasSobreRasanteActiv"
														type="number"
														min="0"
													/>
												</FormControl>
											)}
										</Field>
									</GridItem>
									<GridItem>
										{variant === "new" && (
											<Field name="fecha_visita_programada">
												{({ field }) => {
													return (
														<FormControl>
															<FormLabel display="flex" gap="2px">
																<Text textStyle="body3">Fecha Visita Programada</Text>
															</FormLabel>
															<Input
																{...field}
																h="38px"
																fontSize="body3"
																type="date"
																autoComplete="fechaVisitaProgramada"
																min={fechaVisitaNuevoActivo}
															/>
														</FormControl>
													);
												}}
											</Field>
										)}
									</GridItem>
								</Grid>
								<Flex my="1rem">
									<Field name="sync_inventario">
										{({ field }) => (
											<FormControl>
												<Checkbox
													{...field}
													defaultChecked={form.initialValues.sync_inventario}
													colorScheme="gray"
													type="boolean"
													h="38px"
													fontSize="body3"
												>
													Sincronizar este activo con el Inventario de Adif
												</Checkbox>
											</FormControl>
										)}
									</Field>
								</Flex>
								<Divider my="1rem" />
								<Flex w="100%" gap="1rem" justifyContent="end">
									<Flex mr="auto" gap="2px">
										<Text color="red.500" textStyle="body3">
											*
										</Text>
										<Text color="gray.500" textStyle="body3">
											Datos de inventario (obligatorio)
										</Text>
									</Flex>
									<Button
										type="submit"
										isLoading={form.isSubmitting}
										color="white"
										bg={variantDetails.aceptar.bgColor}
										_hover={{ background: variantDetails.aceptar.bgColor, filter: "brightness(1.15)" }}
										_focus={{ background: variantDetails.aceptar.bgColor, filter: "brightness(1.15)" }}
										_active={{ background: variantDetails.aceptar.bgColor, filter: "brightness(.8)" }}
										py=".75rem"
										size="lg"
										borderRadius="5px"
										w={{ xs: "50%", sm: "auto" }}
										fontSize="small"
									>
										{variantDetails.aceptar.text}
									</Button>
								</Flex>
								<EditLocationModal
									isOpen={isOpenMapModal}
									onClose={onCloseMapModal}
									setCoordinates={(coordinates: [number, number] | undefined) => {
										if (coordinates) {
											form.setFieldValue("geometria", { type: "Point", coordinates: coordinates });
										} else {
											form.setFieldValue("geometria", undefined);
										}
									}}
									initialValue={form.values.geometria?.coordinates}
								/>
							</Form>
						)}
					</Formik>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default AssetModal;
