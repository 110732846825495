import { ActionReducerMapBuilder } from "@reduxjs/toolkit";

import { ActivoCompletoOutput } from "components/Portfolio/types/activos";
import { THUNK_STATE } from "config/types";
import { putEditarActivoThunk, putRechazarActivoThunk, putValidarActivoThunk } from "store/portfolio/thunks";

import { IteSlice } from "./reducer";
import {
	getIteListThunk,
	getIteByIdThunk,
	getDatosInventarioThunk,
	getNecListThunk,
	getNeeListThunk,
	getNaeListThunk,
	getNcnListThunk,
	putIteValidarThunk,
	putIteRechazarThunk,
	getReportsThunk,
} from "./thunks";

// GET Ite/Ite
export const getIteListExtraReducer = (builder: ActionReducerMapBuilder<IteSlice>) => [
	builder.addCase(getIteListThunk.pending, (state) => ({
		...state,
		iteList: {
			thunkState: THUNK_STATE.PENDING,
			data: state.iteList.data,
		},
	})),
	builder.addCase(getIteListThunk.rejected, (state) => ({
		...state,
		iteList: {
			thunkState: THUNK_STATE.REJECTED,
			data: state.iteList.data,
		},
	})),
	builder.addCase(getIteListThunk.fulfilled, (state, action) => ({
		...state,
		iteList: {
			data: action.payload,
			thunkState: THUNK_STATE.FULFILLED,
		},
	})),
];

// GET Ite/Ite/${Id}
export const getIteByIdExtraReducer = (builder: ActionReducerMapBuilder<IteSlice>) => [
	builder.addCase(getIteByIdThunk.pending, (state) => ({
		...state,
		iteSelected: {
			thunkState: THUNK_STATE.PENDING,
			data: state.iteSelected.data,
		},
	})),
	builder.addCase(getIteByIdThunk.rejected, (state) => ({
		...state,
		iteSelected: {
			thunkState: THUNK_STATE.REJECTED,
			data: state.iteSelected.data,
		},
	})),
	builder.addCase(getIteByIdThunk.fulfilled, (state, action) => ({
		...state,
		iteSelected: {
			data: action.payload,
			thunkState: THUNK_STATE.FULFILLED,
		},
	})),
];

// GET Ite/reports/${Id}
export const getReportExtraReducer = (builder: ActionReducerMapBuilder<IteSlice>) => [
	builder.addCase(getReportsThunk.pending, (state) => ({
		...state,
		reports: {
			thunkState: THUNK_STATE.PENDING,
			data: [],
		},
	})),
	builder.addCase(getReportsThunk.rejected, (state) => ({
		...state,
		reports: {
			thunkState: THUNK_STATE.REJECTED,
			data: [],
		},
	})),
	builder.addCase(getReportsThunk.fulfilled, (state, action) => ({
		...state,
		reports: {
			data: action.payload,
			thunkState: THUNK_STATE.FULFILLED,
		},
	})),
];

export const getDatosInventarioExtraReducer = (builder: ActionReducerMapBuilder<IteSlice>) => [
	builder.addCase(getDatosInventarioThunk.pending, (state) => ({
		...state,
		inv: {
			thunkState: THUNK_STATE.PENDING,
			data: state.inv.data,
		},
	})),
	builder.addCase(getDatosInventarioThunk.rejected, (state) => ({
		...state,
		inv: {
			thunkState: THUNK_STATE.REJECTED,
			data: state.inv.data,
		},
	})),
	builder.addCase(getDatosInventarioThunk.fulfilled, (state, action) => ({
		...state,
		inv: {
			thunkState: THUNK_STATE.FULFILLED,
			data: action.payload,
		},
	})),
];

// GET IDE/nec/items
export const getNecListExtraReducer = (builder: ActionReducerMapBuilder<IteSlice>) => [
	builder.addCase(getNecListThunk.pending, (state) => ({
		...state,
		nec: {
			thunkState: THUNK_STATE.PENDING,
			data: state.nec.data,
		},
	})),
	builder.addCase(getNecListThunk.rejected, (state) => ({
		...state,
		nec: {
			thunkState: THUNK_STATE.REJECTED,
			data: state.nec.data,
		},
	})),
	builder.addCase(getNecListThunk.fulfilled, (state, action) => ({
		...state,
		nec: {
			thunkState: THUNK_STATE.FULFILLED,
			data: action.payload,
		},
	})),
];

// GET IDE/nee/items
export const getNeeListExtraReducer = (builder: ActionReducerMapBuilder<IteSlice>) => [
	builder.addCase(getNeeListThunk.pending, (state) => ({
		...state,
		nee: {
			thunkState: THUNK_STATE.PENDING,
			data: state.nee.data,
		},
		neeContexto: {
			thunkState: THUNK_STATE.PENDING,
			data: state.neeContexto.data,
		},
	})),
	builder.addCase(getNeeListThunk.rejected, (state) => ({
		...state,
		nee: {
			thunkState: THUNK_STATE.REJECTED,
			data: state.nee.data,
		},
		neeContexto: {
			thunkState: THUNK_STATE.REJECTED,
			data: state.neeContexto.data,
		},
	})),
	builder.addCase(getNeeListThunk.fulfilled, (state, action) => ({
		...state,
		nee: {
			data: action.payload.items,
			thunkState: THUNK_STATE.FULFILLED,
		},
		neeContexto: {
			thunkState: THUNK_STATE.FULFILLED,
			data: action.payload.contexto,
		},
	})),
];

// GET IDE/nae/items
export const getNaeListExtraReducer = (builder: ActionReducerMapBuilder<IteSlice>) => [
	builder.addCase(getNaeListThunk.pending, (state) => ({
		...state,
		nae: {
			thunkState: THUNK_STATE.PENDING,
			data: state.nae.data,
		},
	})),
	builder.addCase(getNaeListThunk.rejected, (state) => ({
		...state,
		nae: {
			thunkState: THUNK_STATE.REJECTED,
			data: state.nae.data,
		},
	})),
	builder.addCase(getNaeListThunk.fulfilled, (state, action) => ({
		...state,
		nae: {
			data: action.payload,
			thunkState: THUNK_STATE.FULFILLED,
		},
	})),
];

// GET IDE/ncn/items
export const getNcnListExtraReducer = (builder: ActionReducerMapBuilder<IteSlice>) => [
	builder.addCase(getNcnListThunk.pending, (state) => ({
		...state,
		ncn: {
			thunkState: THUNK_STATE.PENDING,
			data: state.ncn.data,
		},
	})),
	builder.addCase(getNcnListThunk.rejected, (state) => ({
		...state,
		ncn: {
			thunkState: THUNK_STATE.REJECTED,
			data: state.ncn.data,
		},
	})),
	builder.addCase(getNcnListThunk.fulfilled, (state, action) => ({
		...state,
		ncn: {
			data: action.payload,
			thunkState: THUNK_STATE.FULFILLED,
		},
	})),
];

// PUT ITE/validar
export const putIteValidarExtraReducer = (builder: ActionReducerMapBuilder<IteSlice>) => [
	builder.addCase(putIteValidarThunk.pending, (state) => ({
		...state,
		iteSelected: {
			thunkState: THUNK_STATE.PENDING,
			data: state.iteSelected.data,
		},
	})),
	builder.addCase(putIteValidarThunk.rejected, (state) => ({
		...state,
		iteSelected: {
			thunkState: THUNK_STATE.REJECTED,
			data: state.iteSelected.data,
		},
	})),
	builder.addCase(putIteValidarThunk.fulfilled, (state, action) => ({
		...state,
		iteSelected: {
			data: action.payload,
			thunkState: THUNK_STATE.FULFILLED,
		},
	})),
];

// PUT ITE/rechazar
export const putIteRechazarExtraReducer = (builder: ActionReducerMapBuilder<IteSlice>) => [
	builder.addCase(putIteRechazarThunk.pending, (state) => ({
		...state,
		iteSelected: {
			thunkState: THUNK_STATE.PENDING,
			data: state.iteSelected.data,
		},
	})),
	builder.addCase(putIteRechazarThunk.rejected, (state) => ({
		...state,
		iteSelected: {
			thunkState: THUNK_STATE.REJECTED,
			data: state.iteSelected.data,
		},
	})),
	builder.addCase(putIteRechazarThunk.fulfilled, (state, action) => ({
		...state,
		iteSelected: {
			data: action.payload,
			thunkState: THUNK_STATE.FULFILLED,
		},
	})),
];

// PUT Editar Activo
export const putEditarActivoExtraReducer = (builder: ActionReducerMapBuilder<IteSlice>) => [
	builder.addCase(putEditarActivoThunk.pending, (state) => ({
		...state,
		iteSelected: {
			thunkState: THUNK_STATE.PENDING,
			data: state.iteSelected.data,
		},
	})),
	builder.addCase(putEditarActivoThunk.rejected, (state) => ({
		...state,
		iteSelected: {
			thunkState: THUNK_STATE.REJECTED,
			data: state.iteSelected.data,
		},
	})),
	builder.addCase(putEditarActivoThunk.fulfilled, (state, action) => {
		state.iteSelected.thunkState = THUNK_STATE.FULFILLED;
		if (!state.iteSelected.data) return;
		const { id, ...assetData } = action.payload;
		state.iteSelected.data.asset = { id, ...assetData } as ActivoCompletoOutput;
	}),
];

// PUT Validar Activo
export const putValidarActivoExtraReducer = (builder: ActionReducerMapBuilder<IteSlice>) => [
	builder.addCase(putValidarActivoThunk.pending, (state) => ({
		...state,
		thunkState: THUNK_STATE.PENDING,
	})),
	builder.addCase(putValidarActivoThunk.rejected, (state) => ({
		...state,
		thunkState: THUNK_STATE.REJECTED,
	})),
	builder.addCase(putValidarActivoThunk.fulfilled, (state, action) => {
		state.iteSelected.thunkState = THUNK_STATE.FULFILLED;
		if (!state.iteSelected.data) return;
		const { id, ...assetData } = action.payload;
		state.iteSelected.data.asset = { id, ...assetData } as ActivoCompletoOutput;
	}),
];

// PUT Rechazar Activo
export const putRechazarActivoExtraReducer = (builder: ActionReducerMapBuilder<IteSlice>) => [
	builder.addCase(putRechazarActivoThunk.pending, (state) => ({
		...state,
		thunkState: THUNK_STATE.PENDING,
	})),
	builder.addCase(putRechazarActivoThunk.rejected, (state) => ({
		...state,
		thunkState: THUNK_STATE.REJECTED,
	})),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	builder.addCase(putRechazarActivoThunk.fulfilled, (state, action) => ({
		...state,
		iteSelected: {
			thunkState: THUNK_STATE.FULFILLED,
			data: null,
		},
		iteList: {
			thunkState: THUNK_STATE.FULFILLED,
			data: [],
		},
		thunkState: THUNK_STATE.FULFILLED,
	})),
];
