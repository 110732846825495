import React from "react";

import { Text, Button } from "@chakra-ui/react";

import { actions as portfolioActions } from "store/portfolio/reducer";
import { getInfoActivosThunk } from "store/portfolio/thunks";
import { useAppDispatch } from "store/store";

export const ClearFiltersButton: React.FC = () => {
	const dispatch = useAppDispatch();

	const resetFilters = async () => {
		dispatch(portfolioActions.resetFilterSelection());
		dispatch(portfolioActions.resetOrderBy());
		await dispatch(getInfoActivosThunk({}));
	};

	return (
		<React.Fragment>
			<Button
				size="sm"
				bgColor="gray.200"
				borderRadius="md"
				onClick={resetFilters}
				_hover={{ cursor: "pointer", bgColor: "gray.400" }}
				ml="1rem"
			>
				<Text textStyle="body3" color="gray.600" _hover={{ color: "white" }}>
					Limpiar Filtros
				</Text>
			</Button>
		</React.Fragment>
	);
};
