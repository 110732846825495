import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ActivoCompletoOutput } from "components/Portfolio/types/activos";
import { Project, StatePropWithThunkState, THUNK_STATE } from "config/types";

import { SLICE_NAMES } from "../constant";
import {
	activeAssetsExtraReducers,
	activeProjectsExtraReducers,
	tecnicosExtraReducer,
	userEmailExtraReducer,
	userFeaturesExtraReducer,
} from "./extraReducers";

export interface ISlice {
	thunkState: THUNK_STATE;
	activeProjects: StatePropWithThunkState<Project[]>;
	activeAssets: { [projectRef: string]: StatePropWithThunkState<ActivoCompletoOutput[]> };
	features: StatePropWithThunkState<string[]>;
	tecnicos: StatePropWithThunkState<string[]>;
	userEmail: string;
	mapStyle: string;
}

const sliceInitialState: ISlice = {
	thunkState: THUNK_STATE.IDLE,
	activeProjects: { thunkState: THUNK_STATE.IDLE, data: [] },
	activeAssets: {},
	features: { thunkState: THUNK_STATE.IDLE, data: [] },
	tecnicos: { thunkState: THUNK_STATE.IDLE, data: [] },
	userEmail: "",
	mapStyle: "default",
};

const customSlice = createSlice({
	name: `${SLICE_NAMES.USERS}`,
	initialState: sliceInitialState,
	reducers: {
		setStyle: (state, action: PayloadAction<string>) => {
			state.mapStyle = action.payload;
		},
	},
	extraReducers: (builder) => {
		userEmailExtraReducer(builder);
		activeProjectsExtraReducers(builder);
		activeAssetsExtraReducers(builder);
		userFeaturesExtraReducer(builder);
		tecnicosExtraReducer(builder);
	},
});

export const actions = customSlice.actions;
export default customSlice.reducer;
