import React from "react";

import { Tag, TagCloseButton, TagLabel } from "@chakra-ui/react";

import { actions as portfolioActions } from "store/portfolio/reducer";
import { useAppDispatch, useAppSelector } from "store/store";

interface IProps {
	column: string;
	label: string;
	range: string[];
}

export const RangeFilterTag: React.FC<IProps> = (props) => {
	const { column, label, range } = props;
	const filterSelection = useAppSelector((state) => state.portfolio.filterSelection);

	const dispatch = useAppDispatch();

	const handleClose = () => {
		const newFilterSelection = { ...filterSelection };
		delete newFilterSelection[column];
		dispatch(portfolioActions.setFilterSelection(newFilterSelection));
	};

	const displayRange = () => {
		const from: string | undefined = range[0] || undefined;
		const to: string | undefined = range[1] || undefined;

		const formatter = Intl.DateTimeFormat("es-ES", { day: "numeric", month: "numeric", year: "numeric" });

		if (from === undefined && to === undefined) return "";
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		if (from === undefined) return `Hasta ${formatter.format(new Date(to!))}`;
		if (to === undefined) return `Desde ${formatter.format(new Date(from))}`;
		return `${label} desde ${formatter.format(new Date(from))} hasta ${formatter.format(new Date(to))}`;
	};

	return (
		<Tag size="sm" borderRadius="md" variant="solid" bgColor="gray.200" ml=".5rem" key={range.toString()}>
			<TagLabel fontSize="body3" color="gray.600" m=".25rem">
				{displayRange()}
			</TagLabel>
			<TagCloseButton color="gray.600" onClick={() => handleClose()} />
		</Tag>
	);
};
