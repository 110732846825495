import React from "react";

import { AuthenticationProvider } from "@arup-group/cognito-authenticator";
import { ChakraProvider } from "@chakra-ui/react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import FullsizeSpinner from "common/components/FullsizeSpinner";
import PrintView from "common/components/Print/Print";
import { envConfig } from "config/env";
import { store } from "store/store";

import { AppRoutes } from "./config/routes";
import { theme } from "./config/theme";

export const App: React.FC = () => (
	<ChakraProvider theme={theme}>
		<Provider store={store}>
			<React.Suspense fallback={<FullsizeSpinner />}>
				<BrowserRouter>
					<Routes>
						<Route path="/report/:assetId/:iteId" element={<PrintView />} />
						<Route
							path="/*"
							element={
								<AuthenticationProvider
									region={envConfig.AWS_REGION}
									userPoolId={envConfig.AWS_POOL_ID}
									userPoolWebClientId={envConfig.AWS_WEB_CLIENT_ID}
									domain={envConfig.AWS_COGNITO_DOMAIN}
									azureADProviderId={envConfig.AWS_AZURE_PROVIDER_ID}
									allowSignUp={false}
								>
									<AppRoutes />
								</AuthenticationProvider>
							}
						/>
					</Routes>
				</BrowserRouter>
			</React.Suspense>
		</Provider>
	</ChakraProvider>
);
