import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ActivoCompletoOutput, PortfolioStats } from "components/Portfolio/types/activos";
import { PortfolioFilterSelection } from "components/Portfolio/types/filterData";
import { ApiPaginatedResponse, StatePropWithThunkState, THUNK_STATE } from "config/types";

import { SLICE_NAMES } from "../constant";
import {
	getInfoActivosExtraReducer,
	getPortfolioStatssExtraReducer,
	putEditarActivoExtraReducer,
	postNuevoActivoValidadoExtraReducer,
	putRechazarActivoExtraReducer,
	putValidarActivoExtraReducer,
	putFechaEntregaCertificacionExtraReducer,
	postComentarioExtraReducer,
} from "./extraReducers";

export interface PortfolioSlice {
	allAssetsInfo: ActivoCompletoOutput[];
	assetsInfo: StatePropWithThunkState<ApiPaginatedResponse<ActivoCompletoOutput[]>>;
	portfolioStats: StatePropWithThunkState<PortfolioStats | null>;
	certificationActionThunkState: THUNK_STATE;
	commentActionThunkState: THUNK_STATE;
	filterSelection: PortfolioFilterSelection | null;
	orderBy: { column: keyof ActivoCompletoOutput; direction: "asc" | "desc" } | null;
	page: number;
}

const sliceInitialState: PortfolioSlice = {
	allAssetsInfo: [],
	assetsInfo: {
		thunkState: THUNK_STATE.IDLE,
		data: {
			data: [],
			pagination: {
				total: -1,
				lastPage: -1,
				perPage: -1,
				currentPage: -1,
				from: -1,
				to: -1,
			},
		},
	},
	portfolioStats: {
		thunkState: THUNK_STATE.IDLE,
		data: null,
	},
	certificationActionThunkState: THUNK_STATE.IDLE,
	commentActionThunkState: THUNK_STATE.IDLE,
	filterSelection: null,
	orderBy: null,
	page: 1,
};

const customSlice = createSlice({
	name: `${SLICE_NAMES.PORTFOLIO}`,
	initialState: sliceInitialState,
	reducers: {
		setFilterSelection: (state, action: PayloadAction<PortfolioFilterSelection>) => ({
			...state,
			filterSelection: { ...action.payload },
		}),
		resetFilterSelection: (state) => ({ ...state, filterSelection: null }),
		setOrderBy: (state, action: PayloadAction<{ column: keyof ActivoCompletoOutput; direction: "asc" | "desc" }>) => ({
			...state,
			orderBy: { ...action.payload },
		}),
		resetOrderBy: (state) => ({ ...state, orderBy: null }),
		setPage(state, action: PayloadAction<number>) {
			state.page = action.payload;
		},
		clearAllAssetsInfo: (state) => ({ ...state, allAssetsInfo: [] }),
		setAllAssetsInfo: (state, action: PayloadAction<ActivoCompletoOutput[]>) => ({
			...state,
			allAssetsInfo: action.payload,
		}),
	},
	extraReducers: (builder) => {
		getInfoActivosExtraReducer(builder);
		getPortfolioStatssExtraReducer(builder);
		putEditarActivoExtraReducer(builder);
		putValidarActivoExtraReducer(builder);
		putRechazarActivoExtraReducer(builder);
		postNuevoActivoValidadoExtraReducer(builder);
		putFechaEntregaCertificacionExtraReducer(builder);
		postComentarioExtraReducer(builder);
	},
});

export const actions = customSlice.actions;
export default customSlice.reducer;
