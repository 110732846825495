import React from "react";

import {
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Grid,
	GridItem,
	Text,
} from "@chakra-ui/react";

import { defaultValuesSistemas } from "components/Portfolio/defaultValues";
import { noOfLines } from "helpers/displayWhenPrint";
import { capitalizeFirstLetter } from "helpers/text";

import { RowBody } from "../RowBody";
import { Sistema } from "../types";

interface IProps {
	sistema: Sistema;
	print?: boolean;
}

export const TableRow: React.FC<IProps> = (props) => {
	const { sistema, print } = props;

	return (
		<AccordionItem>
			{({ isExpanded }) => (
				<Box style={{ breakInside: "avoid" }}>
					<AccordionButton minHeight="2.75rem" p="0" pl="1rem">
						<Grid
							templateColumns="repeat(1, 1fr)"
							gap={5}
							w="100%"
							textAlign="left"
							alignItems="center"
							overflow="hidden"
						>
							<GridItem colSpan={1}>
								<Text textStyle="body3" color="gray.800" noOfLines={noOfLines(print)}>
									{capitalizeFirstLetter(
										defaultValuesSistemas.find((it) => it.value === sistema.label)?.label ?? sistema.label,
									)}
								</Text>
							</GridItem>
						</Grid>
						{!print && <AccordionIcon color="gray.500" />}
					</AccordionButton>
					<AccordionPanel borderTop="0.5px solid" borderColor="gray.100" padding="1rem 0" backgroundColor="gray.table">
						{isExpanded && <RowBody sistema={sistema} print={print} />}
					</AccordionPanel>
				</Box>
			)}
		</AccordionItem>
	);
};
