import React from "react";

import { ChevronDownIcon } from "@chakra-ui/icons";
import {
	Menu,
	MenuButton,
	IconButton,
	MenuList,
	MenuItem,
	Flex,
	Text,
	MenuDivider,
	Icon,
	MenuOptionGroup,
	Checkbox,
	Stack,
} from "@chakra-ui/react";
import { BsSortDown, BsSortUpAlt } from "react-icons/bs";

import { Choice } from "components/Portfolio/defaultValues";
import { ActivoCompletoOutput } from "components/Portfolio/types/activos";
import { columnHeaderLabel } from "helpers/FiltersColumns";
import { getFilterSelection } from "helpers/filterSelection";
import { capitalizeFirstLetter } from "helpers/text";
import { actions as portfolioActions } from "store/portfolio/reducer";
import { useAppDispatch, useAppSelector } from "store/store";

import { booleanValidar } from "./validado";

type IProps = {
	column: keyof ActivoCompletoOutput;
	values?: Choice[];
};

export const FilterDropDown: React.FC<IProps> = (props) => {
	const { column, values } = props;
	const filterSelection = useAppSelector((state) => state.portfolio.filterSelection);
	const [order, setOrder] = React.useState<"asc" | "desc" | undefined>(undefined);
	const [selected, setSelected] = React.useState<string[]>([]);

	const dispatch = useAppDispatch();

	const handleCheckBoxDefault = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		const { value } = e.target;
		if (!selected.includes(value)) {
			setSelected([...selected, value]);
		}
	};

	const isGenericChoice = (value: string) => value.includes("Todos") || value.includes("Todas");

	const handleCheckboxAmbito = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		const { value } = e.target;
		if (!selected.includes(value)) {
			setSelected([...selected, value]);
		}
		if (value.includes("AV") && isGenericChoice(value)) {
			const avValues = values?.filter(({ red }) => red === "AV")?.map((it) => it.value) || [];
			avValues && setSelected([...selected, ...avValues]);
		}
		if (value.includes("RC") && isGenericChoice(value)) {
			const rcValues = values?.filter(({ red }) => red === "RC")?.map((it) => it.value) || [];
			rcValues && setSelected([...selected, ...rcValues]);
		}
	};

	const handleCheckBox = (e: React.ChangeEvent<HTMLInputElement>, column: string) => {
		switch (column) {
			case "ambito":
				return handleCheckboxAmbito(e);
			default:
				return handleCheckBoxDefault(e);
		}
	};

	const isBold = (value: string) => (isGenericChoice(value) ? "bold" : "normal");

	const indice =
		columnHeaderLabel(column) &&
		columnHeaderLabel(column)
			.split(" ")
			.find(
				(value) =>
					value.startsWith("N") && !value.startsWith("Nº") && !value.startsWith("N°") && !isNaN(+value.slice(-1)),
			);
	const firstPart = columnHeaderLabel(column) && indice && columnHeaderLabel(column).split(indice)[0];
	const indiceNumber = indice && indice.toString().slice(-1);
	const indiceString = indice && indice.toString().slice(0, -1);
	const subIndiceIndicator = indiceString && indiceString.slice(-1);
	const subIndice =
		indiceString && subIndiceIndicator === "E"
			? subIndiceIndicator.toUpperCase()
			: indiceString && capitalizeFirstLetter(indiceString.slice(-2));
	const firstLetters = indiceString && indiceString.slice(0, subIndice === "E" ? -1 : -2).toUpperCase();

	React.useEffect(() => {
		if (order !== undefined) {
			dispatch(portfolioActions.setOrderBy({ column: column, direction: order }));
		}
	}, [order]);

	React.useEffect(() => {
		if (selected.length > 0) {
			if (filterSelection === null) {
				const newFilterSelection = { [getFilterSelection(column)]: selected };
				dispatch(portfolioActions.setFilterSelection(newFilterSelection));
			} else if (filterSelection !== null) {
				const filterKey = Object.keys(filterSelection).find((key) => key === getFilterSelection(column));
				if (filterKey !== undefined) {
					const selectedValues = filterSelection[filterKey];
					if (selectedValues !== undefined) {
						const newSelectedValues = selectedValues.filter((value: string) => !selected.includes(value));
						const newSelected = selected.filter((value: string) => !selectedValues.includes(value));
						const newSelectedValuesFinal = [...newSelectedValues, ...newSelected];
						const newFilterSelection = { ...filterSelection, [getFilterSelection(column)]: newSelectedValuesFinal };
						dispatch(portfolioActions.setFilterSelection(newFilterSelection));
					} else {
						const newFilterSelection = { ...filterSelection, [getFilterSelection(column)]: selected };
						dispatch(portfolioActions.setFilterSelection(newFilterSelection));
					}
				} else if (filterKey === undefined) {
					const newFilterSelection = { ...filterSelection, [getFilterSelection(column)]: selected };
					dispatch(portfolioActions.setFilterSelection(newFilterSelection));
				}
			}
		}
	}, [selected]);

	return (
		<Flex alignItems="center">
			<Menu closeOnSelect={false}>
				<MenuButton
					as={IconButton}
					aria-label="Filter"
					icon={<ChevronDownIcon color="gray.700" />}
					variant="solid"
					borderRadius="md"
					bgColor="white"
					size="1rem"
					p=".05rem"
					border="1px solid"
					borderColor="gray.200"
				/>
				<MenuList minWidth="240px">
					<MenuOptionGroup type="radio">
						<MenuItem _hover={{ bg: "gray.100" }} onClick={() => setOrder("desc")}>
							<Icon as={BsSortDown} fontSize="1rem" color="gray.500" mr=".75rem" />
							<Text textStyle="body3">Descendente</Text>
						</MenuItem>
						<MenuItem _hover={{ bg: "gray.100" }} onClick={() => setOrder("asc")}>
							<Icon as={BsSortUpAlt} fontSize="1rem" color="gray.500" mr=".75rem" />
							<Text textStyle="body3">Ascendente</Text>
						</MenuItem>
					</MenuOptionGroup>
					{values && <MenuDivider w="90%" mx="auto" />}
					<Stack spacing={1} direction="column" pl=".75rem">
						{values &&
							values.map(({ label, value }) => (
								<Checkbox
									colorScheme="gray"
									name={value}
									value={value}
									key={value}
									size="sm"
									color="gray.700"
									mt=".25rem"
									onChange={(e) => handleCheckBox(e, column)}
									isChecked={filterSelection && filterSelection[getFilterSelection(column)]?.includes(value)}
								>
									<Text textStyle="body3" fontWeight={isBold(value)}>
										{capitalizeFirstLetter(booleanValidar(label))}
									</Text>
								</Checkbox>
							))}
					</Stack>
				</MenuList>
			</Menu>
			<Text title={columnHeaderLabel(column)} color="gray.500" textStyle="body3" pl=".5rem">
				{indice ? (
					<>
						{firstPart}
						{firstLetters}
						<Text as="sub">{subIndice}</Text>
						{indiceNumber}
					</>
				) : (
					columnHeaderLabel(column)
				)}
			</Text>
		</Flex>
	);
};
