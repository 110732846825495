import { ComponentStyleConfig } from "@chakra-ui/react";

const componentOverride: ComponentStyleConfig = {
	baseStyle: {
		label: {},
	},
	variants: {
		primary: {
			control: {
				_checked: {
					bg: "gray.500",
					borderColor: "gray.500",
				},
			},
		},
		greenCheckbox: {
			control: {
				borderColor: "gray.500",
				_checked: {
					bg: "green.400",
					borderColor: "green.400",
					_hover: {
						bg: "green.600",
						borderColor: "green.600",
					},
				},
			},
		},
		redCheckbox: {
			control: {
				borderColor: "gray.500",
				_checked: {
					bg: "red.400",
					borderColor: "red.400",
					_hover: {
						bg: "red.600",
						borderColor: "red.600",
					},
				},
			},
		},
		lightCheckbox: {
			control: {
				borderColor: "gray.200",
				_checked: {
					bg: "gray.400",
					borderColor: "gray.400",
					_hover: {
						bg: "gray.600",
						borderColor: "gray.600",
					},
				},
			},
		},
	},
	sizes: {
		md: {
			label: {
				fontFamily: "Arial",
				fontSize: "body3",
				fontWeight: "body3",
				lineHeight: "body3",
				letterSpacing: "body3",
				color: "gray.800",
			},
		},
	},
	defaultProps: {
		size: "md",
	},
};

export default componentOverride;
