import React from "react";

import { Box } from "@chakra-ui/react";

import { AssetInfo } from "common/components/AssetInfo";
import { useAppSelector } from "store/store";

export const MiddleSectionInfo: React.FC = () => {
	const datosInventario = useAppSelector((state) => state.ites.inv.data);

	if (!datosInventario) return <React.Fragment />;
	return (
		<Box pl="1rem">
			<AssetInfo
				title="Datos Generales Recogidos"
				asset={datosInventario.contexto}
				referencia={datosInventario.referencia}
			/>
		</Box>
	);
};
