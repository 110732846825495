import React from "react";

import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";

import { defaultValues } from "../../../defaultValues";
import { FilterDropDown } from "../../components/FilterDropDown";

export const EnergeticaHeader: React.FC = () => {
	return (
		<Box h=".5rem" background="transparent" p="0 1rem" mb=".5rem">
			<Flex width="100%" height="100%" alignItems="center">
				<Grid templateColumns="repeat(16, 1fr)" w="100%" pr="1.5rem" pl=".3rem">
					<GridItem colSpan={1} alignSelf="center">
						<FilterDropDown column="por_validar" values={defaultValues.por_validar} />
					</GridItem>
					<GridItem colSpan={3} alignSelf="center">
						<FilterDropDown column="nombre" />
					</GridItem>
					<GridItem colSpan={1} alignSelf="center">
						<FilterDropDown column="nee_indice" values={defaultValues.nee_indice} />
					</GridItem>
					<GridItem colSpan={2} alignSelf="center">
						<FilterDropDown column="nee_consumo_energetico_kwh" />
					</GridItem>
					<GridItem colSpan={2} alignSelf="center">
						<FilterDropDown column="nee_consumo_por_m2" />
					</GridItem>
					<GridItem colSpan={2} alignSelf="center">
						<FilterDropDown column="nee_tipo_edificio" values={defaultValues.nee_tipo_edificio} />
					</GridItem>
					<GridItem colSpan={2} alignSelf="center">
						<FilterDropDown column="nee_categoria_mejoras" values={defaultValues.nee_categoria_mejoras} />
					</GridItem>
					<GridItem colSpan={1} alignSelf="center">
						<FilterDropDown column="nee_total_mejoras_3" />
					</GridItem>
					<GridItem colSpan={1} alignSelf="center">
						<FilterDropDown column="nee_total_mejoras_2" />
					</GridItem>
					<GridItem colSpan={1} alignSelf="center">
						<FilterDropDown column="nee_total_mejoras_1" />
					</GridItem>
				</Grid>
			</Flex>
		</Box>
	);
};
