import { apiClient } from "api";

export interface CookieResponse {
	cookies: {
		"CloudFront-Key-Pair-Id": string;
		"CloudFront-Signature": string;
		"CloudFront-Expires"?: number;
		"CloudFront-Policy"?: string;
	};
	paths: string[];
}
export const getSignedCookies = async (data: { assetId: string }): Promise<CookieResponse> => {
	return await apiClient.post<CookieResponse>("forms/getSignedCookies/CFCookies", data).then((res) => res.data);
};
