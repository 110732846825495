import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { envConfig } from "config/env";

import iteReducer from "./ITE/reducer";
import portfolioReducer from "./portfolio/reducer";
import userReducer from "./user/reducer";

export const store = configureStore({
	reducer: {
		user: userReducer,
		ites: iteReducer,
		portfolio: portfolioReducer,
	},
	devTools: envConfig.ENV_NAME !== "production",
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {user: UserState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
