import React from "react";

import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";

import { defaultValues, getUniqueChoices } from "components/Portfolio/defaultValues";

import { FilterDropDown } from "../../components/FilterDropDown";

export const LocalizacionHeader: React.FC = () => {
	return (
		<Box h=".5rem" background="transparent" p="0 1rem" mb=".5rem">
			<Flex width="100%" height="100%" alignItems="center">
				<Grid templateColumns="repeat(35, 1fr)" w="100%" pr="1.5rem" pl=".3rem">
					<GridItem colSpan={3}>
						<FilterDropDown column="por_validar" values={defaultValues.por_validar} />
					</GridItem>
					<GridItem colSpan={5}>
						<FilterDropDown column="nombre" />
					</GridItem>
					<GridItem colSpan={3}>
						<FilterDropDown column="ambito" values={defaultValues.ambito} />
					</GridItem>
					<GridItem colSpan={3}>
						<FilterDropDown column="jefatura" values={getUniqueChoices(defaultValues.jefatura)} />
					</GridItem>
					<GridItem colSpan={3}>
						<FilterDropDown column="agrupacion" />
					</GridItem>
					<GridItem colSpan={3}>
						<FilterDropDown column="provincia" values={defaultValues.provincia} />
					</GridItem>
					<GridItem colSpan={4}>
						<FilterDropDown column="municipio" />
					</GridItem>
					<GridItem colSpan={4}>
						<FilterDropDown column="direccion" />
					</GridItem>
					<GridItem colSpan={2}>
						<FilterDropDown column="pk" />
					</GridItem>
					<GridItem colSpan={2}>
						<FilterDropDown column="via" />
					</GridItem>
					<GridItem colSpan={3}>
						<FilterDropDown column="codigo_estacion" />
					</GridItem>
				</Grid>
			</Flex>
		</Box>
	);
};
