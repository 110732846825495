export const getRelevanciaByUsos = (usos: string[], tipologiaActivo: string): "baja" | "media" | "alta" => {
	const valoresRelevancia = ["baja", "media", "alta"]; // Order is totally relevant, as we are using the index
	const relevanciaPorUsosFiltrado = relevanciasPorUso.filter(({ uso, tipologia }) => {
		const matchUso = usos.includes(uso);
		const matchTipologia = tipologia === tipologiaActivo;
		if (!usos.length) return matchTipologia;
		if (!tipologia) return matchUso;
		return matchUso && matchTipologia;
	});
	const relevancia = relevanciaPorUsosFiltrado.reduce(
		(previous, current) => {
			const prevRelevIdx = valoresRelevancia.findIndex((it) => it === previous.relevancia);
			const currRelevIdx = valoresRelevancia.findIndex((it) => it === current.relevancia);
			return currRelevIdx > prevRelevIdx ? current : previous;
		},
		{
			uso: "Ninguno",
			relevancia: "baja",
		},
	).relevancia;

	return relevancia;
};

interface RelevanciaPorUso {
	uso: string;
	tipologia?: string;
	relevancia: "baja" | "media" | "alta";
}
const relevanciasPorUso: RelevanciaPorUso[] = [
	{
		uso: "Almacén",
		relevancia: "baja",
	},
	{
		uso: "Aseos",
		relevancia: "baja",
	},
	{
		uso: "Autotransformador",
		relevancia: "alta",
	},
	{
		uso: "BTS",
		relevancia: "alta",
	},
	{
		uso: "Cambiador de ancho",
		relevancia: "alta",
	},
	{
		uso: "Caseta de túnel",
		relevancia: "alta",
	},
	{
		uso: "Caseta operadora",
		relevancia: "alta",
	},
	{
		uso: "Centro de instalaciones",
		relevancia: "alta",
	},
	{
		uso: "Centro de regulación de circulación",
		relevancia: "alta",
	},
	{
		uso: "Centro de transformación",
		relevancia: "alta",
	},
	{
		uso: "Cocina/comedor",
		relevancia: "baja",
	},
	{
		uso: "Cuarto técnico",
		relevancia: "alta",
	},
	{
		uso: "Dependencia fuera de traza",
		relevancia: "alta",
	},
	{
		uso: "Enclavamiento",
		relevancia: "alta",
	},
	{
		uso: "Equipo LAC",
		relevancia: "alta",
	},
	{
		uso: "Garaje",
		relevancia: "baja",
	},
	{
		uso: "Grupo electrógeno",
		relevancia: "alta",
	},
	{
		uso: "Oficina",
		relevancia: "media",
	},
	{
		uso: "Sala de comunicaciones",
		relevancia: "alta",
	},
	{
		uso: "Subestación eléctrica",
		relevancia: "alta",
	},
	{
		uso: "Taller",
		relevancia: "baja",
	},
	{
		uso: "Otro",
		tipologia: "Almacén",
		relevancia: "baja",
	},
	{
		uso: "Otro",
		tipologia: "Dependencia",
		relevancia: "alta",
	},
	{
		uso: "Otro",
		tipologia: "Edificio principal de Base de mantenimiento",
		relevancia: "media",
	},
	{
		uso: "Otro",
		tipologia: "Edificio técnico",
		relevancia: "alta",
	},
	{
		uso: "Otro",
		tipologia: "Caseta",
		relevancia: "alta",
	},
	{
		uso: "Otro",
		tipologia: "Oficinas",
		relevancia: "media",
	},
	{
		uso: "Otro",
		tipologia: "Subestación",
		relevancia: "alta",
	},
	{
		uso: "Otro",
		tipologia: "Garaje",
		relevancia: "baja",
	},
	{
		uso: "Otro",
		tipologia: "Taller",
		relevancia: "baja",
	},
	{
		uso: "Otro",
		tipologia: "Otro",
		relevancia: "baja",
	},
];
