import React from "react";

import { Divider, Flex, Text } from "@chakra-ui/react";

interface IProps {
	section?: "nae" | "nee";
}
export const NoElementsText: React.FC<IProps> = () => {
	const atPortfolio = location.pathname.includes("portfolio");

	return (
		<Flex justifyContent="center" flexDirection="column" alignItems="center">
			{!atPortfolio && <Divider />}
			<Text textStyle="body3" color="gray.600" mt="2rem">
				No procede / Sin información suficiente
			</Text>
		</Flex>
	);
};
