import React from "react";

import { Flex } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

import { PortfolioStatsCertificacion } from "../../Certificacion/Stats";
import { MiddleSection } from "./MiddleSection";
import { PortfolioStats } from "./Stats";
import { Title } from "./Tittle";

export const PortfolioBar: React.FC = () => {
	const { pathname } = useLocation();
	return (
		<Flex bg="white" p="1rem" gap="5rem" w="100%" justifyContent="space-between" alignItems="end" borderRadius=".5rem">
			<Title />
			<MiddleSection />
			{pathname.endsWith("certificacion") ? <PortfolioStatsCertificacion /> : <PortfolioStats />}
		</Flex>
	);
};
