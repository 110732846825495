export const getCodigoDeActivoPorTipologia = (tipologia: string) => {
	switch (tipologia) {
		case "Almacén":
			return "ECT.ALM";
		case "Dependencia":
		case "Edificio técnico":
		case "Caseta":
			return "ACT.ETL";
		case "Edificio principal de Base de mantenimiento":
		case "Oficinas":
			return "ACT.EOF";
		case "Subestación":
			return "ACT.SCC";
		case "Garaje":
			return "ACT.CCH";
		case "Taller":
			return "ACT.TLL";
		default:
			return "ACT.OTR";
	}
};
