import React from "react";

import { SearchIcon } from "@chakra-ui/icons";
import { Button, Text, Input, InputGroup, InputLeftElement, InputRightElement, Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { actions as portfolioActions } from "store/portfolio/reducer";
import { useAppDispatch, useAppSelector } from "store/store";

export const MiddleSection: React.FC = () => {
	const filterSelection = useAppSelector((state) => state.portfolio.filterSelection);
	const [search, setSearch] = React.useState<string>("");

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (search && e.key === "Enter") {
			dispatch(portfolioActions.setFilterSelection({ ...filterSelection, searchText: search }));
		}
	};

	const handleSearchButton = () => {
		if (search) {
			dispatch(portfolioActions.setFilterSelection({ ...filterSelection, searchText: search }));
		}
	};

	React.useEffect(() => {
		if (filterSelection === null || filterSelection.searchText === undefined) {
			setSearch("");
		}
	}, [filterSelection]);

	return (
		<Flex alignItems="center" gap="1rem" flex=".6">
			<Button
				size="md"
				minH="2rem"
				minW="6rem"
				variant="solid"
				bgColor="gray.400"
				borderRadius="5px"
				onClick={() => navigate("/mapa")}
				_hover={{ bgColor: "gray.500" }}
			>
				<Text textStyle="body3" color="white">
					Ver Mapa
				</Text>
			</Button>
			<InputGroup w="auto" flex="1">
				<InputLeftElement pointerEvents="none" color="gray.300" p=".6rem">
					<SearchIcon />
				</InputLeftElement>
				<Input
					pl="2rem"
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					onKeyPress={(e) => handleSearch(e)}
				/>
				<InputRightElement width="4.5rem" p=".3rem">
					<Button h="1.75rem" size="md" onClick={handleSearchButton}>
						Buscar
					</Button>
				</InputRightElement>
			</InputGroup>
		</Flex>
	);
};
