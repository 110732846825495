import React, { useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { MapboxPopup } from "common/components/Map/MapboxPopup";
import { MapboxPortfolioComponent } from "common/components/Map/MapboxPortfolioComponent";
import { ActivoCompletoOutput } from "components/Portfolio/types/activos";
import { capitalizeFirstLetter } from "helpers/text";
import { useAppSelector } from "store/store";

export const AssetsMap: React.FC = () => {
	const navigate = useNavigate();
	const assetsInfo = useAppSelector((state) => state.portfolio.allAssetsInfo);
	const { pathname } = useLocation();

	const [popupState, setPopupState] = useState<{
		display: boolean;
		latitude: number;
		longitude: number;
		name: string;
		location: string;
		navigation: () => void;
	}>({
		latitude: 40,
		longitude: 30,
		display: false,
		name: "name",
		location: "location",
		navigation: () => {
			return;
		},
	});

	const handleMarkerClick = (asset: ActivoCompletoOutput) => {
		setPopupState({
			display: true,
			longitude: asset.geometria?.coordinates[0] || 30,
			latitude: asset.geometria?.coordinates[1] || 40,
			name: capitalizeFirstLetter(asset.nombre || ""),
			location: `${capitalizeFirstLetter(asset.direccion)}, ${capitalizeFirstLetter(asset.municipio)}`,
			navigation: () => navigate(`/activo/${asset.id}/ite/${asset.ite_id}`, { state: { from: pathname } }),
		});
	};

	return (
		<>
			<MapboxPortfolioComponent
				mapOnClick={() => {
					setPopupState((state) => ({ ...state, display: false }));
				}}
				points={assetsInfo
					.filter((a) => a.geometria)
					.map((a) => ({
						id: a.id || "",
						longitude: a.geometria?.coordinates[0] || 30,
						latitude: a.geometria?.coordinates[1] || 40,
						type: a.estado_ite,
						onClick: () => handleMarkerClick(a),
					}))}
			>
				{popupState.display && (
					<MapboxPopup
						latitude={popupState.latitude}
						longitude={popupState.longitude}
						name={popupState.name}
						location={popupState.location}
						closeHandle={() => {
							setPopupState((state) => ({ ...state, display: false }));
						}}
						onClick={popupState.navigation}
					/>
				)}
			</MapboxPortfolioComponent>
		</>
	);
};
