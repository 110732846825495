import { NAE } from "components/ITE/TablaAccesibilidad/types";
import { NEC } from "components/ITE/TablaConservacion/types";
import { NEE } from "components/ITE/TablaEficiencia/types";
import { Sistema } from "components/ITE/TablaInventario/types";
import { NCN } from "components/ITE/TablaNormativa/types";

export const expandAllItems = (items: NEC[] | NEE[] | NAE[] | NCN[] | Sistema[]) => {
	const expandedItems: number[] = items.map((_, index: number) => index);
	return expandedItems;
};
