import React, { useEffect, useState } from "react";

import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Flex,
	Link,
	Button,
	Text,
	Divider,
	Stack,
	Textarea,
} from "@chakra-ui/react";

import { getComentarios } from "api/comentarios";
import FullsizeSpinner from "common/components/FullsizeSpinner";
import { ITECommentInput, ITECommentOutput } from "components/ITE/types/comentarios";
import { ActivoCompletoOutput } from "components/Portfolio/types/activos";
import { postCommentThunk } from "store/portfolio/thunks";
import { useAppDispatch, useAppSelector } from "store/store";

interface IProps {
	numero: number;
	onOpenComentario: () => void;
}

export const BotonComentarios: React.FC<IProps> = (props) => {
	const { numero, onOpenComentario } = props;

	return (
		<React.Fragment>
			<Flex mt=".25rem" noOfLines={1}>
				<Link textStyle="body3" color="gray.500" pl=".25rem" onClick={() => onOpenComentario()}>
					{numero ? `Ver ${numero} comentarios` : `Añadir comentario`}
				</Link>
			</Flex>
		</React.Fragment>
	);
};

interface ModalComentariosProps {
	asset: ActivoCompletoOutput | undefined;
	onClose: () => void;
}
export const ModalComentarios: React.FC<ModalComentariosProps> = (props) => {
	const { asset } = props;

	const dispatch = useAppDispatch();
	const features = useAppSelector((state) => state.user.features.data);
	const [loading, setLoading] = useState(true);
	const [comentarios, setComentarios] = useState<ITECommentOutput[]>([]);
	const [nuevoComentario, setNuevoComentario] = useState<ITECommentInput>();

	const hasAddCommentFeature = features.indexOf("add-comment") >= 0;

	useEffect(() => {
		if (!asset?.ite_id) return;
		getComentarios({ iteId: asset.ite_id })
			.then((res) => setComentarios(res.data))
			.then(() => setLoading(false));
	}, [asset?.ite_id]);

	const onAddComment = () => {
		if (!asset) return;
		setNuevoComentario({
			iteId: asset.ite_id,
			comment: "",
			event: "extra",
		});
	};

	const onClose = () => {
		setLoading(true);
		props.onClose();
	};

	const onSubmit = () => {
		if (nuevoComentario) dispatch(postCommentThunk(nuevoComentario));
		onClose();
	};

	return (
		<Modal isOpen={!!asset} onClose={onClose} size="3xl">
			<ModalOverlay />
			<ModalContent maxH="50dvh">
				<ModalHeader>
					<Text textStyle="body2" color="#006935">
						Comentarios
					</Text>
					<Text>{asset?.nombre}</Text>
				</ModalHeader>
				<ModalCloseButton />
				<Divider />
				<ModalBody overflowY="auto">
					{loading ? (
						<FullsizeSpinner />
					) : (
						comentarios.map((c) => (
							<Comentario
								key={c.id}
								title={c.event}
								author={c.author}
								content={c.comment}
								date={new Date(c.created_at)}
								editable={false}
							/>
						))
					)}
					{nuevoComentario && (
						<Comentario
							title={"extra"}
							author={"Usted"}
							date={new Date()}
							editable={hasAddCommentFeature}
							onChange={(value) => {
								if (!asset) return;
								setNuevoComentario({ iteId: asset.ite_id, event: "extra", comment: value });
							}}
						/>
					)}
					<Button
						size="lg"
						bg="green.400"
						color="white"
						py=".75rem"
						borderRadius="5px"
						fontSize="small"
						w="8rem"
						my=".5rem"
						onClick={onAddComment}
						disabled={!!nuevoComentario || !hasAddCommentFeature}
					>
						Añadir otro
					</Button>
				</ModalBody>
				<Divider />
				<ModalFooter display="flex" justifyContent="space-between">
					<Button
						form="comments-form"
						type="submit"
						size="lg"
						bg="gray.500"
						color="white"
						py=".75rem"
						borderRadius="5px"
						fontSize="small"
						onClick={onClose}
						w="8rem"
					>
						Cancelar
					</Button>
					<Button
						size="lg"
						bg="green.400"
						color="white"
						py=".75rem"
						borderRadius="5px"
						fontSize="small"
						disabled={!nuevoComentario || !hasAddCommentFeature}
						onClick={onSubmit}
					>
						Publicar
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

interface ComentarioProps {
	title: string;
	author: string;
	date: Date;
	content?: string;
	editable: boolean;
	onChange?: (v: string) => void;
}
const Comentario: React.FC<ComentarioProps> = (props) => {
	const { title, author, date, content, editable, onChange } = props;
	const formatter = Intl.DateTimeFormat("es-ES", { year: "numeric", month: "short", day: "numeric" });
	return (
		<Stack my="1rem">
			<Flex justifyContent="space-between">
				<Text>{TEXTOS_EVENTO[title] ?? title}</Text>
				<Text>
					{author} - {formatter.format(date)}
				</Text>
			</Flex>
			{editable ? (
				<Textarea
					fontSize="md"
					placeholder={"Escriba aquí su comentario"}
					name="content"
					onChange={(e) => onChange && onChange(e.target.value)}
				/>
			) : (
				<Text bg="rgba(0,0,0,0.05)" p=".5rem 1rem" borderRadius=".25rem" border="1px solid #eee" color="gray.700">
					{content || "Comentario vacío"}
				</Text>
			)}
		</Stack>
	);
};

const TEXTOS_EVENTO = {
	init: "Activo inicializado",
	discarded: "Activo descartado",
	rejected: "ITE Rechazada",
	inProgress: "ITE en Progreso",
	validated: "ITE Registrada",
	extra: "Comentario adicional",
};
