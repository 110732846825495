import { extendTheme } from "@chakra-ui/react";

import Button from "./Components/Button";
import Checkbox from "./Components/Checkbox";
import FormError from "./Components/FormError";
import FormLabel from "./Components/FormLabel";
import Input from "./Components/Input";
import Link from "./Components/Link";
import Popover from "./Components/Popover";
import Select from "./Components/Select";
import Switch from "./Components/Switch";
import Textarea from "./Components/Textarea";

const fontSizeBreakpoints = {
	"@media screen and (max-width: 1480px)": {
		fontSize: "12px",
	},
	"@media screen and (min-width: 1480px) and (max-width: 1560px)": {
		fontSize: "14px",
	},
	"@media screen and (min-width: 1560px)": {
		fontSize: "16px",
	},
	"@media screen and (min-width: 2500px)": {
		fontSize: "24px",
	},
};

export const theme = extendTheme({
	colors: {
		teal: {
			100: "#BFF7EA",
			200: "#9DEDDA",
			300: "#77DCCC",
			400: "#52C4BA",
			500: "#32A4A0",
			600: "#21808B",
			700: "#14586B",
			800: "#104B65",
			900: "#0D3D59",
			lightest: "#D6FFF5",
			darkest: "#092B3E",
		},
		red: {
			100: "#FFD9D9",
			200: "#FFB1B3",
			300: "#FA777C",
			400: "#F2454D",
			500: "#E61E28",
			600: "#D10911",
			700: "#B60007",
			800: "#940004",
			900: "#6D0003",
			lightest: "#FFEEEF",
			darkest: "#4D0002",
		},
		green: {
			100: "#CCF7C9",
			200: "#A3EB9F",
			300: "#85DB80",
			400: "#68C163",
			500: "#4BA046",
			600: "#4A7F45",
			700: "#3E5B3C",
			800: "#3D4E3B",
			900: "#2B372A",
			lightest: "#E4FDE3",
			darkest: "#20291F",
		},
		blue: {
			100: "#C3EAFE",
			200: "#96DCFD",
			300: "#6CC9F6",
			400: "#43B3EA",
			500: "#1E98D7",
			600: "#197CBE",
			700: "#005AAA",
			800: "#16477F",
			900: "#16477F",
			lightest: "#DBF2FF",
			darkest: "#0E223F",
		},
		yellow: {
			100: "#FFFAA8",
			200: "#FFF780",
			300: "#FFEA66",
			400: "#FFDF4C",
			500: "#FFD10A",
			600: "#E1A900",
			700: "#BD8400",
			800: "#986100",
			900: "#724403",
			lightest: "#FFFCDB",
			darkest: "#4B2C02",
		},
		gray: {
			50: "#F7FAFC",
			100: "#EDF2F7",
			200: "#E2E8F0",
			300: "#CBD5E0",
			400: "#A0AEC0",
			500: "#718096",
			600: "#4A5568",
			700: "#2D3748",
			800: "#1A202C",
			900: "#171923",
			lightest: "#FAFAFA",
			darkest: "#1C1C1C",
			background: "#F6F6F6",
			table: "#F9FBFD",
		},
		pureWhite: "#FFFFFF",
		pureBlack: "#000000",
	},
	fonts: {
		heading: "Times",
		body: "Arial",
	},
	fontSizes: {
		heading1: "3.8125rem",
		heading2: "3rem",
		heading3: "2rem",
		heading4: "1.5rem",
		heading5: "1.125rem",
		body1: "1rem",
		body2: "0.875rem",
		body3: "0.75rem",
		caption: "0.625rem",
		micro: "0.5rem",
	},
	lineHeights: {
		heading1: "6rem",
		heading2: "4.75rem",
		heading3: "3.25rem",
		heading4: "2.5rem",
		heading5: "2rem",
		body1: "1.75rem",
		body2: "1.25rem",
		body3: "1.125rem",
		caption: "1rem",
		micro: "0.75rem",
	},
	letterSpacings: {
		heading1: "-0.03125rem",
		heading2: "0rem",
		heading3: "0.01875rem",
		heading4: "0.025rem",
		heading5: "0.015625rem",
		body1: "0.015625rem",
		body2: "0.025rem",
		body3: "0.03125rem",
		caption: "0.04375rem",
		micro: "0.05rem",
	},
	components: {
		Button,
		Checkbox,
		Link,
		Input,
		Select,
		Textarea,
		FormLabel,
		Popover,
		FormError,
		Switch,
	},
	textStyles: {
		h1: {
			fontFamily: "Times",
			fontSize: "heading1",
			fontWeight: "heading1",
			lineHeight: "heading1",
			letterSpacing: "heading1",
		},
		h2: {
			fontFamily: "Times",
			fontSize: "heading2",
			fontWeight: "heading2",
			lineHeight: "heading2",
			letterSpacing: "heading2",
		},
		h3: {
			fontFamily: "Times",
			fontSize: "heading3",
			fontWeight: "heading3",
			lineHeight: "heading3",
			letterSpacing: "heading3",
		},
		h4: {
			fontFamily: "Times",
			fontSize: "heading4",
			fontWeight: "heading4",
			lineHeight: "heading4",
			letterSpacing: "heading4",
		},
		h5: {
			fontFamily: "Times",
			fontSize: "heading5",
			fontWeight: "heading5",
			lineHeight: "heading5",
			letterSpacing: "heading5",
		},
		body1: {
			fontFamily: "Arial",
			fontSize: "body1",
			fontWeight: "body1",
			lineHeight: "body1",
			letterSpacing: "body1",
		},
		body2: {
			fontFamily: "Arial",
			fontSize: "body2",
			fontWeight: "body2",
			lineHeight: "body2",
			letterSpacing: "body2",
		},
		body3: {
			fontFamily: "Arial",
			fontSize: "body3",
			fontWeight: "body3",
			lineHeight: "body3",
			letterSpacing: "body3",
		},
		caption: {
			fontFamily: "Arial",
			fontSize: "caption",
			fontWeight: "caption",
			lineHeight: "caption",
			letterSpacing: "caption",
		},
		micro: {
			fontFamily: "Arial",
			fontSize: "micro",
			fontWeight: "micro",
			lineHeight: "micro",
			letterSpacing: "micro",
		},
	},
	shadows: {
		outlinePrimary: "0 0 1px 2px #E61E2899",
	},
	styles: {
		global: () => ({
			"*": {
				boxSizing: "border-box",
				...fontSizeBreakpoints,
			},
			html: {
				height: "100%",
			},
			body: {
				height: "100%",
				fontFamily: "body",
				lineHeight: "body1",
				bg: "gray.50",
				"#root": {
					height: "100%",
					whiteSpace: "pre-line",
				},
			},
			"::-webkit-scrollbar": {
				width: "8px",
			},
			"::-webkit-scrollbar-track": {
				background: "white.200",
			},
			"::-webkit-scrollbar-thumb": {
				background: "gray.200",
				borderRadius: "6px",
			},
			"::-webkit-scrollbar-thumb:hover": {
				background: "gray.100",
			},
		}),
	},
	breakpoints: {
		xs: "0px",
		sm: "415px",
		md: "1024px",
		lg: "1560px",
		xl: "1736px",
	},
});
