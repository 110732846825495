import { ComponentStyleConfig } from "@chakra-ui/react";

import Input, { baseStyle as inputBaseStyle } from "./Input";

export const baseStyle = {
	...inputBaseStyle.field,
};

const componentOverride: ComponentStyleConfig = {
	baseStyle: baseStyle,
	defaultProps: {
		...Input.defaultProps,
	},
};

export default componentOverride;
