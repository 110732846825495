import { ComponentStyleConfig } from "@chakra-ui/react";
import type { SystemStyleObject } from "@chakra-ui/theme-tools";

export const baseStyle: SystemStyleObject = {
	fontFamily: "Arial",
	fontSize: "body3",
	fontWeight: "body3",
	lineHeight: "body3",
	letterSpacing: "body3",
	color: "gray.800",
};

const componentOverride: ComponentStyleConfig = {
	baseStyle: baseStyle,
};

export default componentOverride;
