import React from "react";

import {
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Grid,
	GridItem,
	Text,
} from "@chakra-ui/react";

import { noOfLines } from "helpers/displayWhenPrint";
import { capitalizeFirstLetter } from "helpers/text";

import { RowBody } from "../RowBody";
import { NAE } from "../types";

interface IProps {
	nae: NAE;
	print?: boolean;
}

export const TableRow: React.FC<IProps> = (props) => {
	const { nae, print } = props;

	return (
		<AccordionItem>
			{({ isExpanded }) => (
				<Box style={{ breakInside: "avoid" }}>
					<AccordionButton minHeight="2.75rem" p="0" pl="1rem">
						<Grid templateColumns="repeat(7, 1fr)" gap={5} w="100%" textAlign="left" alignItems="center">
							<GridItem colSpan={2}>
								<Text
									title={capitalizeFirstLetter(nae.categoria)}
									textStyle="body3"
									color="gray.800"
									noOfLines={noOfLines(print)}
								>
									{capitalizeFirstLetter(nae.categoria)}
								</Text>
							</GridItem>
							<GridItem colSpan={2}>
								<Text
									title={capitalizeFirstLetter(nae.subcategoria)}
									textStyle="body3"
									color="gray.800"
									noOfLines={noOfLines(print)}
									pl=".25rem"
								>
									{capitalizeFirstLetter(nae.subcategoria)}
								</Text>
							</GridItem>
							<GridItem colSpan={3}>
								<Text
									title={capitalizeFirstLetter(nae.incumplimiento)}
									textStyle="body3"
									color="gray.800"
									noOfLines={noOfLines(print)}
									pl=".5rem"
								>
									{capitalizeFirstLetter(nae.incumplimiento)}
								</Text>
							</GridItem>
						</Grid>
						{!print && <AccordionIcon color="gray.500" />}
					</AccordionButton>
					<AccordionPanel borderTop="0.5px solid" borderColor="gray.100" padding="1rem 0" backgroundColor="gray.table">
						{isExpanded && <RowBody nae={nae} />}
					</AccordionPanel>
				</Box>
			)}
		</AccordionItem>
	);
};
