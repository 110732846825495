import { Route } from "react-router-dom";

import { Layout } from "common/core/Layout";
import { Portfolio } from "views/portfolio";

export const portfolioWithParams = (
	<Route
		path=":portfolioParams"
		element={
			<Layout>
				<Portfolio />
			</Layout>
		}
	/>
);
