import { createSlice } from "@reduxjs/toolkit";

import { NAE } from "components/ITE/TablaAccesibilidad/types";
import { NEC } from "components/ITE/TablaConservacion/types";
import { NEEContexto, NEE } from "components/ITE/TablaEficiencia/types";
import { INVOutput } from "components/ITE/TablaInventario/types";
import { NCN } from "components/ITE/TablaNormativa/types";
import { ITEListOutput, ITEOutput } from "components/ITE/types/ite";
import { ReportOutput } from "components/ITE/types/report";
import { ApiPaginatedResponse, StatePropWithThunkState, THUNK_STATE } from "config/types";

import { SLICE_NAMES } from "../constant";
import {
	getIteListExtraReducer,
	getIteByIdExtraReducer,
	getNaeListExtraReducer,
	getNcnListExtraReducer,
	getNecListExtraReducer,
	getNeeListExtraReducer,
	putIteValidarExtraReducer,
	putIteRechazarExtraReducer,
	putEditarActivoExtraReducer,
	putValidarActivoExtraReducer,
	putRechazarActivoExtraReducer,
	getDatosInventarioExtraReducer,
	getReportExtraReducer,
} from "./extraReducers";

export interface IteSlice {
	iteList: StatePropWithThunkState<ITEListOutput[]>;
	iteSelected: StatePropWithThunkState<ITEOutput | null>;
	reports: StatePropWithThunkState<ReportOutput[]>;
	inv: StatePropWithThunkState<INVOutput | null>;
	nec: StatePropWithThunkState<NEC[]>;
	nee: StatePropWithThunkState<ApiPaginatedResponse<NEE[]>>;
	neeContexto: StatePropWithThunkState<NEEContexto>;
	nae: StatePropWithThunkState<NAE[]>;
	ncn: StatePropWithThunkState<NCN[]>;
}

const sliceInitialState: IteSlice = {
	iteList: {
		thunkState: THUNK_STATE.IDLE,
		data: [],
	},
	iteSelected: {
		thunkState: THUNK_STATE.IDLE,
		data: null,
	},
	reports: {
		thunkState: THUNK_STATE.IDLE,
		data: [],
	},
	inv: {
		thunkState: THUNK_STATE.IDLE,
		data: null,
	},
	nec: {
		thunkState: THUNK_STATE.IDLE,
		data: [],
	},
	nee: {
		thunkState: THUNK_STATE.IDLE,
		data: {
			data: [],
			pagination: {
				total: -1,
				lastPage: -1,
				perPage: -1,
				currentPage: -1,
				from: -1,
				to: -1,
			},
		},
	},
	neeContexto: {
		thunkState: THUNK_STATE.IDLE,
		data: {},
	},
	nae: {
		thunkState: THUNK_STATE.IDLE,
		data: [],
	},
	ncn: {
		thunkState: THUNK_STATE.IDLE,
		data: [],
	},
};

const customSlice = createSlice({
	name: `${SLICE_NAMES.ITE}`,
	initialState: sliceInitialState,
	reducers: {},
	extraReducers: (builder) => {
		getIteListExtraReducer(builder);
		getIteByIdExtraReducer(builder);
		getReportExtraReducer(builder);
		getDatosInventarioExtraReducer(builder);
		getNaeListExtraReducer(builder);
		getNcnListExtraReducer(builder);
		getNecListExtraReducer(builder);
		getNeeListExtraReducer(builder);
		putIteValidarExtraReducer(builder);
		putIteRechazarExtraReducer(builder);
		putEditarActivoExtraReducer(builder);
		putValidarActivoExtraReducer(builder);
		putRechazarActivoExtraReducer(builder);
	},
});

export const actions = customSlice.actions;
export default customSlice.reducer;
