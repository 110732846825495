import React from "react";

import { Divider, Grid, GridItem } from "@chakra-ui/react";

import HeaderText from "common/components/Header";

export const HeaderLevantamientoDigital: React.FC = () => {
	return (
		<React.Fragment>
			<Grid templateColumns="repeat(8, 1fr)" gap={1} w="100%" mb=".25rem" pr="1.5rem">
				<GridItem colStart={1} ml="1rem">
					<HeaderText label="Archivos" />
				</GridItem>
				<GridItem colStart={5} justifySelf="flex-start">
					<HeaderText label="Estado" />
				</GridItem>
			</Grid>
			<Divider w="97%" ml="1rem" />
		</React.Fragment>
	);
};
