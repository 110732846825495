import React from "react";

import { Box, Flex, Icon, SimpleGrid, Text } from "@chakra-ui/react";
import { FiAlertTriangle } from "react-icons/fi";

import { LocalAssetData } from "components/ITE/TablaInventario/types";
import { defaultValuesJefaturas, defaultValuesTipologias, defaultValuesUsos } from "components/Portfolio/defaultValues";
import { ActivoCompletoOutput } from "components/Portfolio/types/activos";
import { getCodigoDeActivoPorTipologia } from "helpers/codigoDeActivo";
import { geojsonToUTMString } from "helpers/utmCoordinates";

import { getRelevanciaByUsos } from "../AssetModal/relevanciaPorUso";
import { InfoLine } from "../InfoLine";

interface IProps {
	asset: ActivoCompletoOutput | LocalAssetData;
	title: string;
	porValidar?: boolean;
	referencia?: LocalAssetData;
}

export const AssetInfo: React.FC<IProps> = (props) => {
	const { asset, title, porValidar, referencia } = props;

	const isModified = (key: keyof LocalAssetData) => {
		// return true;
		if (referencia === undefined) return false;
		if (typeof asset[key] !== "object" && referencia[key] != asset[key]) return true;
		if (JSON.stringify(referencia[key]) !== JSON.stringify(asset[key])) {
			console.log(referencia[key], asset[key]);
			return true;
		}
		return false;
	};

	return (
		<Box>
			<Text textStyle="body3" color="#006935" data-testid="assetStateLabel">
				{title}
			</Text>
			<SimpleGrid columns={2} gap="1rem" gridGap="1rem">
				<Box>
					<InfoLine
						label="Nombre del activo:"
						value={asset.nombre}
						highlight={isModified("nombre") ? "yellow.100" : undefined}
					/>
					<InfoLine
						label="Dirección:"
						value={asset.direccion}
						highlight={isModified("direccion") ? "yellow.100" : undefined}
					/>
					<InfoLine
						label="Municipio:"
						value={asset.municipio}
						highlight={isModified("municipio") ? "yellow.100" : undefined}
					/>
					<InfoLine
						label="Provincia:"
						value={asset.provincia}
						highlight={isModified("provincia") ? "yellow.100" : undefined}
					/>
					<InfoLine
						label="Coordenadas UTM:"
						value={geojsonToUTMString(asset.geometria)}
						highlight={isModified("geometria") ? "yellow.100" : undefined}
					/>
					<InfoLine
						label="Subdirección / Gerencia:"
						value={asset.ambito}
						highlight={isModified("ambito") ? "yellow.100" : undefined}
					/>
					<InfoLine
						label="Jefatura:"
						value={defaultValuesJefaturas.find(({ value }) => value === asset.jefatura)?.label ?? asset.jefatura}
						highlight={isModified("jefatura") ? "yellow.100" : undefined}
					/>
					<InfoLine
						label="Agrupación de Edificios:"
						value={asset.agrupacion}
						highlight={isModified("agrupacion") ? "yellow.100" : undefined}
					/>
					<InfoLine
						label="Linea Asignada:"
						value={asset.via}
						highlight={isModified("via") ? "yellow.100" : undefined}
					/>
					<InfoLine label="PK de Inicio:" value={asset.pk} highlight={isModified("pk") ? "yellow.100" : undefined} />
					<InfoLine
						label="Código de Estación:"
						value={asset.codigo_estacion}
						highlight={isModified("codigo_estacion") ? "yellow.100" : undefined}
					/>
				</Box>
				<Box>
					<InfoLine
						label="Principal Tipología Edificatoria:"
						value={defaultValuesTipologias.find(({ value }) => value === asset.tipologia)?.label ?? asset.tipologia}
						highlight={
							isModified("tipologia")
								? asset.tipologia === "Otro" && asset.uso_principal === "Otro"
									? "red.100"
									: "yellow.100"
								: undefined
						}
					/>
					<InfoLine
						label="Uso Principal:"
						value={defaultValuesUsos.find((it) => it.value === asset.uso_principal)?.label ?? asset.uso_principal}
						highlight={
							isModified("usos")
								? asset.tipologia === "Otro" && asset.uso_principal === "Otro"
									? "red.100"
									: "yellow.100"
								: undefined
						}
					/>
					<InfoLine
						label="Usos Complementarios:"
						value={asset.usos?.map((uso) => defaultValuesUsos.find((it) => it.value === uso)?.label ?? uso) ?? []}
						highlight={isModified("usos") ? "yellow.100" : undefined}
					/>
					<InfoLine
						label="Relevancia:"
						value={getRelevanciaByUsos([asset.uso_principal ?? "", ...(asset.usos ?? [])], asset.tipologia ?? "")}
						highlight={
							referencia !== undefined &&
							getRelevanciaByUsos([asset.uso_principal ?? "", ...(asset.usos ?? [])], asset.tipologia ?? "") !=
								getRelevanciaByUsos(
									[referencia?.uso_principal ?? "", ...(referencia?.usos ?? [])],
									referencia?.tipologia ?? "",
								)
								? "yellow.100"
								: undefined
						}
					/>
					<InfoLine
						label="Especialidades:"
						value={asset.especialidades}
						highlight={isModified("especialidades") ? "yellow.100" : undefined}
					/>
					<InfoLine
						label="Año de Construcción:"
						value={asset.ano_construccion}
						highlight={isModified("ano_construccion") ? "yellow.100" : undefined}
					/>
					<InfoLine
						label="Superficie (m²):"
						value={asset.superficie}
						highlight={isModified("superficie") ? "yellow.100" : undefined}
					/>
					<InfoLine
						label="Cuenta con Personal Permanente:"
						value={asset.personal}
						highlight={isModified("personal") ? "yellow.100" : undefined}
					/>
					<InfoLine
						label="Numero de plantas Sobre Rasante:"
						value={asset.numero_de_plantas_sobre_rasante}
						highlight={isModified("numero_de_plantas_sobre_rasante") ? "yellow.100" : undefined}
					/>
					<InfoLine
						label="Numero de plantas Bajo Rasante:"
						value={asset.numero_de_plantas_bajo_rasante}
						highlight={isModified("numero_de_plantas_bajo_rasante") ? "yellow.100" : undefined}
					/>
					<InfoLine
						label="Código ACC/ RIH Tipo Edificio"
						value={asset.tipologia ? getCodigoDeActivoPorTipologia(asset.tipologia) : undefined}
						highlight={isModified("tipologia") ? "yellow.100" : undefined}
					/>
				</Box>
				{porValidar && (
					<Flex
						alignItems="center"
						mt="1rem"
						gridColumn="span 2"
						pl={{ xs: "0", sm: "1rem" }}
						gap="0.75rem"
						fontSize="1.25rem"
					>
						<Icon as={FiAlertTriangle} fontSize="1.5rem" color="red.500" display={{ xs: "none", sm: "block" }} />
						<Text textStyle="body2">Activo pendiente de aprobación</Text>
					</Flex>
				)}
			</SimpleGrid>
		</Box>
	);
};
