import React from "react";

import { Tag, TagCloseButton, TagLabel } from "@chakra-ui/react";

import { actions as portfolioActions } from "store/portfolio/reducer";
import { useAppDispatch, useAppSelector } from "store/store";

interface IProps {
	column: string;
	labelTrue: string;
	labelFalse: string;
	value: boolean | undefined;
}

export const BooleanFilterTag: React.FC<IProps> = (props) => {
	const { column, labelTrue, labelFalse, value } = props;
	const filterSelection = useAppSelector((state) => state.portfolio.filterSelection);

	const dispatch = useAppDispatch();

	const handleClose = () => {
		const newFilterSelection = { ...filterSelection };
		delete newFilterSelection[column];
		dispatch(portfolioActions.setFilterSelection(newFilterSelection));
	};

	return (
		<Tag size="sm" borderRadius="md" variant="solid" bgColor="gray.200" ml=".5rem">
			<TagLabel fontSize="body3" color="gray.600" m=".25rem">
				{value ? labelTrue : labelFalse}
			</TagLabel>
			<TagCloseButton color="gray.600" onClick={() => handleClose()} />
		</Tag>
	);
};
