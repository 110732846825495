import { Route } from "react-router-dom";

import { Layout } from "common/core/Layout";
import { AssetView } from "views/asset";

export const assetsView = (
	<Route
		index
		element={
			<Layout>
				<AssetView />
			</Layout>
		}
	/>
);
