import { FieldInputProps, FieldProps } from "formik";
import Select, { Options, PropsValue, StylesConfig } from "react-select";

interface Option {
	label: string;
	value: string;
}

interface FormikSelectProps extends FieldProps {
	options: Options<Option>;
	isMulti?: boolean;
	styles?: StylesConfig<Option | string>;
}

export const FormikSelect = ({ field, form, options, styles, isMulti = false }: FormikSelectProps) => {
	const onChange = (option: PropsValue<string | Option>) => {
		form.setFieldValue(
			field.name,
			isMulti ? (option as Option[]).map((item: Option) => item.value) : (option as Option).value,
		);
	};

	const getValue = (field: FieldInputProps<string | undefined>) => {
		if (!field.value) return "";
		const optionValues = options.map((it) => it.value);
		if (field.value && !optionValues.includes(field.value)) return "";
		return options.find((opt) => opt.value === field.value) || "";
	};

	const getMultiValue = (field: FieldInputProps<string[] | undefined>) => {
		return (field.value || [])
			.map((fieldVal) => options.find((opt) => fieldVal === opt.value))
			.filter((opt): opt is Option => opt !== undefined);
	};

	return (
		<Select
			name={field.name}
			value={isMulti ? getMultiValue(field) : getValue(field)}
			onChange={onChange}
			options={options}
			isMulti={isMulti}
			styles={styles}
		/>
	);
};
