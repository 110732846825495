import React from "react";

import {
	Button,
	Divider,
	Flex,
	Grid,
	GridItem,
	Icon,
	IconButton,
	Input,
	Modal as ChakraModal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	useToast,
} from "@chakra-ui/react";
import { FiAlertTriangle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { postDuplicarActivo } from "api/portfolio";
import HeaderText from "common/components/Header";
import { ActivoCompletoOutput } from "components/Portfolio/types/activos";

interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
	asset: ActivoCompletoOutput | null;
}

export const DuplicateAssetModal: React.FC<ModalProps> = (props) => {
	const { isOpen, onClose, asset } = props;

	const [nombre, setNombre] = React.useState<string | undefined>(undefined);
	const toast = useToast();
	const notReady = !nombre || nombre == "";
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const navigate = useNavigate();

	const saveNombre = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setNombre(value);
	};

	const handleSubmit = async () => {
		const assetId = asset?.id;
		setIsLoading(true);
		try {
			if (assetId && nombre) {
				const res = await postDuplicarActivo({ assetId, nombre });
				toast({
					title: "Respuesta enviada",
					status: "success",
					duration: 3000,
					isClosable: true,
				});
				onClose();
				navigate(`/activo/${res.data.id}/ite/${res.data.ite_id}`);
			}
		} catch (error) {
			toast({
				title: "Error al enviar respuesta",
				status: "error",
				duration: 3000,
				isClosable: true,
			});
		}
		setIsLoading(false);
	};

	React.useEffect(() => {
		setNombre(asset ? asset.nombre + " - Copia" : undefined);
	}, [isOpen]);

	return (
		<ChakraModal isOpen={isOpen} onClose={onClose} size="2xl">
			{asset && (
				<React.Fragment>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader color="gray.800" textStyle="body2" pt="2rem">
							<Grid templateColumns="repeat(8, 3fr)" w="100%" alignItems={"center"}>
								<GridItem gridColumn={"1/2"} gridRow={"1/2"} pl=".5rem">
									<IconButton aria-label="back" aria-hidden="true" variant="icon-only" height="2rem" width="2rem">
										<Icon as={FiAlertTriangle} fontSize="3rem" color="red.500" strokeWidth={"1"} />
									</IconButton>
								</GridItem>
								<GridItem gridColumn={"2/8"} gridRow={"1/2"}>
									<Text textStyle="body1" color="gray.800" fontWeight="bold">
										Duplicar activo
									</Text>
								</GridItem>
							</Grid>
							<Divider w="98%" alignSelf={"center"} mt="1.25rem" />
						</ModalHeader>
						<ModalCloseButton onClick={() => onClose()} color="gray.500" />
						<ModalBody mb="2rem">
							<Grid templateColumns="repeat(8, 3fr)" w="100%" alignItems={"baseline"}>
								<GridItem gridColumn={"1/4"} gridRow={"1/2"}>
									<Text textStyle="body3" color="gray.600" lineHeight="1.25rem" mb="1rem">
										Genera una copia completa del activo
									</Text>
								</GridItem>
								<GridItem gridColumn={"5/9"} gridRow={"1/3"}>
									<Input value={nombre} onChange={saveNombre} isDisabled={isLoading} />
									<Flex mt=".5rem">
										<Text textStyle="body3" color="red.500">
											*
										</Text>
										<HeaderText label="Nombre Requerido" textProps={{ ml: ".25rem" }} />
									</Flex>
								</GridItem>
								<GridItem gridColumn={"1/9"} gridRow={"3/4"} pt="1.5rem">
									<Divider w="98%" alignSelf={"center"} mb="1.5rem" />
									<Flex direction="row" justifyContent="space-between">
										<Button
											type="submit"
											bg="gray.400"
											size="md"
											borderRadius="5px"
											h="2rem"
											w="20%"
											onClick={onClose}
											_hover={{ bg: "gray.500" }}
											isDisabled={isLoading}
										>
											<Text textStyle="body3" color="white">
												Cancelar
											</Text>
										</Button>
										<Button
											isDisabled={notReady ? true : false}
											type="submit"
											bg={"green.500"}
											size="md"
											ml="1rem"
											borderRadius="5px"
											mr=".5rem"
											h="2rem"
											w="20%"
											onClick={() => handleSubmit()}
											isLoading={isLoading}
										>
											<Text textStyle="body3" color="white">
												Duplicar
											</Text>
										</Button>
									</Flex>
								</GridItem>
							</Grid>
						</ModalBody>
					</ModalContent>
				</React.Fragment>
			)}
		</ChakraModal>
	);
};
