import { DateTime } from "luxon";

import { useAppSelector } from "store/store";

import { ITEListOutput } from "../types/ite";
import { fechaPrimerEvento, fechaUltimoEvento, getFechaVisita } from "../utils/fechasIte";

export const useReprogramacionITEs = () => {
	const iteList: ITEListOutput[] = useAppSelector((state) => state.ites.iteList.data);
	const iteSelected = useAppSelector((state) => state.ites.iteSelected.data);
	const features = useAppSelector((state) => state.user.features);

	const now = DateTime.now();
	const currentDate = iteSelected?.ite ? getFechaVisita(iteSelected?.ite) : undefined;

	const prevITEs = [...iteList]
		// La ITE anterior tiene que empezar en el pasado respecto a la ITE que estamos reprogramando
		.filter((it) => fechaUltimoEvento(it) < (currentDate || now))
		// Y de entre esas ITEs que estén en el pasado, quiero encontrar la última
		.sort((prev, next) => fechaUltimoEvento(next).toSeconds() - fechaUltimoEvento(prev).toSeconds());

	const nextITEs = [...iteList]
		// La proxima ITE tiene que empezar en el futuro (y no deberia estar registrada)
		.filter((it) => !it.fecha_registro && fechaPrimerEvento(it) > (currentDate || now))
		// Y de entre esas ITEs que estén en el futuro, quiero encontrar la primera (aunque deberia haber solo una)
		.sort((prev, next) => fechaPrimerEvento(prev).toSeconds() - fechaPrimerEvento(next).toSeconds());

	const prevITE = prevITEs[0];
	const nextITE = nextITEs[0];
	const minDate = prevITE ? fechaUltimoEvento(prevITE).plus({ days: 1 }) : now;
	const maxDate = nextITE ? fechaPrimerEvento(nextITE).minus({ years: 1 }) : undefined;

	const allowsRescheduling =
		features.data.includes("reschedule-ite") &&
		["En proceso", "Programada"].includes(iteSelected?.ite.estado || "") &&
		(!maxDate || maxDate > minDate);

	return {
		allowsRescheduling,
		minDate: minDate > now ? minDate : now,
		maxDate,
		defaultDate: currentDate || DateTime.now(),
	};
};
