import React from "react";

import { Box, Grid, GridItem, Text } from "@chakra-ui/react";

import { InfoLine } from "common/components/InfoLine";
import { useAppSelector } from "store/store";

const mapEnergyType = (original?: string) => {
	if (original === "tipoEnergiaElectricidad") {
		return "Electricidad";
	} else if (original === "tipoEnergiaCarbon") {
		return "Carbon";
	} else if (original === "tipoEnergiaGasoleoC") {
		return "Gasóleo C";
	} else if (original === "tipoEnergiaGLP") {
		return "GLP";
	} else if (original === "tipoEnergiaGasnatural") {
		return "Gas Natural";
	} else if (original === "tipoEnergiaOtroP") {
		return "Otro";
	}
	return "";
};

export const MiddleSectionInfo: React.FC = () => {
	const neeContexto = useAppSelector((state) => state.ites.neeContexto.data);

	return (
		<React.Fragment>
			{neeContexto && (
				<Box m="1rem" mb=".5rem">
					<Grid templateColumns="repeat(2, 1fr)" gap={6}>
						<GridItem colSpan={1}>
							<Text textStyle="body3" color="green.600" mb=".25rem">
								Características energéticas del edificio
							</Text>
							<InfoLine label="Tipo edificio:" value={neeContexto.tipo_edificio} />
							<InfoLine
								label="Tipo energía utilizada:"
								value={neeContexto.tipo_energia?.map(mapEnergyType).join(", ") || ""}
							/>
							<Text textStyle="body3" color="green.600" mt=".5rem">
								Emisiones de CO<Text as="sub">2</Text>
							</Text>
							<InfoLine label="Emisiones totales:" value={neeContexto.total_co2} suffix={"kgCO2"} />
						</GridItem>
						<GridItem colSpan={1}>
							<Text textStyle="body3" color="green.600" mb=".25rem">
								Consumo energético
							</Text>
							<InfoLine label="Medio para la obtención:" value={neeContexto.tipo_medicion_consumo} />
							<InfoLine
								label="Consumo Energético Total:"
								value={neeContexto.consumo_energetico_kwh}
								suffix={"kWh/año"}
							/>
							<InfoLine label="Consumo por superficie:" value={neeContexto.consumo_por_m2} suffix={"kWh/año m2"} />
						</GridItem>
					</Grid>
				</Box>
			)}
		</React.Fragment>
	);
};
