import React from "react";
import "./Styles.css";

import { Box, Divider, Grid, Text, GridItem, Image, Flex } from "@chakra-ui/react";

import HeaderText from "common/components/Header";
import { textofValue } from "components/ITE/TablaConservacion/RowBody";
import { ActivoCompletoOutput } from "components/Portfolio/types/activos";
import { sistemaLabel } from "helpers/tableLabels";
import { capitalizeFirstLetter } from "helpers/text";
import { useAppSelector } from "store/store";

import { thumbnailUrlFromFilepath } from "../Carousel";
import IndiceIndicator from "../IndiceIndicator";
import { InfoLine } from "../InfoLine";

interface IProps {
	asset: ActivoCompletoOutput;
}

export const EstadoDeConservacionPdf: React.FC<IProps> = (props) => {
	const { asset } = props;

	const nec = useAppSelector((state) => state.ites.nec.data);

	return (
		<Box className="item" p="1rem">
			{nec.map((necItem) => (
				<Box key={necItem.id} style={{ breakInside: "avoid", breakAfter: "always", breakBefore: "always" }}>
					<Box px="1rem">
						<Text textStyle="body2" color="gray.600">
							{asset.nombre}
						</Text>
						<Text textStyle="body2" as="span">
							ITE. Estado de Conservacion:
						</Text>
						<Text textStyle="body2" fontWeight="bold" as="span" ml=".25rem">
							Deficiencias
						</Text>
					</Box>
					<Box mt="1.25rem" px="1rem">
						<Text textStyle="body3" fontWeight="bold">
							A. Caracterización de la deficiencia
						</Text>
						<Grid templateColumns="repeat(3, 1fr)" gap={6} m=".5rem 0">
							<GridItem colSpan={1}>
								<Text textStyle="body3" color="gray.600">
									Sistema
								</Text>
							</GridItem>
							<GridItem colSpan={1}>
								<Text textStyle="body3" color="gray.600">
									Defecto
								</Text>
							</GridItem>
							<GridItem colSpan={1}>
								<Text textStyle="body3" color="gray.600">
									Elemento
								</Text>
							</GridItem>
						</Grid>
						<Divider alignSelf="center" width="99%" borderColor="gray.800" mb=".5rem" />
						<Grid templateColumns="repeat(3, 1fr)" gap={6}>
							<GridItem colSpan={1}>
								<Text textStyle="body3">{sistemaLabel(necItem.sistema)}</Text>
							</GridItem>
							<GridItem colSpan={1}>
								<Text textStyle="body3">{necItem.defecto}</Text>
							</GridItem>
							<GridItem colSpan={1}>
								<Text textStyle="body3">{necItem.elemento}</Text>
							</GridItem>
						</Grid>
						<Divider alignSelf="center" width="99%" borderColor="gray.800" mt=".5rem" />
						<Box backgroundColor="#f7f7f7" p="0 1rem">
							<Grid templateColumns="repeat(1, 1fr)" templateRows="repeat(1, 1fr)" gap={1}>
								<GridItem colSpan={1} rowSpan={1}>
									<InfoLine
										label="Otros sistemas afectados:"
										value={necItem.otros_sistemas_afectados
											.map((sistema) => capitalizeFirstLetter(sistemaLabel(sistema)))
											.join(", ")}
									/>
								</GridItem>
								<GridItem colSpan={1} rowSpan={1}>
									<InfoLine label="Posible Causa:" value={necItem.causa} />
								</GridItem>
								<GridItem colSpan={1} rowSpan={1}>
									<InfoLine label="Intensidad del daño:" value={textofValue(necItem.intensidad)} />
								</GridItem>
								<GridItem colSpan={1} rowSpan={1}>
									<InfoLine label="Ubicación del daño:" value={necItem.ubicacion} />
								</GridItem>
								<GridItem colSpan={1} rowSpan={1}>
									<Flex alignItems="center" gap=".5rem">
										<HeaderText label="Índice:" />
										{necItem.indice && <IndiceIndicator indice={necItem.indice} />}
									</Flex>
								</GridItem>
								<GridItem colSpan={1} rowSpan={1}>
									<InfoLine label="Influencia:" value={textofValue(necItem.influencia)} />
								</GridItem>
							</Grid>
						</Box>
						<Divider alignSelf="center" width="99%" borderColor="gray.800" />
					</Box>
					<Box mt="1rem" px="1rem">
						<Text textStyle="body3" fontWeight="bold" mb=".5rem">
							B. Acciones propuestas sobre la deficiencia
						</Text>
						<Divider alignSelf="center" width="99%" borderColor="gray.800" />
						<Box backgroundColor="#f7f7f7" p="0 1rem">
							<Grid templateColumns="repeat(1, 1fr)" templateRows="repeat(1, 1fr)" gap={1}>
								<GridItem colSpan={1} rowSpan={1}>
									<InfoLine label="Acciones de seguimiento asociadas:" value={necItem.acciones_seguimiento} />
								</GridItem>
								<GridItem colSpan={1} rowSpan={1}>
									<InfoLine label="Propuesta de reparación:" value={necItem.propuesta_reparacion} />
								</GridItem>
								<GridItem colSpan={1} rowSpan={1}>
									<InfoLine label="Prioridad de la reparación propuesta:" value={necItem.prioridad_reparacion} />
								</GridItem>
								<GridItem colSpan={1} rowSpan={1}>
									{necItem.observacion && <InfoLine label="Observaciones:" value={necItem.observacion} />}
								</GridItem>
							</Grid>
						</Box>
						<Divider alignSelf="center" width="99%" borderColor="gray.800" />
					</Box>
					{necItem.fotos.length > 0 && (
						<Box mt="1.25rem" px="1rem">
							<Text textStyle="body3" fontWeight="bold">
								C. Imágenes
							</Text>
							<Box display="block" position="relative" width="100%">
								{necItem.fotos.map((foto, index) => (
									<Image
										key={index}
										src={thumbnailUrlFromFilepath(foto.filepath)}
										objectFit="scale-down"
										maxH="300px"
										maxW="calc(33% - 1rem)"
										display="inline-block"
										marginInline=".25rem"
										sx={{ breakInside: "avoid" }}
									/>
								))}
							</Box>
						</Box>
					)}
				</Box>
			))}
		</Box>
	);
};
