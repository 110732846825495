import React, { useState } from "react";

import { Flex, IconButton, Icon, Box } from "@chakra-ui/react";
import { BsCloudDownload } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";

import AssetModal from "common/components/AssetModal";
import DownloadExcelModal from "common/components/DownloadExcelModal";
import { useAppDispatch, useAppSelector } from "store/store";
import { getUserFeaturesThunk } from "store/user/thunks";

import { TableViewButtons } from "./TablesViewButtons";

export const MiddleSection: React.FC = () => {
	const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	const [isDownloadExcelModalOpen, setIsDownloadExcelModalOpen] = useState(false);

	const features = useAppSelector((state) => state.user.features);
	const dispatch = useAppDispatch();

	React.useEffect(() => {
		dispatch(getUserFeaturesThunk());
	}, []);

	return (
		<React.Fragment>
			<Flex justifyContent="space-between" pr="1rem" alignItems="center">
				<TableViewButtons />
				<Box pt=".5rem">
					{features.data.includes("download-excel") && (
						<IconButton
							aria-label="add"
							marginRight="1rem"
							w="2.5rem"
							h="2.5rem"
							borderRadius="100%"
							minW="unset"
							maxH="unset"
							maxW="unset"
							onClick={() => {
								setIsDownloadExcelModalOpen(true);
							}}
						>
							<Icon as={BsCloudDownload} fontSize="1rem" color="gray.500" />
						</IconButton>
					)}
					{features.data.includes("create-asset") && (
						<IconButton
							aria-label="add"
							w="2.5rem"
							h="2.5rem"
							borderRadius="100%"
							minW="unset"
							maxH="unset"
							maxW="unset"
							onClick={() => {
								setIsAddModalOpen(true);
							}}
						>
							<Icon as={FiPlus} fontSize="1.5rem" color="gray.500" strokeWidth="1" />
						</IconButton>
					)}
				</Box>
			</Flex>
			<AssetModal
				isOpen={isAddModalOpen}
				onClose={() => {
					setIsAddModalOpen(false);
				}}
			/>
			<DownloadExcelModal
				isOpen={isDownloadExcelModalOpen}
				onClose={() => {
					setIsDownloadExcelModalOpen(false);
				}}
			/>
		</React.Fragment>
	);
};
