const customGreenStyleTrack = () => {
	return {
		bg: "gray.400",
		_disabled: {
			bg: "gray.300",
		},
		_checked: {
			bg: "green.400",
		},
	};
};

const variantCustomGreen = {
	track: customGreenStyleTrack(),
};

export default {
	variants: {
		customGreen: variantCustomGreen,
	},
};
