import { fromLatLon } from "utm";

export const geojsonToUTMString = (geometry?: {
	bbox: number[];
	type: string;
	coordinates: [number, number];
	[extra: string]: unknown;
}): string | undefined => {
	if (!geometry) return undefined;
	try {
		// location is given as a geojson geometry string
		const utm = fromLatLon(geometry.coordinates[1], geometry.coordinates[0], 30);
		return `${utm.zoneNum}${utm.zoneLetter} ${utm.easting.toFixed(0)} ${utm.northing.toFixed(0)}`;
	} catch (err) {
		console.error("Error parsing coordinates to UTM");
		console.error(err);
	}
	return undefined;
};
