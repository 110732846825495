import React from "react";

import { Grid, GridItem } from "@chakra-ui/react";

import HeaderText from "common/components/Header";

export const TableHeader: React.FC = () => {
	return (
		<React.Fragment>
			<Grid templateColumns="repeat(7, 1fr)" w="100%" mb=".25rem" pl="1rem" textAlign="left">
				<GridItem colSpan={2}>
					<HeaderText label="Categoría de mejora" />
				</GridItem>
				<GridItem colSpan={2}>
					<HeaderText label="Tipo de mejora" />
				</GridItem>
				<GridItem colSpan={2}>
					<HeaderText label="Periodo de retorno" />
				</GridItem>
				<GridItem colSpan={1} colStart={7}>
					<HeaderText label="Índice" />
				</GridItem>
			</Grid>
		</React.Fragment>
	);
};
