import { Route } from "react-router-dom";

import { Layout } from "common/core/Layout";
import { Portfolio } from "views/portfolio";

export const portfolioRoute = (
	<Route
		index
		element={
			<Layout>
				<Portfolio />
			</Layout>
		}
	/>
);
