import React from "react";

import { Box, Divider } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { Inspeccion } from "components/Portfolio/Lista/Inspeccion";
import { Localizacion } from "components/Portfolio/Lista/Localizacion";
import { Tipologia } from "components/Portfolio/Lista/Tipologia";
import { getIteListThunk } from "store/ITE/thunks";
import { getInfoActivosThunk, getPortfolioStatsThunk } from "store/portfolio/thunks";
import { useAppDispatch, useAppSelector } from "store/store";

import { Accesibilidad } from "./Accesibilidad";
import { Certificacion } from "./Certificacion";
import { FiltersApplied } from "./components/FiltersApplied";
import { MiddleSection } from "./components/MiddleSection";
import { Conservacion } from "./Conservacion";
import { Energetica } from "./Energetica";
import { Normativa } from "./Normativa";

export const PortfolioList: React.FC = () => {
	const { portfolioParams } = useParams();
	const filterSelection = useAppSelector((state) => state.portfolio.filterSelection);
	const orderBy = useAppSelector((state) => state.portfolio.orderBy);
	const page = useAppSelector((state) => state.portfolio.page);

	const dispatch = useAppDispatch();

	const RenderTable = () => {
		const getView = (portfolioParams: string | undefined) => {
			switch (portfolioParams) {
				case "inspeccion":
					return <Inspeccion />;
				case "localizacion":
					return <Localizacion />;
				case "tipologia":
					return <Tipologia />;
				case "nec":
					return <Conservacion />;
				case "nee":
					return <Energetica />;
				case "nae":
					return <Accesibilidad />;
				case "ncn":
					return <Normativa />;
				case "certificacion":
					return <Certificacion />;
				default:
					return <Inspeccion />;
			}
		};
		return getView(portfolioParams);
	};

	React.useEffect(() => {
		if (filterSelection !== null && orderBy === null) {
			dispatch(getInfoActivosThunk({ filterSelection, page }));
			dispatch(getPortfolioStatsThunk({ filterSelection }));
		}
		if (filterSelection !== null && orderBy !== null) {
			dispatch(getInfoActivosThunk({ filterSelection, order: `${orderBy.column}.${orderBy.direction}`, page }));
			dispatch(getPortfolioStatsThunk({ filterSelection }));
		}
		if (orderBy !== null && filterSelection === null) {
			dispatch(getInfoActivosThunk({ order: `${orderBy.column}.${orderBy.direction}`, page }));
			dispatch(getPortfolioStatsThunk({}));
		}
		if (orderBy === null && filterSelection === null) {
			dispatch(getInfoActivosThunk({ page }));
			dispatch(getPortfolioStatsThunk({}));
		}
	}, [filterSelection, orderBy, page]);

	React.useEffect(() => {
		dispatch(getIteListThunk({}));
	}, []);

	return (
		<Box mt="1.5rem">
			<MiddleSection />
			<FiltersApplied />
			<Divider mb="1.5rem" />
			<RenderTable />
		</Box>
	);
};
