import { apiClient } from "api";

export interface SendEmailProps {
	iteId: string;
	to: string[];
	subject: string;
	message: string;
}
export const sendEmail = async (props: SendEmailProps) => {
	const { iteId, to, subject, message } = props;
	await apiClient.post(`/ite/email/${iteId}`, { to, subject, message }).then((res) => res.data);
};
