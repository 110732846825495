import React from "react";

import { Box, Button, Divider, Flex } from "@chakra-ui/react";
import { Popup } from "react-map-gl";

type MapboxPopupProps = {
	latitude: number;
	longitude: number;
	name: string;
	location: string;
	onClick: () => void;
	closeHandle: () => void;
};

export const MapboxPopup: React.FC<MapboxPopupProps> = (props) => {
	return (
		<Popup latitude={props.latitude} longitude={props.longitude} anchor="bottom" closeButton={false} maxWidth="auto">
			<Box margin="2rem" width="15rem">
				<Flex flexDirection="row" alignItems="center" marginBottom="1rem" justifyContent="space-between">
					<Flex flexDirection="row" alignItems="center" gap="1rem" fontSize="1.5rem" fontFamily="serif">
						{props.name}
					</Flex>
				</Flex>
				<Divider marginTop="1rem" marginBottom="0.5rem" />
				<Box fontSize="1rem">{props.location}</Box>
				<Button
					minWidth="100%"
					variant="solid"
					marginTop="1rem"
					onClick={() => {
						props.closeHandle();
						props.onClick();
					}}
				>
					Ver más
				</Button>
			</Box>
		</Popup>
	);
};
