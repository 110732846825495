import { createAsyncThunk } from "@reduxjs/toolkit";

import {
	getDatosInventario,
	GetInvQueryProps,
	getIteById,
	getIteList,
	getNaeList,
	GetNaeQueryProps,
	getNcnList,
	GetNcnQueryProps,
	getNecList,
	GetNecQueryProps,
	getNeeList,
	getReportsByITEId,
	postPublicarIteBasica,
	postPublicarIteSegunEstado,
	putIteRechazar,
	putIteReprogramar,
	putIteValidar,
} from "api/ite";
import { NEC } from "components/ITE/TablaConservacion/types";
import { NEE } from "components/ITE/TablaEficiencia/types";
import { NCN } from "components/ITE/TablaNormativa/types";
import { SLICE_NAMES } from "store/constant";

// GET Ite/Ite
export type GetIteListQueryParams = {
	assetId?: string;
	page?: number;
	order?: string;
};

export const getIteListThunk = createAsyncThunk(
	`${SLICE_NAMES.ITE}/getIteList`,
	async (payload: GetIteListQueryParams) => {
		const response = await getIteList(payload);
		return response.data.data;
	},
);

// GET Ite/Ite/${Id}
type GetIteIdPropsType = {
	id: string;
};

export const getIteByIdThunk = createAsyncThunk(`${SLICE_NAMES.ITE}/getIteById`, async (payload: GetIteIdPropsType) => {
	const response = await getIteById(payload);
	response.data.ite.nae.indice = `NAE${response.data.ite.nae.indice}`;
	response.data.ite.ncn.indice = `NCNE${response.data.ite.ncn.indice}`;
	response.data.ite.nec.indice = `NCE${response.data.ite.nec.indice}`;
	response.data.ite.nee.indice = `NEE${response.data.ite.nee.indice}`;
	if (response.data.ite.estado === "Por validar") {
		response.data.ite.estado = "Entregado";
	}
	if (response.data.ite.estado === "Validado") {
		response.data.ite.estado = "Registrado";
	}
	return response.data;
});

// GET ite/reports/${Id}
type GetReportPropsType = {
	iteId: string;
};
export const getReportsThunk = createAsyncThunk(
	`${SLICE_NAMES.ITE}/getReports`,
	async (payload: GetReportPropsType) => {
		const response = await getReportsByITEId(payload);
		return response.data;
	},
);

// GET IDE/inv/items
export const getDatosInventarioThunk = createAsyncThunk(
	`${SLICE_NAMES.ITE}/getDatosInventario`,
	async (payload: GetInvQueryProps) => {
		const response = await getDatosInventario(payload);
		return response.data;
	},
);

// GET IDE/nec/items
export const getNecListThunk = createAsyncThunk(`${SLICE_NAMES.ITE}/getNecList`, async (payload: GetNecQueryProps) => {
	const response = await getNecList(payload);
	const data = response.data.data;
	data.forEach((item: NEC) => {
		item.indice = `NCSY${item.indice}`;
	});
	return data;
});

// GET IDE/nee/items
export const getNeeListThunk = createAsyncThunk(`${SLICE_NAMES.ITE}/getNeeList`, async (payload: GetNaeQueryProps) => {
	const response = await getNeeList(payload);
	const data = response.data;
	data.items.data.forEach((item: NEE) => {
		item.indice = `NMEE${item.indice}`;
	});
	return data;
});

// GET IDE/nae/items
export const getNaeListThunk = createAsyncThunk(`${SLICE_NAMES.ITE}/getNaeList`, async (payload: GetNaeQueryProps) => {
	const response = await getNaeList(payload);
	return response.data.data;
});

// GET IDE/ncn/items
export const getNcnListThunk = createAsyncThunk(`${SLICE_NAMES.ITE}/getNcnList`, async (payload: GetNcnQueryProps) => {
	const response = await getNcnList(payload);
	const data = response.data.data;
	data.forEach((item: NCN) => {
		item.indice = `NCNSY${item.indice}`;
	});
	return data;
});

// PUT ITE/validar
export type PutIteValidarPropsType = {
	id: string;
	comentario: string;
	fechaNuevaITE?: string;
};

export const putIteValidarThunk = createAsyncThunk(
	`${SLICE_NAMES.ITE}/ite/ite/validar`,
	async (data: PutIteValidarPropsType, thunkApi) => {
		const response = await putIteValidar(data);
		response.data.ite.nae.indice = `NAE${response.data.ite.nae.indice}`;
		response.data.ite.ncn.indice = `NCNE${response.data.ite.ncn.indice}`;
		response.data.ite.nec.indice = `NCE${response.data.ite.nec.indice}`;
		response.data.ite.nee.indice = `NEE${response.data.ite.nee.indice}`;
		await thunkApi.dispatch(
			getIteByIdThunk({
				id: data.id,
			}),
		);
		return response.data;
	},
);

// PUT ITE/rechazar
export type PutIteRechazarPropsType = {
	id: string;
	comentario: string;
};

export const putIteRechazarThunk = createAsyncThunk(
	`${SLICE_NAMES.ITE}/ite/ite/rechazar`,
	async (data: PutIteRechazarPropsType, thunkApi) => {
		const response = await putIteRechazar(data);
		response.data.ite.nae.indice = `NAE${response.data.ite.nae.indice}`;
		response.data.ite.ncn.indice = `NCNE${response.data.ite.ncn.indice}`;
		response.data.ite.nec.indice = `NCE${response.data.ite.nec.indice}`;
		response.data.ite.nee.indice = `NEE${response.data.ite.nee.indice}`;
		await thunkApi.dispatch(
			getIteByIdThunk({
				id: data.id,
			}),
		);
		return response.data;
	},
);

// PUT ITE/reprogramar
export type PutIteReprogramarPropsType = {
	id: string;
	fecha: string;
};

export const putIteReprogramarThunk = createAsyncThunk(
	`${SLICE_NAMES.ITE}/ite/ite/reprogramar`,
	async (data: PutIteReprogramarPropsType, thunkApi) => {
		const response = await putIteReprogramar(data);
		await thunkApi.dispatch(
			getIteByIdThunk({
				id: data.id,
			}),
		);
		return response.data;
	},
);

// POST ITE/publciar
export type PostPublicarIteSegunEstadoPropsType = {
	assetId: string;
	fecha: string;
};

export const postPublicarIteSegunEstadoThunk = createAsyncThunk(
	`${SLICE_NAMES.ITE}/ite/ite/segunEstado`,
	async (data: PostPublicarIteSegunEstadoPropsType, thunkApi) => {
		const response = await postPublicarIteSegunEstado(data);
		await thunkApi.dispatch(
			getIteByIdThunk({
				id: response.data.ite.id,
			}),
		);
		return response.data;
	},
);

export type PostPublicarIteBasicaPropsType = {
	assetId: string;
	fecha: string;
};

export const postPublicarIteBasicaThunk = createAsyncThunk(
	`${SLICE_NAMES.ITE}/ite/ite/basica`,
	async (data: PostPublicarIteBasicaPropsType, thunkApi) => {
		const response = await postPublicarIteBasica(data);
		await thunkApi.dispatch(
			getIteByIdThunk({
				id: response.data.ite.id,
			}),
		);
		return response.data;
	},
);
