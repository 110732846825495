import React from "react";

import { Box, Flex, Spinner } from "@chakra-ui/react";

import { PaginationComponent } from "common/components/Pagination";
import { THUNK_STATE } from "config/types";
import { useAppSelector } from "store/store";

import { EnergeticaHeader } from "./Header";
import { EnergeticaRow } from "./Row";

export const Energetica: React.FC = () => {
	const assetsInfo = useAppSelector((state) => state.portfolio.assetsInfo);
	const page = useAppSelector((state) => state.portfolio.page);

	const ref = React.useRef<HTMLDivElement>(null);

	const autoScrollToTop = React.useCallback(() => {
		ref.current?.scrollIntoView({ behavior: "smooth" });
	}, [page]);

	React.useEffect(() => {
		if (page > 1) {
			autoScrollToTop();
		}
	}, [page]);

	return (
		<Box ref={ref}>
			<EnergeticaHeader />
			<Box overflow="hidden" overflowY="scroll" height="75vh">
				{assetsInfo.thunkState === THUNK_STATE.FULFILLED ? (
					assetsInfo.data.data.map((asset, i) => (
						<React.Fragment key={i}>
							<EnergeticaRow asset={asset} />
						</React.Fragment>
					))
				) : (
					<Flex w="100%" my="2rem" justifyContent="center">
						<Spinner />
					</Flex>
				)}
				<PaginationComponent thunkState={assetsInfo.thunkState} pagination={assetsInfo.data.pagination} />
			</Box>
		</Box>
	);
};
