import React, { useState } from "react";
import "./Styles.css";

import { Box, BoxProps } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { WithApiKeyOrUserAuth } from "common/hoc/WithApiKeyOrUserAuthHOC";
// import { EstadoActivo } from "components/EstadoActivo";
import { IteSection } from "components/ITE";
import { TablaAccesibilidad } from "components/ITE/TablaAccesibilidad";
import { TablaEficiencia } from "components/ITE/TablaEficiencia";
import { TablaInventario } from "components/ITE/TablaInventario";
import { TablaNormativa } from "components/ITE/TablaNormativa";
// import { LevantamientoDigital } from "components/LevantamientoDigital";
import {
	getDatosInventarioThunk,
	getIteByIdThunk,
	getNaeListThunk,
	getNcnListThunk,
	getNecListThunk,
	getNeeListThunk,
} from "store/ITE/thunks";
import { useAppDispatch, useAppSelector } from "store/store";

import CookieProvider from "../CookieProvider";
import { Cover } from "./common/Cover";
import { EstadoDeConservacionPdf } from "./EstadoConservacion";

export const Print: React.FC = () => {
	const { assetId, iteId } = useParams();

	const dispatch = useAppDispatch();
	const asset = useAppSelector((state) => state.ites.iteSelected.data?.asset);
	const [isCookieSet, setIsCookieSet] = useState(false);

	React.useEffect(() => {
		if (!iteId || !assetId) return;
		dispatch(getIteByIdThunk({ id: iteId })).then(() => console.log("Queried ite"));
		dispatch(getDatosInventarioThunk({ iteId })).then(() => console.log("Queried inventario"));
		dispatch(getNecListThunk({ iteId, order: "indice.desc" })).then(() => console.log("Queried nec"));
		dispatch(getNaeListThunk({ iteId })).then(() => console.log("Queried nae"));
		dispatch(getNcnListThunk({ iteId, order: "indice.desc" })).then(() => console.log("Queried ncn"));
		dispatch(getNeeListThunk({ iteId, order: "indice.desc" })).then(() => console.log("Queried nee"));
	}, [assetId, iteId]);

	const printDisplayProps: BoxProps = {
		style: {
			breakInside: "avoid",
			breakAfter: "always",
			breakBefore: "always",
		},
	};

	const onCookieSet = () => {
		setIsCookieSet(true);
	};

	return (
		<div className="print">
			{assetId && asset && (
				<React.Fragment>
					<CookieProvider assetId={assetId} onCookieSet={onCookieSet} />
					{isCookieSet && (
						<React.Fragment>
							<Cover />
							{/* <EstadoActivo print={true} /> */}
							{/* <LevantamientoDigital print={true} /> */}
							<Box {...printDisplayProps}>
								<IteSection print={true} />
							</Box>
							<Box float="none">
								<TablaInventario print={true} />
							</Box>
							<Box float="none">
								<EstadoDeConservacionPdf asset={asset} />
							</Box>
							<Box {...printDisplayProps}>
								<TablaEficiencia print={true} />
							</Box>
							<Box {...printDisplayProps}>
								<TablaAccesibilidad print={true} />
							</Box>
							<Box {...printDisplayProps}>
								<TablaNormativa print={true} />
							</Box>
						</React.Fragment>
					)}
				</React.Fragment>
			)}
		</div>
	);
};

export default WithApiKeyOrUserAuth(Print);
