import React from "react";

import { Text, TextProps } from "@chakra-ui/react";

interface IProps {
	label: string;
	color?: string;
	textProps?: TextProps;
}

const HeaderText: React.FC<IProps> = (props) => {
	const { label, color = "gray.500", textProps } = props;
	return (
		<Text textStyle="body3" color={color} {...textProps}>
			{label}
		</Text>
	);
};

export default HeaderText;
