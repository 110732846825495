import React from "react";

import { Grid, GridItem, Text } from "@chakra-ui/react";

import Carousel from "common/components/Carousel";
import { InfoLine } from "common/components/InfoLine";

import { NEE } from "../types";

interface IProps {
	nee: NEE;
}
export const RowBody: React.FC<IProps> = (props) => {
	const { nee } = props;

	return (
		<React.Fragment>
			<Grid templateColumns="repeat(10, 1fr)" gap={2} w="100%" textAlign="left" pl="1rem">
				<GridItem colSpan={nee.fotos && nee.fotos.length > 0 ? 6 : 10}>
					<Grid templateColumns="repeat(10, 1fr)" gap={5} w="100%" textAlign="left" pl="1rem">
						<GridItem colSpan={6} pr="1rem">
							<Text textStyle="body3" color="green.600">
								Descripción de la mejora
							</Text>
							<InfoLine value={nee.descripcion} />
						</GridItem>
						<GridItem colSpan={2}>
							<Text textStyle="body3" color="green.600">
								Evaluación Técnica
							</Text>
							<InfoLine
								label="Ahorro Energetico"
								value={nee.ahorro_porcentual_ano}
								percentage={true}
								styleProps={{ mt: ".25rem" }}
							/>
							<InfoLine
								label="Ahorro de energía"
								value={nee.ahorro_absoluto_kwh_ano}
								styleProps={{ mt: ".25rem" }}
								suffix={"kWh/año"}
							/>
							<InfoLine
								label="Emisiones evitadas"
								value={nee.emisiones_evitadas_tco2_ano}
								styleProps={{ mt: ".25rem" }}
								suffix={"tCO2 equiv./año"}
							/>
						</GridItem>
						<GridItem colSpan={2}>
							<Text textStyle="body3" color="green.600">
								Evaluación económica
							</Text>
							<InfoLine
								label="Inversion Total"
								value={nee.inversion_total_euros}
								currency={true}
								styleProps={{ mt: ".25rem" }}
							/>
							<InfoLine
								label="Ahorro económico"
								value={nee.ahorro_total_euros_ano}
								styleProps={{ mt: ".25rem" }}
								currency={true}
								suffix={"/año"}
							/>
							<InfoLine
								label="Período de retorno"
								value={nee.retorno_anos}
								styleProps={{ mt: ".25rem" }}
								suffix={"años"}
							/>
						</GridItem>
					</Grid>
				</GridItem>
				{nee.fotos && nee.fotos.length > 0 && (
					<GridItem colSpan={4} pr=".5rem">
						{nee.fotos && nee.fotos.length > 0 && <Carousel images={nee.fotos} />}
					</GridItem>
				)}
			</Grid>
		</React.Fragment>
	);
};
