import { DateTime } from "luxon";

import { ITEListOutput, ResumenITE } from "../types/ite";

export const getFechaVisita = (ite: ITEListOutput | ResumenITE) =>
	ite.fecha_visita_reportada || ite.fecha_visita_reprogramada || ite.fecha_visita_programada
		? DateTime.fromISO(ite.fecha_visita_reprogramada || ite.fecha_visita_programada || "")
		: DateTime.now();

export const fechaPrimerEvento = (ite: ITEListOutput | ResumenITE) => {
	const allDates = [
		ite.fecha_visita_reportada || ite.fecha_visita_reprogramada || ite.fecha_visita_programada,
		ite.fecha_entrega,
		ite.fecha_registro,
	];
	return DateTime.fromSeconds(
		Math.min(...allDates.filter((d): d is string => !!d).map((d) => DateTime.fromISO(d).toSeconds())),
	);
};

export const fechaUltimoEvento = (ite: ITEListOutput | ResumenITE) => {
	const allDates = [
		ite.fecha_visita_reportada || ite.fecha_visita_reprogramada || ite.fecha_visita_programada,
		ite.fecha_entrega,
		ite.fecha_registro,
	];
	return DateTime.fromSeconds(
		Math.max(...allDates.filter((d): d is string => !!d).map((d) => DateTime.fromISO(d).toSeconds())),
	);
};
