import { Box, Flex, Text } from "@chakra-ui/react";

interface BarAndValueProps {
	value: number;
	totalValue: number;
	label: string;
	colors: Record<string, string>;
}

const percentage = (value: number, totalValue: number) => {
	if (totalValue === 0) {
		return 0;
	}
	return ((value / totalValue) * 100).toFixed(2);
};

export function BarAndValue(props: BarAndValueProps) {
	const { value, totalValue, label } = props;

	return (
		<Flex flexDirection="row" width="100%" alignItems="center">
			<Box
				w={`${percentage(value, totalValue)}%`}
				h="100%"
				bg={props.colors[label] || "gray.300"}
				borderRadius="2px"
				transition="width 0.8s ease"
				border="5px solid"
				color={props.colors[label] || "gray.300"}
			/>
			<Text marginLeft={"0.5rem"} textStyle="body3" fontWeight="bold" color="gray.800">
				{value}
			</Text>
		</Flex>
	);
}
