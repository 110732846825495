import React from "react";

import { Box, Flex } from "@chakra-ui/react";

import Breadcrumb from "common/components/Breadcrum";

import { Topbar } from "../Topbar";

interface IProps {
	children: React.ReactNode;
}

export const Layout: React.FC<IProps> = (props) => {
	return (
		<Flex direction="column">
			<Topbar />
			<Box overflow="hidden" overflowY="auto" p={{ xs: "0 1.25rem", sm: "0 2rem" }} minH="300px">
				<Box p="1rem .25rem">
					<Breadcrumb />
				</Box>
				{props.children}
			</Box>
		</Flex>
	);
};
