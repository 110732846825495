import React from "react";

import { Box, SlideFade, SlideFadeProps, Text, useDisclosure } from "@chakra-ui/react";

import { formsUrl } from "helpers/formsUrl";
import { useAppSelector } from "store/store";

import { HeaderLevantamientoDigital } from "./Table/Header";
import { RowLevantamientoDigital } from "./Table/Row";

type IProps = {
	print?: boolean;
};

export const LevantamientoDigital: React.FC<IProps> = (props) => {
	const { print } = props;
	const { isOpen, onToggle } = useDisclosure();
	const iteSelected = useAppSelector((state) => state.ites.iteSelected.data);

	const slideProps: SlideFadeProps = {
		in: isOpen,
		offsetX: "0px",
		offsetY: "0px",
	};

	React.useEffect(() => {
		onToggle();
	}, []);

	return (
		<SlideFade {...slideProps}>
			<Box p="1rem" rounded="md" boxShadow="sm" bgColor="white" pb="2rem">
				<Text textStyle="body2" fontWeight="bold" pl="1rem" mb="2rem">
					Activo Digital
				</Text>
				<HeaderLevantamientoDigital />
				<RowLevantamientoDigital
					type="fichasInventario"
					formLink={formsUrl(iteSelected?.ite.record_inv_id)}
					formName="Fichas de Inventario (último registro)"
					formStatus={iteSelected?.inventario.fichaDeInventario.estado}
					print={print}
				/>
				<RowLevantamientoDigital
					type="activoDigitalizado"
					accLink={iteSelected?.inventario.activoDigitalizado.accLink ?? "#"}
					formName="Activo Digitalizado"
					formStatus={iteSelected?.asset.fecha_entrega_bim ? "entregado" : "pendiente"}
					print={print}
				/>
			</Box>
		</SlideFade>
	);
};
