import { ComponentStyleConfig } from "@chakra-ui/react";

const componentOverride: ComponentStyleConfig = {
	variants: {
		responsive: {
			content: {
				maxWidth: "unset",
				width: "unset",
				backgroundColor: "green",
			},
		},
	},
};

export default componentOverride;
