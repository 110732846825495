import { devConfig } from "./dev";
import { localConfig } from "./local";
import { prodConfig } from "./prod";

export type EnvConfigurationType = {
	ENV_NAME: string;
	AWS_POOL_ID: string;
	AWS_REGION: string;
	AWS_WEB_CLIENT_ID: string;
	AWS_COGNITO_DOMAIN: string;
	AWS_AZURE_PROVIDER_ID: string;
	API_URL: string;
	PROJECT_REF: string;
};

const runConfig = () => {
	if (process.env.NODE_ENV === "production") {
		if (window.location.host === "aite.dhub.arup.com") return prodConfig;
		else if (window.location.host === "dev.aite.dhub.arup.com") return devConfig;
		else return devConfig;
	} else {
		return localConfig;
	}
};

export const envConfig = runConfig();
