import React from "react";

import {
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Grid,
	GridItem,
	Text,
} from "@chakra-ui/react";

import IndiceIndicator from "common/components/IndiceIndicator";
import { defaultValuesSistemas, defaultValuesElementos } from "components/Portfolio/defaultValues";
import { noOfLines } from "helpers/displayWhenPrint";
import { sistemaLabel } from "helpers/tableLabels";
import { capitalizeFirstLetter } from "helpers/text";

import { RowBody } from "../RowBody";
import { NEC } from "../types";

interface IProps {
	nec: NEC;
	print?: boolean;
}

export const TableRow: React.FC<IProps> = (props) => {
	const { nec, print } = props;

	return (
		<AccordionItem>
			{({ isExpanded }) => (
				<Box style={{ breakInside: "avoid" }}>
					<AccordionButton minHeight="2.75rem" p="0" pl="1rem">
						<Grid templateColumns="repeat(7, 1fr)" gap={5} w="100%" textAlign="left" alignItems="center">
							<GridItem colSpan={2}>
								<Text
									title={capitalizeFirstLetter(
										sistemaLabel(defaultValuesSistemas.find((it) => it.value === nec.sistema)?.label ?? nec.sistema),
									)}
									textStyle="body3"
									color="gray.800"
									noOfLines={noOfLines(print)}
								>
									{capitalizeFirstLetter(
										sistemaLabel(defaultValuesSistemas.find((it) => it.value === nec.sistema)?.label ?? nec.sistema),
									)}
								</Text>
							</GridItem>
							<GridItem colSpan={2}>
								<Text
									title={capitalizeFirstLetter(nec.defecto)}
									textStyle="body3"
									color="gray.800"
									noOfLines={noOfLines(print)}
									pl=".25rem"
								>
									{capitalizeFirstLetter(nec.defecto)}
								</Text>
							</GridItem>
							<GridItem colSpan={2}>
								<Text
									title={capitalizeFirstLetter(
										capitalizeFirstLetter(
											defaultValuesElementos.find((it) => it.value === nec.elemento && it.sistemasEC === nec.sistema)
												?.label ?? nec.elemento,
										),
									)}
									textStyle="body3"
									color="gray.800"
									noOfLines={noOfLines(print)}
								>
									{capitalizeFirstLetter(
										capitalizeFirstLetter(
											defaultValuesElementos.find((it) => it.value === nec.elemento && it.sistemasEC === nec.sistema)
												?.label ?? nec.elemento,
										),
									)}
								</Text>
							</GridItem>
							<GridItem colSpan={1}>{nec.indice && <IndiceIndicator indice={nec.indice} />}</GridItem>
						</Grid>
						{!print && <AccordionIcon color="gray.500" />}
					</AccordionButton>
					<AccordionPanel borderTop="0.5px solid" borderColor="gray.100" padding="1rem 0" backgroundColor="gray.table">
						{isExpanded && <RowBody nec={nec} />}
					</AccordionPanel>
				</Box>
			)}
		</AccordionItem>
	);
};
