import React from "react";

import { Box, Text } from "@chakra-ui/react";

export const SideBarHeader: React.FC = () => {
	return (
		<Box>
			<Text textStyle="body3" color="red.500">
				Vista Portfolio
			</Text>
			<Text textStyle="h5" fontFamily="heading" lineHeight={"1.25rem"}>
				Edificios técnicos y dedicados al mantenimiento
			</Text>
		</Box>
	);
};
