import React from "react";

import { Box, Divider, Flex, Grid, GridItem, Icon, Text } from "@chakra-ui/react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";

import StatusIndicator from "common/components/StatusIndicator";

interface IProps {
	sectionLabel: string;
	sectionRef: string;
	estado?: string;
	resultado?: string;
	print?: boolean;
}

export const SectionSelector: React.FC<IProps> = (props) => {
	const { sectionLabel, sectionRef, estado, resultado, print } = props;
	const { assetId, sectionId } = useParams();
	const navigate = useNavigate();

	const handleNavigate = () => {
		if (sectionId === sectionRef) navigate(`/activo/${assetId}/`);
		else navigate(`/activo/${assetId}/${sectionRef}/`);
	};

	return (
		<Box>
			<Flex
				width="100%"
				height="100%"
				alignItems="center"
				onClick={handleNavigate}
				_hover={{ cursor: "pointer", bgColor: "gray.100" }}
				bgColor={!print ? (sectionId === sectionRef ? "gray.100" : "white") : "white"}
			>
				<Flex width="100%" height="100%" alignItems="center">
					<Grid templateColumns="repeat(8, 1fr)" w="100%" m=".25rem 0" alignItems="center">
						<GridItem colSpan={4} m=".25rem 0">
							<Text fontSize="body2" pl="1rem">
								{sectionLabel}
							</Text>
						</GridItem>
						<GridItem colSpan={2}>
							<StatusIndicator estado={estado} />
						</GridItem>
						<GridItem colSpan={2}>
							<StatusIndicator resultado={resultado} />
						</GridItem>
					</Grid>
				</Flex>
				{!print && (
					<Icon
						as={sectionId === sectionRef ? BsChevronLeft : BsChevronRight}
						color="gray.500"
						width="1.25rem"
						height="1.25rem"
						mr="1rem"
						mt="0.5rem"
						mb=".5rem"
						_hover={{ cursor: "pointer" }}
					/>
				)}
			</Flex>
			<Divider />
		</Box>
	);
};
