import React from "react";

import { Flex, Wrap, Box, Text, Grid, GridItem } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { TableButton } from "./Button";

export const TableViewButtons: React.FC = () => {
	const { portfolioParams } = useParams();
	const atPortfolio = portfolioParams === undefined;
	const atInspeccion = location.pathname.includes("inspeccion");
	const atLocalizacion = location.pathname.includes("localizacion");
	const atTipologia = location.pathname.includes("tipologia");
	const atConservacion = location.pathname.includes("nec");
	const atEnergetica = location.pathname.includes("nee");
	const atAccesibilidad = location.pathname.includes("nae");
	const atNormativa = location.pathname.includes("ncn");
	const atCertificacion = location.pathname.includes("certificacion");

	return (
		<Grid templateColumns="3fr 5fr 1fr" gap=".5rem 2rem">
			<GridItem colSpan={1}>
				<Box>
					<Text textStyle="body3" color="gray.500">
						Datos generales
					</Text>
				</Box>
			</GridItem>
			<GridItem colSpan={1}>
				<Box>
					<Text textStyle="body3" color="gray.500">
						Categorías de inspección
					</Text>
				</Box>
			</GridItem>
			<GridItem colSpan={1}>
				<Box>
					<Text textStyle="body3" color="gray.500">
						Control de estado
					</Text>
				</Box>
			</GridItem>

			<GridItem colSpan={1}>
				<Flex>
					<Wrap spacing={3}>
						<TableButton name="Inspección" path={atInspeccion || atPortfolio} />
						<TableButton name="Localización" path={atLocalizacion} />
						<TableButton name="Tipología" path={atTipologia} />
					</Wrap>
				</Flex>
			</GridItem>

			<GridItem colSpan={1}>
				<Flex>
					<Wrap spacing={3}>
						<TableButton name="E. Conservación" path={atConservacion} />
						<TableButton name="E. Energética" path={atEnergetica} />
						<TableButton name="Accesibilidad" path={atAccesibilidad} />
						<TableButton name="C. Normativa" path={atNormativa} />
					</Wrap>
				</Flex>
			</GridItem>

			<GridItem colSpan={1}>
				<Flex>
					<Wrap spacing={3}>
						<TableButton name="Certificación" path={atCertificacion} />
					</Wrap>
				</Flex>
			</GridItem>
		</Grid>
	);
};
