import React from "react";

import { Box, Grid, GridItem, Text } from "@chakra-ui/react";

import { InfoLine } from "common/components/InfoLine";
import { ResumenITE } from "components/ITE/types/ite";

interface IProps {
	ite?: ResumenITE | null;
}

export const IteInfo: React.FC<IProps> = (props) => {
	const { ite } = props;

	return (
		<Box flex="auto">
			<Text textStyle="body3" color="#006935">
				Resumen de la ITE
			</Text>
			<Grid templateColumns={{ xs: "1fr", sm: "repeat(2, 1fr)" }} mb="1rem">
				{ite && (
					<React.Fragment>
						<GridItem>
							<InfoLine label="Empresa Inspectora:" value={ite.empresa || "No especificada"} />
						</GridItem>
						<GridItem>
							<InfoLine label="Técnico Responsable:" value={ite.tecnico || "No especificado"} />
						</GridItem>
						<GridItem>
							<InfoLine
								label="Fecha de Visita Programada:"
								value={
									ite.fecha_visita_reprogramada || ite.fecha_visita_programada
										? ((ite.fecha_visita_reprogramada || ite.fecha_visita_programada) as unknown as string)
										: "No especificada"
								}
								date={!!(ite.fecha_visita_reprogramada || ite.fecha_visita_programada)}
							/>
						</GridItem>
						<GridItem>
							<InfoLine
								label="Fecha de Visita Reportada:"
								value={ite.fecha_visita_reportada ? (ite.fecha_visita_reportada as unknown as string) : "No reportada"}
								date={!!ite.fecha_visita_reportada}
							/>
						</GridItem>
						<GridItem>
							<InfoLine
								label="Fecha de Entrega:"
								value={ite.fecha_entrega ? (ite.fecha_entrega as unknown as string) : "Pendiente de entrega"}
								date={!!ite.fecha_entrega}
							/>
						</GridItem>
						<GridItem>
							<InfoLine
								label="Fecha de Registro:"
								value={ite.fecha_registro ? (ite.fecha_registro as unknown as string) : "Pendiente de registro"}
								date={!!ite.fecha_registro}
							/>
						</GridItem>
						<GridItem>
							<InfoLine label="Resultado ITE:" value={ite.resultado || "Pendiente de cálculo"} />
						</GridItem>
						<GridItem>
							<InfoLine label="Autor de la Inspección:" value={ite.autor || "No especificado"} />
						</GridItem>
					</React.Fragment>
				)}
			</Grid>
		</Box>
	);
};
