import React from "react";

import { Flex, Button, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const SideBarButtons: React.FC = () => {
	const navigate = useNavigate();

	return (
		<Flex w="100%" m="1rem 0">
			<Button
				size="md"
				borderRadius="5px"
				h="auto"
				w="100%"
				minH="2rem"
				variant="solid"
				bgColor="gray.400"
				onClick={() => navigate("/portfolio/inspeccion")}
				_hover={{ bgColor: "gray.500" }}
			>
				<Text textStyle="body3" color="white">
					Ver Lista
				</Text>
			</Button>
		</Flex>
	);
};
