import { ActivoCompletoOutput } from "components/Portfolio/types/activos";

export const getFilterSelection = (column: keyof ActivoCompletoOutput) => {
	switch (column) {
		case "estado_ite":
			return "estadoITE";
		case "estado_levantamiento":
			return "estadoLevantamiento";
		case "estado_ficha_inventario":
			return "estadoFichaInventario";
		case "resultado_ite":
			return "resultadoITE";
		case "ambito":
			return "ambito";
		case "jefatura":
			return "jefatura";
		case "especialidades":
			return "especialidad";
		case "usos":
			return "uso";
		case "relevancia":
			return "relevancia";
		case "tipologia":
			return "tipologia";
		case "empresa":
			return "empresa";
		case "tecnico":
			return "tecnico";
		case "autor":
			return "autor";
		case "por_validar":
			return "porValidar";
		case "agrupacion":
			return "agrupacion";
		case "nee_categoria_mejoras":
			return "categoriaMejoras";
		case "nee_tipo_edificio":
			return "tipoEdificio";
		case "nec_indice":
			return "indiceNEC";
		case "prioridad_reparacion":
			return "prioridadReparacion";
		case "nee_indice":
			return "indiceNEE";
		case "nae_indice":
			return "indiceNAE";
		case "ncn_indice":
			return "indiceNCN";
		case "provincia":
			return "provincia";
		case "fecha_visita_programada":
			return "fechaVisita";
		case "fecha_visita_reprogramada":
			return "fechaVisita";
		case "fecha_visita_reportada":
			return "fechaVisita";
		case "fecha_visita_programada_prox_ite":
			return "fechaVisitaProxIte";
		case "fecha_visita_reprogramada_prox_ite":
			return "fechaVisitaProxIte";
		default:
			return "undefined";
	}
};
