import React, { useRef, useEffect } from "react";

import "./styles.css";

interface IProps {
	url: string;
	display: "none" | "block";
	setDisplay: React.Dispatch<React.SetStateAction<"none" | "block">>;
}

const CarouselModal: React.FC<IProps> = (props) => {
	const { url, display, setDisplay } = props;
	const imageRef = useRef(null);

	const overlayClick = (ref) => {
		useEffect(() => {
			function handleClick(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setDisplay("none");
				}
			}
			document.addEventListener("mousedown", handleClick);
			return () => {
				document.removeEventListener("mousedown", handleClick);
			};
		}, [ref]);
	};

	overlayClick(imageRef);

	return (
		<div id="myModal" className="modal" style={{ display: display, zIndex: 100 }}>
			<span className="close" onClick={() => setDisplay("none")}>
				&times;
			</span>
			<img ref={imageRef} className="modal-content" id="image" src={url} />
		</div>
	);
};
export default CarouselModal;
